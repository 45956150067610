:root {
  --job-details-font-colour: #{$msv-black};

  --job-details-div-margin: 0;

  --job-details-intro-margin: 1rem 0 2rem 0;
  --job-details-intro-margin-mobile: 1rem 0 0 0;
  --job-details-intro-padding: 0;

  --job-details-social-caption-font-colour: var(--brandPrimaryColor);
  --job-details-social-caption-font-weight: #{$msv-font-weight-normal};
  --job-details-social-caption-padding-mobile: 4px 0;
  --job-details-social-line-height: .5;
  --job-details-social-margin-mobile: .8rem 0 0 0;

  --job-details-text-margin: 1rem 0 0 0;
  --job-details-text-padding: 0 10rem 0 0;
  --job-details-text-padding-mobile: 0;

  --job-details-title-font-weight: #{$msv-font-weight-bold};
  --job-details-title-margin-mobile: 1rem 0 0 0;
  --job-details-title-padding: 0;
}

.ecomm-job-details {
  color: var(--job-details-font-colour);

  div:last-child {
    @include breakpoint($msv-breakpoint-l) { margin: var(--job-details-div-margin); }
  }

  &-title {
    font-weight: var(--job-details-title-font-weight);
    padding: var(--job-details-title-padding);

    &.sub {
      @include breakpoint($msv-breakpoint-l) { margin: var(--job-details-title-margin-mobile); }
    }
  }

  &-social {
    @include breakpoint($msv-breakpoint-l) { margin: var(--job-details-social-margin-mobile); }

    .ms-social-share-ul.caption::before {
      @include breakpoint($msv-breakpoint-l) { padding: var(--job-details-social-caption-padding-mobile); }

      color: var(--job-details-social-caption-font-colour);
      font-weight: var(--job-details-social-caption-font-weight);
      line-height: var(--job-details-social-line-height); 
    }
  }

  &-intro { 
    @include breakpoint($msv-breakpoint-l) { margin: var(--job-details-intro-margin-mobile); }

    margin: var(--job-details-intro-margin);
    padding: var(--job-details-intro-padding);
  }

  &-text {
    @include breakpoint($msv-breakpoint-l) { padding: var(--job-details-text-padding-mobile); }

    margin: var(--job-details-text-margin);
    padding: var(--job-details-text-padding);
  }
}