$msv-breakpoint-xs: 0px;
$msv-breakpoint-s: 540px;
$msv-breakpoint-m: 768px;
$msv-breakpoint-l: 992px;
$msv-breakpoint-xl: 1368px;

$msv-gutter-width-s: 16px;
$msv-gutter-width-m: 20px;
$msv-gutter-width-l: 60px;

$msv-container-max-width-s: 768px;
$msv-container-max-width-m: 992px;
$msv-container-max-width-l: 1368px;

$msv-container-min-width-m: 769px;

@mixin breakpoint($point) {
  @if $point == $msv-breakpoint-s {
    /* PHONE LANDSCAPE VERSION */
    @media only screen and (min-width: $msv-breakpoint-s) and (max-width: $msv-breakpoint-m) and (orientation: landscape) {
      @content; 
    }

    /* PHONE PORTRAIT VERSION */
    @media only screen and (max-width: $msv-breakpoint-s) and (orientation: portrait) { @content; }
  } @else if $point == $msv-breakpoint-m {
    /* TABLET PORTRAIT VERSION */
    @media only screen and (max-width: $msv-breakpoint-m) and (orientation: portrait) { @content; }
  } @else if $point == $msv-breakpoint-l {
    @media only screen and (max-width: $msv-breakpoint-l) { @content; }
  } @else {
    /* DESKTOP VERSION */
    @media only screen and (min-width: $msv-breakpoint-l) { @content; }
  }
}