$breadcrumb-padding-bottom: 20px;

:root {
    --msv-breadcrumb-item-font-color: var(--brandPrimaryColor);
    --msv-breadcrumb-item-font-size: var(--msv-body-font-size-s);
    --msv-breadcrumb-item-font-weight: #{$msv-font-weight-bold};
 }

.ms-breadcrumb
{
    padding-bottom: #{$breadcrumb-padding-bottom};

   .ms-breadcrumb_list
   {
       color: var(--msv-breadcrumb-item-font-color);
       float: left;
       list-style: none;
       padding: 0;

       .ms-breadcrumb-back-button
       {
           text-decoration: underline;
           cursor: pointer;
           float: left;
           margin-right: 10px;

           @include add-icon($msv-ChevronLeft, before);

           &:before{
               padding-right: 5px;
           }
       }
   }

   .ms-breadcrumb_item
   {
       color: var(--msv-breadcrumb-item-font-color);
       display: inline;
       float: left;
       font-size: var(--msv-breadcrumb-item-font-size);
       position: relative;
       margin:0;
       padding-right: 5px;

       @include font-content-s();

       a[data-no-click='true'] {
        cursor: default;
        pointer-events: none;
       }

       .ms-breadcrumb_link
       {
           color: var(--msv-breadcrumb-item-font-color);
           font-size: var(--msv-breadcrumb-item-font-size);
           text-decoration: underline;
       }

       &.breadcrumb_item-current {
           font-weight: var(--msv-breadcrumb-item-font-weight);

           .ms-breadcrumb_link
           {
               text-decoration: none;
           }
       }  
   }   
}