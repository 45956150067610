:root {
    --stream-column-padding: 0;
    --stream-controls-bg: #{$msv-gray-90};
    --stream-controls-colour: #{$msv-gray-400};
    --stream-controls-height: 3rem;
    --stream-controls-icon-size: 1.2rem;
    --stream-controls-slider-height: 3px;
    --stream-controls-slider-thumb-dimension: 15px;
    --stream-controls-slider-thumb-radius: 25px;
    --stream-event-height: 60vh;
    --stream-header-margin: 0 0 1rem 0;
    --stream-divider-border: 1px solid #{$msv-gray-300};
    --stream-divider-height: 1rem;
    --stream-divider-margin: 0 .5rem;
    --stream-floors-prompt-padding: 0;
    --stream-floors-selector-padding: 0;
    --stream-loader-wrapper-height: 70vh;
    --stream-player-wrapper-width: auto;
    --stream-prompt-colour: #{$msv-gray-400};
    --stream-react-player-padding-top: 56.25%; // Percentage ratio for 16:9.
    --stream-selector-border: 1px solid #{$msv-gray-300};
    --stream-selector-colour: var(--brandPrimaryColor);
    --stream-selector-margin: 0 0 0 .25rem;
    --stream-selector-weight: #{$msv-font-weight-bold};
    --stream-slider-bg: #{$msv-gray-400};
    --stream-slider-thumb-bg: #{$msv-gray-400};
    --stream-video-top: 0;
    --stream-title-colour: var(--brandPrimaryColor);
    --stream-title-font-weight: #{$msv-font-weight-bold};
    --stream-sutra-border: 1px solid #{$msv-gray-200};
    --stream-sutra-empty-colour: #{$msv-gray-900};
    --stream-sutra-empty-padding: 0 1rem;
    --stream-sutra-prompt-colour: var(--brandPrimaryColor);
    --stream-sutra-prompt-padding: 0 1rem;
    --stream-sutra-prompt-weight: bold;
}

.ecomm-live-stream {
    &-header,
    &-title,
    &-video,
    &-floors,
    &-sutras {
        padding: var(--stream-column-padding);
    }
    &-header {
        margin: var(--stream-header-margin);
        .sutra-prompt {
            color: var(--stream-sutra-prompt-colour);
            font-weight: var(--stream-sutra-prompt-weight);
            padding: var(--stream-sutra-prompt-padding);
        }
    }
    &-title {
        color: var(--stream-title-colour);
        font-weight: var(--stream-title-font-weight);
    }
    &-floors {
        align-items: baseline;
        .divider {
            border-right: var(--stream-divider-border);
            height: var(--stream-divider-height);
            margin: var(--stream-divider-margin);
        }
        >p,
        .floor-display span {
            color: var(--stream-prompt-colour);
            padding: var(--stream-floors-prompt-padding);
            text-align: end;
        }
        .floor-display span:last-child {
            color: var(--stream-selector-colour);
            font-weight: var(--stream-selector-weight);
            margin: var(--stream-selector-margin);
        }
        .floor-selector {
            padding: var(--stream-floors-selector-padding);
            >span+div {
                border: none;
                box-shadow: none;
                &:hover {
                    box-shadow: none;
                }
                >div {
                    font-weight: var(--stream-selector-weight);
                    span {
                        background-color: transparent;
                    }
                    >div {
                        color: var(--stream-selector-colour);
                        padding: var(--stream-floors-selector-padding);
                    }
                }
                &+div {
                    border: var(--stream-selector-border);
                    box-shadow: none;
                    color: var(--stream-selector-colour);
                }
            }
        }
    }
    &-video {
        width: 100%;
        .loader-wrapper {
            @include display-flex;
            @include justify-content(center);
            @include align-items(center);
            min-height: var(--stream-loader-wrapper-height);
        }
        .player-wrapper {
            min-width: var(--stream-player-wrapper-width);
            position: relative;
            overflow: hidden;
            width: 100%;
            padding-top: 56.25%;
            >.player {
                // padding-top: var(--stream-react-player-padding-top);
                // position: relative;
                overflow: hidden;
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                width: 100%;
                height: 100%;
                // >div {
                //     position: absolute;
                //     top: var(--stream-video-top);
                // }
            }
        }
    }
    &-controls {
        background-color: var(--stream-controls-bg);
        height: var(--stream-controls-height);
        button {
            background-color: transparent;
            border: none;
            color: var(--stream-controls-colour);
        }
        &-player {
            @include display-flex;
            @include align-items(center);
            .mute {
                @include add-icon($msv-Mute, before, block, var(--stream-controls-icon-size));
            }
            .unmute {
                @include add-icon($msv-UnMute, before, block, var(--stream-controls-icon-size));
            }
            .slider {
                -webkit-appearance: none;
                background: var(--stream-slider-bg);
                height: var(--stream-controls-slider-height);
                &::-webkit-slider-thumb {
                    -webkit-appearance: none;
                    background: var(--stream-slider-thumb-bg);
                    border-radius: var(-stream-controls-slider-thumb-radius);
                    cursor: pointer;
                    height: var(--stream-controls-slider-thumb-dimension);
                    width: var(--stream-controls-slider-thumb-dimension);
                }
                &::-moz-range-thumb {
                    background: var(--stream-slider-thumb-bg);
                    border-radius: var(-stream-controls-slider-thumb-radius);
                    cursor: pointer;
                    height: var(--stream-controls-slider-thumb-dimension);
                    width: var(--stream-controls-slider-thumb-dimension);
                }
            }
        }
        &-view {
            @include display-flex;
            @include justify-content(end);
            .fullscreen {
                @include add-icon($msv-Fullscreen, before, block, var(--stream-controls-icon-size));
            }
        }
    }
    &-sutras {
        max-height: var(--stream-event-height);
        overflow-y: scroll;
        >div:not(:last-child) {
            border-bottom: var(--stream-sutra-border);
        }
        .no-schedule {
            color: var(--stream-sutra-empty-colour);
            padding: var(--stream-sutra-empty-padding);
        }
    }
}