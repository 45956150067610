:root {
  --ecomm-loader-animation: load-animation 1.8s infinite ease-in-out;
  --ecomm-loader-animation-delay: -0.16s;
  --ecomm-loader-animation-before: -0.32s;
  --ecomm-loader-animation-end: 0 2.5em 0 -1.3em;
  --ecomm-loader-animation-fill-mode: both;
  --ecomm-loader-animetion-start: 0 2.5em 0 0;

  --ecomm-loader-border-radius: 50%;
  --ecomm-loader-colour: var(--brandPrimaryColor);
  --ecomm-loader-content: '';
  --ecomm-loader-font-size: 10px;

  --ecomm-loader-height: 2.5em;

  --ecomm-loader-d-left-after: 3.5em;
  --ecomm-loader-d-left-before: -3.5em;
  --ecomm-loader-d-top-before: 0;
  --ecomm-loader-d-top-after: 0;

  --ecomm-loader-margin: 80px auto;

  --ecomm-loader-text-indent: -9999em;
  --ecomm-loader-transform: translateZ(0);

  --ecomm-loader-width: 2.5em;
}

.loader,
.loader:before,
.loader:after {
  -webkit-animation: var(--ecomm-loader-animation);
  animation: var(--ecomm-loader-animation);
  -webkit-animation-fill-mode: var(--ecomm-loader-animation-fill-mode);
  animation-fill-mode: var(--ecomm-loader-animation-fill-mode);
  border-radius: var(--ecomm-loader-border-radius);
  height: var(--ecomm-loader-height);
  width: var(--ecomm-loader-width);
}

.loader {
  -webkit-animation-delay: var(--ecomm-loader-animation-delay);
  animation-delay: var(--ecomm-loader-animation-delay);
  color: var(--ecomm-loader-colour);
  font-size: var(--ecomm-loader-font-size);
  margin: var(--ecomm-loader-margin);
  position: relative;
  text-indent: var(--ecomm-loader-text-indent);
  -webkit-transform: var(--ecomm-loader-transform);
  -ms-transform: var(--ecomm-loader-transform);
  transform: var(--ecomm-loader-transform);


  &::before {
    -webkit-animation-delay: var(--ecomm-loader-animation-before);
    animation-delay: var(--ecomm-loader-animation-before);
    content: var(--ecomm-loader-content);
    left: var(--ecomm-loader-d-left-before);
    position: absolute;
    top: var(--ecomm-loader-d-top-before);
  }

  &::after {
    content: var(--ecomm-loader-content);
    left: var(--ecomm-loader-d-left-after);
    position: absolute;
    top: var(--ecomm-loader-d-top-after);
  }
}

@-webkit-keyframes load-animation {
  0%, 80%, 100% { box-shadow: var(--ecomm-loader-animation-end); }
  40% { box-shadow: var(--ecomm-loader-animetion-start); }
}

@keyframes load-animation {
  0%, 80%, 100% { box-shadow: var(--ecomm-loader-animation-end); }
  40% { box-shadow: var(--ecomm-loader-animetion-start); }
}
