:root {
    --header-paragraph-padding: 0;

    --header-top-acc-action-colour: var(--brandPrimaryColor);
    --header-top-acc-action-weight: #{$msv-font-weight-heavy};

    --header-top-acc-padding: 0;

    --header-top-acc-draft-bg: transparent;
    --header-top-acc-draft-border: none;
    --header-top-acc-draft-colour: var(--brandPrimaryColor);
    --header-top-acc-draft-colour-disabled: #{$msv-gray-300};
    --header-top-acc-draft-padding: 0;
    --header-top-acc-draft-weight: bold;

    --header-top-acc-draft-icon-margin: 0 0 0 .5rem;

    --header-top-acc-draft-span-colour: var(--brandPrimaryColor);
    --header-top-acc-draft-span-margin: 0 .5rem;
    --header-top-acc-draft-span-vertical: top;

    --header-top-acc-draft-prompt-colour: var(--brandPrimaryColor);
    --header-top-acc-draft-prompt-size: .8rem;
    --header-top-acc-draft-prompt-weight: bold;

    --header-top-acc-renew-bg: white;
    --header-top-acc-renew-bg-disabled: #f7f7fc;
    --header-top-acc-renew-border: 1px solid #{$msv-gray-700};
    --header-top-acc-renew-border-disabled: 1px solid #{$msv-gray-700};
    --header-top-acc-renew-colour: var(--brandPrimaryColor);
    --header-top-acc-renew-colour-disabled: #{$msv-gray-300};
    --header-top-acc-renew-margin: 0 0 0 1rem;
    --header-top-acc-renew-padding: .5rem 1rem;
    --header-top-acc-renew-weight: bold;

    --header-top-acc-prompt-colour: #{$msv-gray-500};
    --header-top-acc-prompt-weight: normal;

    --header-top-section-margin: 0 0 2rem 0;

    --header-top-title-colour: var(--brandPrimaryColor);
    --header-top-title-size: 2rem;
    --header-top-title-weight: #{$msv-font-weight-heavy};

    --header-sub-colour: var(--brandPrimaryColor);
    --header-sub-margin: 0 0 1rem 0;
    --header-sub-size: 1.2rem;
    --header-sub-weight: #{$msv-font-weight-heavy};

    --header-notice-colour: #000;
    --header-notice-padding: 1rem 0 2rem 0;
    --header-notice-header-font-size: 24px;
    --header-notice-header-font-weight: bold;

    --header-footer-bg: var(--brandPrimaryColor);
    --header-footer-bottom: 0;
    --header-footer-colour: var(--brandTextColour);
    --header-footer-height: 3rem;
    --header-footer-left: 0;
    --header-footer-padding: 1rem 2rem;
    --header-footer-width: 100%;
    --header-footer-z: 980;

    --header-footer-total-margin: 0 1rem 0 0;
    --header-footer-total-size: 1.5rem;
    --header-footer-total-weight: #{$msv-font-weight-heavy};

    --header-footer-btn-bg: #{$msv-white};
    --header-footer-btn-border: 1px solid #{$msv-gray-300};
    --header-footer-btn-colour: var(--brandPrimaryColor);
    --header-footer-btn-opacity: .7;
    --header-footer-btn-padding: .5rem 2rem;
    --header-footer-btn-size: 1rem;
    --header-footer-btn-weight: #{$msv-font-weight-bold};

    --header-footer-btn-icon-margin: 0 0 0 .5rem;

    // MOBILE STYLINGS
    --header-top-section-margin-mobile: 0 0 1rem 0;

    --header-top-acc-margin-mobile: 1rem 0 0 0;
    --header-top-acc-size-mobile: 1rem;

    --header-footer-total-size-mobile: .8rem;
    --header-footer-price-size-mobile: 1.5rem;
    --header-footer-btn-padding-mobile: .5rem 1rem;
}

.ecomm-purchase {
  &-header {
    p {
        padding: var(--header-paragraph-padding);
    }

    >.top {
      @include breakpoint($msv-breakpoint-l) { margin: var(--header-top-section-margin-mobile); }

      margin: var(--header-top-section-margin);

      .title {
        color: var(--header-top-title-colour);
        font-size: var(--header-top-title-size);
        font-weight: var(--header-top-title-weight);
      }
    }

    >.sub {
      color: var(--header-sub-colour);
      margin: var(--header-sub-margin);
      font-size: var(--header-sub-size);
      font-weight: var(--header-sub-weight);

      .subtotal {
        @include display-flex;
        @include justify-content(center);
      }
    }

    >.top,
    .sub {
      >.account {
        @include display-flex;
        @include justify-content(end);

        @include breakpoint($msv-breakpoint-l) { 
          @include justify-content(start);

          font-size: var(--header-top-acc-size-mobile); 
          margin: var(--header-top-acc-margin-mobile);
        }

        padding: var(--header-top-acc-padding);

        > .draft-wrapper {
          .save-draft,
          .retrieve-draft {
            background-color: var(--header-top-acc-draft-bg);
            border: var(--header-top-acc-draft-border);
            color: var(--header-top-acc-draft-colour);
            font-weight: var(--header-top-acc-draft-weight);
            padding: var(--header-top-acc-draft-padding);

            &::after { margin: var(--header-top-acc-draft-icon-margin); }

            &.disabled { color: var(--header-top-acc-draft-colour-disabled); }
          }

          .renew-lamp {
            background-color: var(--header-top-acc-renew-bg);
            border: var(--header-top-acc-renew-border);
            color: var(--header-top-acc-renew-colour);
            font-weight: var(--header-top-acc-renew-weight);
            margin: var(--header-top-acc-renew-margin);
            padding: var(--header-top-acc-renew-padding);

            &.disabled {
              background-color: var(--header-top-acc-renew-bg-disabled);
              border: var(--header-top-acc-renew-border-disabled);
              color: var(--header-top-acc-renew-colour-disabled);
            }
          }
          
          .save-draft { 
            @include add-icon($msv-Save, after); 

            &-prompt {
              color: var(--header-top-acc-draft-prompt-colour);
              font-size: var(--header-top-acc-draft-prompt-size);
              font-weight: var(--header-top-acc-draft-prompt-weight);
            }
          }
          
          .retrieve-draft { @include add-icon($msv-History, after); }
          
          > span {
            color: var(--header-top-acc-draft-span-colour);
            margin: var(--header-top-acc-draft-span-margin);
            vertical-align: var(--header-top-acc-draft-span-vertical);
          }
        }
        
        .prompt {
          color: var(--header-top-acc-prompt-colour);
          font-weight: var(--header-top-acc-prompt-weight);
          
          .action {
            color: var(--header-top-acc-action-colour);
            font-weight: var(--header-top-acc-action-weight);
            display: inline;
            
            &:hover {
              color: var(--header-top-acc-action-colour);
              text-decoration: none;
            }
          }
        }
      }
    }

    &-notice {
      color: var(--header-notice-colour);
      padding: var(--header-notice-padding);

      &-title {
        font-size: var(--header-notice-header-font-size);
        font-weight: var(--header-notice-header-font-weight);
        padding-bottom: 1rem;
      }
    }
  }

  &-footer {
    @include display-flex;
    @include justify-content(end);

    background-color: var(--header-footer-bg);
    bottom: var(--header-footer-bottom);
    color: var(--header-footer-colour);
    left: var(--header-footer-left);
    padding: var(--header-footer-padding);
    position: fixed;
    width: var(--header-footer-width);
    z-index: var(--header-footer-z);

    .content-wrapper {
      @include display-flex;
      @include align-items(center);

      > .total {
        display: inline;
        font-size: var(--header-footer-total-size);
        font-weight: var(--header-footer-total-weight);
        margin: var(--header-footer-total-margin);
        vertical-align: middle;

        > span {
          @include breakpoint($msv-breakpoint-l) {
            display: block;
            font-size: var(--header-footer-price-size-mobile);
          }
        }
      }

      > .ms-buybox__add-to-cart-container {
        display: inline;

        > button.msc-add-to-cart {
          @include breakpoint($msv-breakpoint-l) {
            padding: var(--header-footer-btn-padding-mobile);
            vertical-align: text-top;

            &::after { display: none; }
          }

          @include add-icon($msv-ShoppingCart, after, inline);
          
          background-color: var(--header-footer-btn-bg);
          border: var(--header-footer-btn-border);
          color: var(--header-footer-btn-colour);
          font-size: var(--header-footer-btn-size);
          font-weight: var(--header-footer-btn-weight);
          padding: var(--header-footer-btn-padding);
          
          &::after { margin: var(--header-footer-btn-icon-margin); }
          
          &:disabled {
            cursor: not-allowed;
            opacity: var(--header-footer-btn-opacity);
          }
        }
      }
    }
  }
}