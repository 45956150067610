:root {
  --pdp-colour: #{$msv-black};

  --pdp-list-margin: 1rem 0 0 0;

  --pdp-section-seperator: 1px solid #{$msv-gray-300};
  --pdp-section-padding: 2rem 0;

  --pdp-paragraph-margin: 1rem 0 0 0;

  --pdp-title-colour: var(--brandPrimaryColor);
  --pdp-title-weight: bold;

  --pdp-video-width: 50vw;

  --pdp-ref-page-font-size: .8rem;
  --pdp-ref-page-icon-margin: 0 .2rem 0 0;
  --pdp-ref-page-margin: 0;
  --pdp-ref-page-weight: bold;

  --pdp-ref-src-font-size: .6rem;
  --pdp-ref-src-margin: 0;

  --pdp-mobile-div-margin: 1rem 0 0 0;
}

.ecomm-pdp {
  color: var(--pdp-colour);

  .section {
    border-top: var(--pdp-section-seperator);
    padding: var(--pdp-section-padding);

    > div:last-child {
      @include breakpoint($msv-breakpoint-l) { margin: var(--pdp-mobile-div-margin); }
    }

    > .text {
      > h5 { 
        color: var(--pdp-title-colour); 
        font-weight: var(--pdp-title-weight);
      }

      > p { margin: var(--pdp-paragraph-margin); }
    }

    > .image {
      @include display-flex;
      @include justify-content(center);
    }

    > .video {
      @include display-flex;
      @include justify-content(center);

      > .wrapper video { max-width: var(--pdp-video-width); }
    }

    > h5 { 
      color: var(--pdp-title-colour); 
      font-weight: var(--pdp-title-weight); 
    }

    > p { margin: var(--pdp-paragraph-margin); }
    > ol { margin: var(--pdp-list-margin); }

    > .page-ref {
      @include add-icon($msv-book-open, before, inline-block, var(--pdp-ref-page-font-size));

      font-size: var(--pdp-ref-page-font-size);
      font-weight: var(--pdp-ref-page-weight);
      margin: var(--pdp-ref-page-margin);

      &::before { margin: var(--pdp-ref-page-icon-margin); }
    }

    > .src-ref { 
      margin: var(--pdp-paragraph-margin);
      
      p {
        font-size: var(--pdp-ref-src-font-size);
        margin: var(--pdp-ref-src-margin);
      }
    }
  }
}