:root {
  --ecomm-event-desc-img-no-padding: 0;
  --ecomm-event-desc-img-width: 60%;

  --ecomm-event-desc-title-size: .8rem;

  --ecomm-event-desc-name-colour: var(--brandPrimaryColor);
  --ecomm-event-desc-name-margin: 1rem 0;
  --ecomm-event-desc-name-size: 24px;
  --ecomm-event-desc-name-weight: bold;

  --ecomm-event-desc-colour: #{$msv-black};
  --ecomm-event-desc-line-height: 21px;
  --ecomm-event-desc-weight: 300;

  --ecomm-event-desc-action-margin: 2rem 0 0 0;

  --ecomm-event-desc-button-margin: 0 1rem 0 0;
  --ecomm-event-desc-button-padding: .5rem 2rem;

  --ecomm-event-desc-button-bg-first: #{$msv-white};
  --ecomm-event-desc-button-border-first: #{$msv-gray-300};
  --ecomm-event-desc-button-colour-first: var(--brandPrimaryColor);

  --ecomm-event-desc-button-bg-second: var(--brandPrimaryColor);
  --ecomm-event-desc-button-colour-second: var(--brandTextColour);

  // MOBILE STYLINGS
  --ecomm-event-desc-col-padding-mobile: 0 15px;
}

.ecomm-event-description {
  > .img-gallery {
    @include breakpoint($msv-breakpoint-l) { padding: var(--ecomm-event-desc-img-no-padding); }

    @include display-flex;
    @include justify-content(center);

    padding-left: var(--ecomm-event-desc-img-no-padding);

    > .img-wrapper { width: var(--ecomm-event-desc-img-width); }
  }

  > .content {
    @include breakpoint($msv-breakpoint-l) { padding: var(--ecomm-event-desc-img-no-padding); }

    color: var(--ecomm-event-desc-colour);
    padding-right: var(--ecomm-event-desc-img-no-padding);

    > .type { font-size: var(--ecomm-event-desc-title-size); }

    > .name {
      color: var(--ecomm-event-desc-name-colour);
      font-size: var(--ecomm-event-desc-name-size);
      font-weight: var(--ecomm-event-desc-name-weight);
      margin: var(--ecomm-event-desc-name-margin);
    }

    > .description {
      font-weight: var(--ecomm-event-desc-weight);
      line-height: var(--ecomm-event-desc-line-height);
    }

    > .actions {
      margin: var(--ecomm-event-desc-action-margin);

      a {
        padding: var(--ecomm-event-desc-button-padding);

        &:hover { text-decoration: none; }

        &.first-button {
          background-color: var(--ecomm-event-desc-button-bg-first);
          border: 1px solid var(--ecomm-event-desc-button-border-first);
          color: var(--ecomm-event-desc-button-colour-first);
          margin: var(--ecomm-event-desc-button-margin);
        }
  
        &.second-button {
          background-color: var(--ecomm-event-desc-button-bg-second);
          color: var(--ecomm-event-desc-button-colour-second);
        }
      }
    }
  }
}