:root {
  --job-card-border-colour: #{$msv-gray-300};
  --job-card-content-margin: 1rem 0 0 0;
  --job-card-font-colour: #{$msv-black};
  --job-card-padding: 2rem 3rem;
  --job-card-padding-mobile: 2rem 1rem;

  --job-card-nav-bg-colour: #{$msv-white};
  --job-card-nav-border-colour: #{$msv-gray-300};
  --job-card-nav-font-colour: var(--brandPrimaryColor);
  --job-card-nav-font-weight: #{$msv-font-weight-heavy};
  --job-card-nav-padding: 10px 20px;

  --job-card-text-margin: 0 0 1rem 0;
  --job-card-text-padding: 0;

  --job-card-title-font-weight: #{$msv-font-weight-bold};
}

.ecomm-job-card {
  @include breakpoint($msv-breakpoint-l) { padding: var(--job-card-padding-mobile); }

  border-top: 1px solid var(--job-card-border-colour);
  color: var(--job-card-font-colour);
  padding: var(--job-card-padding);

  &-title { font-weight: var(--job-card-title-font-weight); }

  &-content { margin: var(--job-card-content-margin); }

  &-text {
    @include breakpoint($msv-breakpoint-l) { margin: var(--job-card-text-margin); }

    p { padding: var(--job-card-text-padding); }
  }

  &-action {
    @include breakpoint($msv-breakpoint-l) { @include justify-content(start); }

    @include display-flex;
    @include justify-content(end);
  }

  &-nav {
    background-color: var(--job-card-nav-bg-colour);
    border: 1px solid var(--job-card-nav-border-colour);
    color: var(--job-card-nav-font-colour);
    cursor: pointer;
    font-weight: var(--job-card-nav-font-weight);
    padding: var(--job-card-nav-padding);

    &:hover {
      color: var(--job-card-nav-font-colour);
      text-decoration: none; 
    }
  }
}