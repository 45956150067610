.ecomm-history-list-container {
  .ecomm-history-list {
    width: 100%;
    display: inline-block;

    li {
      width: 100%;
    }

    .desktop {
      padding-left: 1rem;
    }
  }
}