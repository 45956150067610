:root {
  --table-margin: 0 0 1rem 0;
	--table-width: 100%;

	--form-record-bg: var(--brandPrimaryColor);
  --form-record-margin: 0 0 1rem 0;

	--form-item-padding: 1rem;
	--form-item-width: 100%;

  --form-prompt-colour: #{$msv-white};
  --form-prompt-margin: 0 0 .5rem 0;
  --form-prompt-padding: 0;
  --form-prompt-size: .8rem;

  --form-label-colour--req: var(--brandTextColour);
	--form-label-margin: 0 0 .5rem 0;
  --form-label-margin-n-child: .5rem 0;
	--form-label-padding: 0;
	--form-label-size: .8rem;
	--form-label-weight: #{$msv-font-weight-heavy};

	--form-sub-hr-color: var(--brandTextColour);

	--form-sub-header-size: 16px;
	--form-sub-header-padding: 1rem;
	--form-sub-header-weight: #{$msv-font-weight-heavy};
	--form-sub-header-color: var(--brandTextColour);

  --form-field-margin: 1rem;

  --form-checkbox-margin: 0 .5rem 0 0;

  --form-datepicker-input-colour: #{$msv-black};

  --form-datepicker-bg: #{$msv-white};
  --form-datepicker-colour: #{$msv-black};
  --form-datepicker-padding: .35rem 8px;
  --form-datepicker-width: 100%;
  
  --form-event-select-colour: #{$msv-black};
  --form-event-select-colour--remove: var(--brandPrimaryColor);

  --form-select-border-radius: 0;
  --form-select-colour: #{$msv-black};

	--form-input-border: none;
	--form-input-box-shadow: none;
	--form-input-radius: 0;
	--form-input-width-moz: 80%;

	--form-input-other-margin: 0 0 0 .5rem;
	--form-input-other-width: 30%;

	--form-input-textarea-height-mobile: 10rem;

  --form-label-margin: 0;

	--form-radio-colour: var(--brandTextColour);
	--form-radio-margin: 0 .5rem 0 0;
  --form-radio-padding: 0;

	--form-radio-input-margin-right: .2rem;

  --form-input-wrapper-icon-colour--warning: #{$amber};
  --form-input-wrapper-icon-right: 10px;
  --form-input-wrapper-icon-top: 12px;

	--form-border: 1px solid var(--brandTextColour);

	--form-subtotal-colour: var(--brandTextColour);
	--form-subtotal-size: 1.5rem;
	--form-subtotal-weight: #{$msv-font-weight-heavy};

	--form-del-bg: none;
	--form-del-border: none;
	--form-del-border-radius: 0;
	--form-del-box-shadow: none;
	--form-del-colour: var(--brandTextColour);
	--form-del-icon-size: 1rem;

	--form-add-bg: var(--brandSecondaryColor);
	--form-add-border: none;
	--form-add-colour: var(--brandTextColour);
	--form-add-size: 1rem;
	--form-add-padding: .8rem 2rem;

	--form-add-icon-margin: 0 .5rem 0 0;
	--form-add-icon-size: 1rem;

  // MOBILE STYLINGS
	--form-record-margin-mobile: 0 0 1rem 0;
	--form-record-padding-mobile: .5rem 0;

	--form-item-padding-mobile: .5rem 1rem;

  --form-field-height-mobile: min-content;

  --form-radio-other-margin-mobile: 0 0 .5rem 0;

	--form-add-size-mobile: .8rem;
	--form-add-padding-mobile: 0;
	--form-add-weight-mobile: #{$msv-font-weight-heavy};

	--form-add-icon-size-mobile: 1.5rem;
	
}

.ecomm-purchase-form {
	&-table {
    margin: var(--table-margin);
		width: var(--table-width);

		.form-record {
			@include breakpoint($msv-breakpoint-l) {
				margin: var(--form-record-margin-mobile);
				padding: var(--form-record-padding-mobile);
			}

			background-color: var(--form-record-bg);

			.form-details {
				&-item {
					@include breakpoint($msv-breakpoint-l) { padding: var(--form-item-padding-mobile); }

          &:not(:first-child) {
            @include display-flex;
            @include align-items(center);
          }

					padding: var(--form-item-padding);
					width: var(--form-item-width);

          // First row.
          > div.row {
            &:not(:last-child) { margin-bottom: var(--form-field-margin); }

            // Second level child row.
            > div.row {  
              margin-bottom: var(--form-field-margin);

              // Third level child row.
              > div.row:not(:last-child) { margin-bottom: var(--form-field-margin); }
            }
          } 

          .form-control {
            display: inherit;
						border: var(--form-input-border);
						border-radius: var(--form-input-radius);

						&:focus { box-shadow: var(--form-input-box-shadow); }

						@-moz-document url-prefix() { width: var(--form-input-width-moz); }

						&-other {
							display: inline-block;
							margin: var(--form-input-other-margin);
							width: var(--form-input-other-width);
						}
					}

          .form-field {
						@include breakpoint($msv-breakpoint-l) { min-height: var(--form-field-height-mobile); }

            .checkbox-option { 
              > input { margin: var(--form-checkbox-margin); }
            }

            .datepicker {
              background-color: var(--form-datepicker-bg);
              color: var(--form-datepicker-colour);
              padding: var(--form-datepicker-padding);
              width: var(--form-datepicker-width);

              .react-date-picker__wrapper { border: none; }
            }

            .eventSelect__menu { color: var(--form-event-select-colour); }

						.eventSelect__multi-value__remove { color: var(--form-event-select-colour--remove); }

            label { margin: var(--form-label-margin); }

            .radio-option {
              @include breakpoint($msv-breakpoint-l) {
                display: block;
                margin: var(--form-radio-other-margin-mobile);
              }  

              color: var(--form-radio-colour);
              display: inline;
              padding: var(--form-radio-padding);

              &:not(:last-child) { margin: var(--form-radio-margin); }
              
              > input { margin-right: var(--form-radio-input-margin-right); }
            }

            .select div { 
              border: none;
              border-radius: var(--form-select-border-radius);
              box-shadow: none;
              color: var(--form-select-colour);
            }

            .input-wrapper {
              display: inline-block;
              position: relative;

              &.warning {
                @include add-icon($msv-Warning, after);
  
                &::after {
                  color: var(--form-input-wrapper-icon-colour--warning);
                  position: absolute;
                  right: var(--form-input-wrapper-icon-right);
                  top: var(--form-input-wrapper-icon-top);
                }
              }
            }
					}

          .form-label {
            font-size: var(--form-label-size);
            font-weight: var(--form-label-weight);
            margin: var(--form-label-margin);
            padding: var(--form-label-padding);

            &.required::after {
              color: var(--form-label-colour--req);
              content: '*';
            }
          }

          &.edit { @include justify-content(end); }

          .prompt { 
            color: var(--form-prompt-colour);
            font-size: var(--form-prompt-size);
            margin: var(--form-prompt-margin); 
            padding: var(--form-prompt-padding);
          }

					.form-sub-hr { border-color: var(--form-sub-hr-color); }

					.form-sub-header {
						font-size: var(--form-sub-header-size);
						padding-bottom: var(--form-sub-header-padding);
						font-weight: var(--form-sub-header-weight);
						color: var(--form-sub-header-color);
					}

					textarea.form-control {
						@include breakpoint($msv-breakpoint-l) { height: var(--form-input-textarea-height-mobile); }
					}

					&.bordered {
						border-left: var(--form-border);
						border-right: var(--form-border);
					}

					&.centred { @include justify-content(center); }

					.subtotal {
						color: var(--form-subtotal-colour);
						font-size: var(--form-subtotal-size);
						font-weight: var(--form-subtotal-weight);
					}

					.add-row {
						@include add-icon($msv-PlusCircle, before, inline, var(--form-add-icon-size-mobile));

						background-color: transparent;
						border: none;
						color: var(--form-add-colour);
						font-size: var(--form-add-size-mobile);
						font-weight: var(--form-add-weight-mobile);
						padding: var(--form-add-padding-mobile);

						&:before {
							margin: var(--form-add-icon-margin);
							vertical-align: bottom;
						}
					}

					> .delete-wrapper {
            @include display-flex;
            @include justify-content(end);
					}

					.delete_row {
						@include add-icon($msv-trash, before, inline-block, var(--form-del-icon-size));

						background: var(--form-del-bg);
						border: var(--form-del-border);
						border-radius: var(--form-del-border-radius);
						box-shadow: var(--form-del-box-shadow);
						color: var(--form-del-colour);
					}
				}
			}

      &:not(:last-child) { margin: var(--form-record-margin); }
		}
	}

	&-add {
		button {
			@include add-icon($msv-PlusCircle, before, inline, var(--form-add-icon-size));

			background-color: var(--form-add-bg);
			border: var(--form-add-border);
			color: var(--form-add-colour);
			font-size: var(--form-add-size);
			padding: var(--form-add-padding);
			text-align: center;

			&::before { margin: var(--form-add-icon-margin); }
		}
	}
	&-upload {
		button {
			background-color: var(--form-add-bg);
			border: var(--form-add-border);
			color: var(--form-add-colour);
			font-size: var(--form-add-size);
			padding: var(--form-add-padding);
			text-align: center;

			&::before { margin: var(--form-add-icon-margin); }
		}
	}
}
