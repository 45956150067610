:root {
  --ecomm-prog-card-bg: rgba(255,255,255,0.5);
  --ecomm-prog-card-padding: 1rem;

  --ecomm-prog-card-img-wrapper-padding: 0 .5rem 0 0;

  --ecomm-prog-card-content-wrapper-colour: #{$msv-black};
  --ecomm-prog-card-content-wrapper-padding: 0 0 0 .5rem;
  --ecomm-prog-card-content-wrapper-size: .8rem;

  --ecomm-prog-card-content-info-colour: #{$msv-gray-400};

  --ecomm-prog-card-type-margin: 0 0 .5rem 0;

  --ecomm-prog-card-title-colour: var(--brandPrimaryColor);
  --ecomm-prog-card-title-margin: 0 0 .5rem 0;
  --ecomm-prog-card-title-weight: bold;

  --ecomm-prog-card-description-margin: .5rem 0 0 0;

  --ecomm-prog-card-redirect-wrapper-margin: 1rem 0 0 0;
  --ecomm-prog-card-redirect-wrapper-padding: 0;

  --ecomm-prog-card-redirect-bg: var(--brandPrimaryColor);
  --ecomm-prog-card-redirect-colour: var(--brandTextColour);
  --ecomm-prog-card-redirect-portal-colour: #6CD20A;
  --ecomm-prog-card-redirect-padding: .5rem 1rem;

  // MOBILE STYLINGS
  --ecomm-prog-card-img-wrapper-padding-mobile: 0;

  --ecomm-prog-card-content-wrapper-margin-mobile: 1rem 0 0 0;
  --ecomm-prog-card-content-wrapper-padding-mobile: 0;

  --ecomm-prog-card-img-wrapper-padding-dd: 0 .5rem 0 0;

  --ecomm-prog-card-content-wrapper-margin-dd: 0;
  --ecomm-prog-card-content-wrapper-padding-dd: 0 0 0 .5rem;
}

.ecomm-programme-card {
  background-color: var(--ecomm-prog-card-bg);
  padding: var(--ecomm-prog-card-padding);

  > .img-wrapper { 
    @include breakpoint($msv-breakpoint-l) { padding: var(--ecomm-prog-card-img-wrapper-padding-mobile); }

    padding: var(--ecomm-prog-card-img-wrapper-padding);
  }

  > .content-wrapper {
    @include breakpoint($msv-breakpoint-l) { 
      margin: var(--ecomm-prog-card-content-wrapper-margin-mobile);
      padding: var(--ecomm-prog-card-content-wrapper-padding-mobile); 
    }

    color: var(--ecomm-prog-card-content-wrapper-colour);
    font-size: var(--ecomm-prog-card-content-wrapper-size);
    padding: var(--ecomm-prog-card-content-wrapper-padding);

    .type, .time { color: var(--ecomm-prog-card-content-info-colour); }

    .type { margin: var(--ecomm-prog-card-type-margin); }

    .name {
      color: var(--ecomm-prog-card-title-colour);
      font-weight: var(--ecomm-prog-card-title-weight);
      margin: var(--ecomm-prog-card-title-margin);
    }

    .description { margin: var(--ecomm-prog-card-description-margin); }

    .redirect-wrapper {
      @include display-flex;

      align-items: end;
      margin: var(--ecomm-prog-card-redirect-wrapper-margin);
      padding: var(--ecomm-prog-card-redirect-wrapper-padding);

      > .redirect {
        background-color: var(--ecomm-prog-card-redirect-bg);
        border: none;
        color: var(--ecomm-prog-card-redirect-colour);
        padding: var(--ecomm-prog-card-redirect-padding);

        &.portal {
          color: var(--ecomm-prog-card-redirect-portal-colour);
        }

        &:hover {
          color: var(--ecomm-prog-card-redirect-colour);
          text-decoration: none;

          &.portal {
            color: var(--ecomm-prog-card-redirect-portal-colour);
          }
        }
      }
    }
  }

  &.dropdown {
    > .img-wrapper {
      @include breakpoint($msv-breakpoint-l) { padding: var(--ecomm-prog-card-img-wrapper-padding-dd); }
    }

    > .content-wrapper {
      @include breakpoint($msv-breakpoint-l) {
        margin: var(--ecomm-prog-card-content-wrapper-margin-dd);
        padding: var(--ecomm-prog-card-content-wrapper-padding-dd);
      }
    }
  }
}