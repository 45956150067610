$buybox-font-size: 14px;
$buybox-content-items-padding-top: 20px;
$buybox-content-items-padding-top-small: 12px;
$buybox-margin-top: 36px;
$buybox-button-height:32px;
$buybox-quantity-width:40px;
$buybox-find-in-store-description-padding-bottom:7px;
$buybox-product-title-line-height:32px;
$buybox-quantity-label-line-height:20px;
$msv-buybox-text-line-height: 21px;
$buybox-label-margin-bottom: 5px;
$buybox-dropdown-height:32px;
$buybox-ratings-margin-top:10px;
$msv-dialog-width: 400px;
$msv-dialog-button-margin: 15px auto;
$msv-dialog-button-padding: 6px 16px 7px;
$msv-dialog-button-width: 100%;
$buybox-custom-input-width:80px;
$buybox-custom-input-height:32px;

:root {
  //title
  --msv-buybox-title-font-size: var(--msv-body-font-size-xl);
  --msv-buybox-title-font-color: var(--msv-font-primary-color);
  --msv-buybox-title-font-weight: #{$msv-font-weight-heavy};
  --msv-buybox-btn-wishlist-color: var(--msv-accent-brand-color);

  //text
  --msv-buybox-text-font-size: var(--msv-body-font-size-m);
  --msv-buybox-text-font-color: var(--msv-font-primary-color);

  // primary button
  --msv-buybox-primary-btn-bg: var(--msv-accent-brand-color);
  --msv-buybox-primary-btn-font-color: var(--msv-font-secondary-color);
  --msv-buybox-primary-btn-border: var(--msv-accent-brand-color);

  // secondary button
  --msv-buybox-secondary-btn-bg: var(--msv-bg-color);
  --msv-buybox-secondary-btn-font-color: var(--msv-font-primary-color);
  --msv-buybox-secondary-btn-border: var(--msv-accent-brand-color);
}

@mixin buybox-button {
  height: $buybox-button-height;
  width: 100%;
}

.ms-buybox {
  &__content {
    color: var(--msv-font-primary-color);
    font-size: $buybox-font-size;
    flex-grow: 2;
  }

  &__product-title {
    @include font-content($msv-font-weight-bold, var(--msv-buybox-title-font-size),
    $buybox-product-title-line-height);

    color: var(--msv-buybox-title-font-color);
  }

  &__product-description {
    @include font-content($msv-font-weight-light, var(--msv-buybox-text-font-size),
    $msv-buybox-text-line-height);
    
    color: var(--msv-buybox-text-font-color);
  }

  &__dropdown,
  &__quantity { padding-top: $buybox-content-items-padding-top; }

  &__find-in-store-heading,
  &__find-in-store-description { display: none; }

  &__ratings-link {
    @include vfi();
    
    display: inline-block;
  }

  .msc-price__actual,
  .ms-buybox__dropdown-quantity-label,
  .ms-buybox__product-quantity-label-heading,
  .ms-buybox__key_in_price-label {
    color: var(--msv-buybox-title-font-color);
    font-weight: var(--msv-buybox-title-font-weight);
    line-height: $buybox-quantity-label-line-height;
  }

  .msc-price__actual { font-size: var(--msv-buybox-text-font-size); }

  .msc-no-ratings { margin-top: $buybox-ratings-margin-top; }

  .msc-dropdown__select {
    @include vfi();
    
    height: $buybox-dropdown-height;
    width: 100%;
  }

  .msc-quantity-input {
    @include vfi();
    
    height: $buybox-dropdown-height;
    text-align: center;
    width: $buybox-quantity-width;
  }

  &__add-to-wishlist-container {
    padding-top: $buybox-content-items-padding-top-small;

    .msc-add-to-wishlist {
      @include buybox-button();
      @include secondary-button();

      &::after { color: var(--msv-buybox-btn-wishlist-color); }

      border-color: transparent;
      background-color: transparent;
      width: $buybox-button-height;
    }
  }

  &__find-in-store-description { padding-bottom: $buybox-find-in-store-description-padding-bottom; }

  &__find-in-store {
    padding-top: $buybox-content-items-padding-top;

    .ms-buybox__find-in-store-button {
      @include buybox-button();
      @include secondary-button(var(--msv-buybox-secondary-btn-bg),
      var(--msv-buybox-secondary-btn-font-color), var(--msv-buybox-secondary-btn-border));
      @include vfi();

      &:not(:disabled) {
        &:hover { cursor: pointer; }
      }
    }
  }

  &__key_in_price_custom-amount__input {
    @include vfi();
    
    height: $buybox-custom-input-height;
    text-align: left;
    width: $buybox-custom-input-width;
  }

  label {
    display: block;
    margin-bottom: $buybox-label-margin-bottom;
  }

  &__shop-similar-looks-button {
    @include button-default();
    @include secondary-button(var(--msv-buybox-secondary-btn-bg), var(--msv-buybox-secondary-btn-font-color), var(--msv-buybox-secondary-btn-border));
    
    margin-bottom: 20px;
  }

  &__shop-similar-description-button {
    @include button-default();
    @include secondary-button(var(--msv-buybox-secondary-btn-bg), var(--msv-buybox-secondary-btn-font-color), var(--msv-buybox-secondary-btn-border));
  }
}

.msc-modal {
  .msc-modal__dialog.ms-buybox__dialog {
    max-width: $msv-dialog-width;

    .msc-modal__content { max-width: $msv-dialog-width; }

    .ms-buybox__dialog__body { text-align: center; }

    .ms-buybox__dialog__image {
      margin-right: auto;
      margin-left: auto;
    }

    .ms-buybox__dialog__go-to-cart.msc-btn {
      @include primary-button(var(--msv-buybox-primary-btn-bg),
      var(--msv-buybox-primary-btn-font-color), var(--msv-buybox-primary-btn-border));
      
      display: block;
      margin: $msv-dialog-button-margin;
      padding: $msv-dialog-button-padding;
      width: $msv-dialog-button-width;
    }

    .ms-buybox__dialog__back-to-shopping.msc-btn {
      @include secondary-button(var(--msv-buybox-secondary-btn-bg),
      var(--msv-buybox-secondary-btn-font-color), var(--msv-buybox-secondary-btn-border));
      
      display: block;
      margin: $msv-dialog-button-margin;
      padding: $msv-dialog-button-padding;
      width: $msv-dialog-button-width;
    }
  }
}
