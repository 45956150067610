$msv-checkout-button-margin: 15px auto;
$msv-checkout-button-text-align: center;
$msv-checkout-button-align-items: center;
$msv-checkout-button-justify-content: center;
$msv-checkout-button-width: 100%;
$msv-checkout-button-border-radius: 2px;
$divider-vertical-margin: 20px;
$cart-items-right-margin: 40px;
$cart-empty-image-width: 183px;
$cart-empty-image-width-m: 80px;
$summary-min-width: 320px;
$summary-max-width: 480px;
$cart-heading-bottom: 20px;
$cart-heading-top: 32px;
$cart-cartline-wrapper-grp-padding: 32px;
$cart-cartline-wrapper-margin-right: 40px;
$cart-cartline-wrapper-margin-bottom: 1rem;
$cart-cartline-line-padding-right: 20px;
$cart-content-margin-left: 200px;
$cart-cartline-line-product-padding-bottom: 10px;
$cart-cartline-line-product-price-padding-top: 10px;
$cart-cartline-line-product-price-margin-left: 0px;
$cart-cartline-item-content-margin-left-m: 100px;
$cart-promo-code-input-width-m: 170px;
$cart-order-summary-padding-m: 20px;
$cart-promo-code-input-height: 50px;
$msv-promotion-icon-right-margin: 8px;
$cart-promotion-padding: 20px;
$cart-promotion-margin-bottom: 5px;
$cart-promotion-popup-close-button-padding: 5px;
$cart-lines-group-heading-padding-top: 12px;
$cart-lines-group-heading-padding-left: 76px;
$cart-line-group-extra-action-margin-top: 75px;
$cart-line-group-extra-action-margin-left: 200px;
$cart-lines-item-background-color: #9B3347;

$shopping-cart-badge-border-radius: 50%;
$shopping-cart-badge-padding: 0 .4em;
$shopping-cart-padding: 20px;

:root {
  --ecomm-cart-empty-colour: #{$msv-black};

  --ecomm-cart-item-bg: var(--brandPrimaryColor);
  --ecomm-cart-item-colour: var(--brandTextColour);
  --ecomm-cart-item-margin: 0 0 1rem 0;
  --ecomm-cart-item-padding: 1.5rem;

  --ecomm-cart-item-price-padding: 0;
  --ecomm-cart-item-price-size: 1.5rem;
  --ecomm-cart-item-price-weight: #{$msv-font-weight-heavy};

  --ecomm-cart-item-product-weight: #{$msv-font-weight-light};

  --ecomm-cart-item-info-margin: .5rem 0;
  --ecomm-cart-item-info-weight: #{$msv-font-weight-light};

  --ecomm-cart-item-action-colour: var(--brandTextColour);
  --ecomm-cart-item-actions-weight: #{$msv-font-weight-heavy};

  --ecomm-cart-item-actions-p-margin: 0 .2rem;

  --ecomm-cart-item-actions-remove-colour: var(--brandTextColour);
  --ecomm-cart-item-actions-remove-padding: 0;

  --ecomm-cart-item-actions-remove-unavailable-colour: #ffffff;

  --msv-cart-heading-font-size: var(--msv-heading-font-size-m);
  --msv-cart-heading-font-color: var(--msv-font-primary-color);

  --ecomm-cart-badge-bg: var(--msv-font-primary-color);
  --ecomm-cart-badge-font-colour: var(--msv-accent-brand-color);
  --ecomm-cart-checkout-button-border-colour: var(--msv-font-primary-color);
  --ecomm-cart-checkout-button-font-colour: var(--msv-font-primary-color);

  --ecomm-cart-unavailable-line-wrapper-padding-top: 2rem;

  --ecomm-cart-unavailable-line-header-color: var(--brandTextColour);
  --ecomm-cart-unavailable-line-header-padding: 0 0 1rem 0;
  --ecomm-cart-unavailable-line-header-font-size: 18px;

  --ecomm-cart-unavailable-line-accordion-margin: 0;

  --ecomm-cart-unavailable-line-accordion-card-border: 0;
  --ecomm-cart-unavailable-line-accordion-card-header-background-color: transparent;
  --ecomm-cart-unavailable-line-accordion-card-header-color: #333333;
  --ecomm-cart-unavailable-line-accordion-card-header-border-bottom: 0;
  --ecomm-cart-unavailable-line-accordion-card-header-border-top: 1px solid #D3D3EB;
  --ecomm-cart-unavailable-line-accordion-card-header-margin: 0;
  --ecomm-cart-unavailable-line-accordion-card-header-padding: .75rem 0;

  --ecomm-cart-unavailable-line-accordion-card-header-content-unavailable-font-size: 20px;
  --ecomm-cart-unavailable-line-accordion-card-header-content-unavailable-font-weight: bold;
  --ecomm-cart-unavailable-line-accordion-card-header-content-unavailable-padding: 0;
  
  --ecomm-cart-unavailable-line-accordion-card-header-content-toggle-color: #6A6A6A;
  --ecomm-cart-unavailable-line-accordion-card-header-content-toggle-font-size: 16px;
  --ecomm-cart-unavailable-line-accordion-card-header-content-toggle-text-align: right;
  --ecomm-cart-unavailable-line-accordion-card-header-content-toggle-padding: 0;
  
  --ecomm-cart-unavailable-line-accordion-card-header-content-toggle-collapsed-before-margin: 0 .5rem;
  --ecomm-cart-unavailable-line-accordion-card-header-content-toggle-view-before-margin: 0 .5rem;
  
  --ecomm-cart-unavailable-line-accordion-card-body-padding: 0 0 1.25rem 0;
  --ecomm-cart-unavailable-line-accordion-card-body-color: #333333;
  
  --ecomm-cart-unavailable-line-accordion-card-body-header-padding: 1rem 0;
  
  --ecomm-cart-unavailable-line-accordion-card-body-header-message-font-size: 16px;
  --ecomm-cart-unavailable-line-accordion-card-body-header-message-padding: 0;
  --ecomm-cart-unavailable-line-accordion-card-body-header-message-margin: auto;
  
  --ecomm-cart-unavailable-line-accordion-card-body-header-button-padding: 0;

  --ecomm-cart-unavailable-line-accordion-card-body-header-button-button-background-color: #ffffff;
  --ecomm-cart-unavailable-line-accordion-card-body-header-button-button-border: 1px solid #{$msv-gray-700};
  --ecomm-cart-unavailable-line-accordion-card-body-header-button-button-color: var(--brandPrimaryColor);
  --ecomm-cart-unavailable-line-accordion-card-body-header-button-button-padding: 12px 16px;
  --ecomm-cart-unavailable-line-accordion-card-body-header-button-button-font-size: 16px;
  --ecomm-cart-unavailable-line-accordion-card-body-header-button-button-font-weight: bold;
  --ecomm-cart-unavailable-line-accordion-card-body-header-button-button-float: right;

  --ecomm-cart-unavailable-line-accordion-card-body-header-button-button-focus-background-color: #ffffff;
  --ecomm-cart-unavailable-line-accordion-card-body-header-button-button-focus-box-shadow: none;
  --ecomm-cart-unavailable-line-accordion-card-body-header-button-button-focus-color: var(--brandPrimaryColor);

  --ecomm-cart-unavailable-line-accordion-card-body-header-button-button-hover-background-color: #ffffff;
  --ecomm-cart-unavailable-line-accordion-card-body-header-button-button-hover-color: var(--brandPrimaryColor);

  --ecomm-cart-unavailable-line-accordion-card-body-header-button-mobile-button-float: left;

  --ecomm-cart-item-unavailable-background-color: #858BA2;
  --ecomm-cart-item-unavailable-color: #ffffff;

  // MOBILE STYLINGS.
  --ecomm-cart-checkout-bg-mobile: #{msv-white};
  --ecomm-cart-checkout-border-mobile: 1px solid #{$msv-gray-300};
  --ecomm-cart-checkout-colour-mobile: var(--brandPrimaryColor);
  --ecomm-cart-checkout-margin-mobile: 1rem 0 0 0;
  --ecomm-cart-checkout-padding-mobile: .8rem 0;
  --ecomm-cart-checkout-weight-mobile: bold;
  --ecomm-cart-checkout-width-mobile: 100%;
}

.ecomm-cart {
  > .empty { color: var(--ecomm-cart-empty-colour); }

  .msc-cart-line {   
    &__product-image {
      @include display-flex;
      @include justify-content(center);

      .msc-empty_image { @include image-placeholder($cart-empty-image-width); }
    }
  }

  &-unavailable-line {
    &-wrapper {
      padding-top: var(--ecomm-cart-unavailable-line-wrapper-padding-top);
    }

    &-header {
      color: var(--ecomm-cart-unavailable-line-header-color);
      padding: var(--ecomm-cart-unavailable-line-header-padding);
      font-size: var(--ecomm-cart-unavailable-line-header-font-size);
    }

    &-accordion {
      margin: var(--ecomm-cart-unavailable-line-accordion-margin);

      &-card {
        border: var(--ecomm-cart-unavailable-line-accordion-card-border);

        &-header {
          background-color: var(--ecomm-cart-unavailable-line-accordion-card-header-background-color);
          color: var(--ecomm-cart-unavailable-line-accordion-card-header-color);
          border-bottom: var(--ecomm-cart-unavailable-line-accordion-card-header-border-bottom);
          border-top: var(--ecomm-cart-unavailable-line-accordion-card-header-border-top);
          margin: var(--ecomm-cart-unavailable-line-accordion-card-header-margin);
          padding: var(--ecomm-cart-unavailable-line-accordion-card-header-padding);

          &-content {
            &-unavailable {
              font-size: var(--ecomm-cart-unavailable-line-accordion-card-header-content-unavailable-font-size);
              font-weight: var(--ecomm-cart-unavailable-line-accordion-card-header-content-unavailable-font-weight);
              padding: var(--ecomm-cart-unavailable-line-accordion-card-header-content-unavailable-padding);
            }

            &-toggle {
              color: var(--ecomm-cart-unavailable-line-accordion-card-header-content-toggle-color);
              font-size: var(--ecomm-cart-unavailable-line-accordion-card-header-content-toggle-font-size);
              text-align: var(--ecomm-cart-unavailable-line-accordion-card-header-content-toggle-text-align);
              padding: var(--ecomm-cart-unavailable-line-accordion-card-header-content-toggle-padding);
            }

            &-toggle.collapsed {
              @include add-icon($msv-angle-up, before, inline);

              &::before { margin: var(--ecomm-cart-unavailable-line-accordion-card-header-content-toggle-collapsed-before-margin) }
            }

            &-toggle.view {
              @include add-icon($msv-angle-down, before, inline);

              &::before { margin: var(--ecomm-cart-unavailable-line-accordion-card-header-content-toggle-view-before-margin) }
            }
          }
        }

        &-body {
          padding: var(--ecomm-cart-unavailable-line-accordion-card-body-padding);
          color: var(--ecomm-cart-unavailable-line-accordion-card-body-color);

          &-header {
            padding: var(--ecomm-cart-unavailable-line-accordion-card-body-header-padding);

            &-message {
              font-size: var(--ecomm-cart-unavailable-line-accordion-card-body-header-message-font-size);
              padding: var(--ecomm-cart-unavailable-line-accordion-card-body-header-message-padding);
              margin: var(--ecomm-cart-unavailable-line-accordion-card-body-header-message-margin);
            }

            &-button {
              padding: var(--ecomm-cart-unavailable-line-accordion-card-body-header-button-padding);

              > button {
                background-color: var(--ecomm-cart-unavailable-line-accordion-card-body-header-button-button-background-color);
                border: var(--ecomm-cart-unavailable-line-accordion-card-body-header-button-button-border);
                color: var(--ecomm-cart-unavailable-line-accordion-card-body-header-button-button-color);
                padding: var(--ecomm-cart-unavailable-line-accordion-card-body-header-button-button-padding);
                font-size: var(--ecomm-cart-unavailable-line-accordion-card-body-header-button-button-font-size);
                font-weight: var(--ecomm-cart-unavailable-line-accordion-card-body-header-button-button-font-weight);
                float: var(--ecomm-cart-unavailable-line-accordion-card-body-header-button-button-float);
        
                &:focus {
                  background-color: var(--ecomm-cart-unavailable-line-accordion-card-body-header-button-button-focus-background-color);
                  box-shadow: var(--ecomm-cart-unavailable-line-accordion-card-body-header-button-button-focus-box-shadow);
                  color: var(--ecomm-cart-unavailable-line-accordion-card-body-header-button-button-focus-color);
                }
            
                &:hover {
                  background-color: var(--ecomm-cart-unavailable-line-accordion-card-body-header-button-button-hover-background-color);
                  color: var(--ecomm-cart-unavailable-line-accordion-card-body-header-button-button-hover-color);
                }
              }
            }

            &-button.mobile {
              > button {
                float: var(--ecomm-cart-unavailable-line-accordion-card-body-header-button-mobile-button-float);
              }
            }
          }
        }
      }
    }
  }

  &-item.unavailable {
    background-color: var(--ecomm-cart-item-unavailable-background-color);
    color: var(--ecomm-cart-item-unavailable-color);
  }

  &-item {
    background-color: var(--ecomm-cart-item-bg);
    color: var(--ecomm-cart-item-colour);
    padding: var(--ecomm-cart-item-padding);

    &:not(:last-child) { margin: var(--ecomm-cart-item-margin); }

    .price {
      font-size: var(--ecomm-cart-item-price-size);
      font-weight: var(--ecomm-cart-item-price-weight);
      padding: var(--ecomm-cart-item-price-padding);
      text-align: end;
    }

    .product { font-weight: var(--ecomm-cart-item-product-weight);}


    .information { 
      @include breakpoint($msv-breakpoint-l) { margin: var(--ecomm-cart-item-info-margin); }

      font-weight: var(--ecomm-cart-item-info-weight); 
    }

    .actions {
      font-weight: var(--ecomm-cart-item-actions-weight);
      display: flex;

      > a, button.remove { vertical-align: middle; }

      > a {
        margin-top: auto;
        color: var(--ecomm-cart-item-action-colour);
        text-decoration: none;

        &:hover {
          color: var(--ecomm-cart-item-action-colour);
          text-decoration: none;
        }
      }

      > p {
        margin: var(--ecomm-cart-item-actions-p-margin);
        margin-top: auto;
        display: inline;
      } 

      > button.remove {
        margin-top: auto;
        background-color: transparent;
        border: none;
        color: var(--ecomm-cart-item-actions-remove-colour);
        padding: var(--ecomm-cart-item-actions-remove-padding);
      }

      > button.remove.unavailable {
        color: var(--ecomm-cart-item-actions-remove-unavailable-colour);
      }
    }
  }

  > .checkout {
    background-color: var(--ecomm-cart-checkout-bg-mobile);
    border: var(--ecomm-cart-checkout-border-mobile);
    color: var(--ecomm-cart-checkout-colour-mobile);
    font-weight: var(--ecomm-cart-checkout-weight-mobile);
    justify-content: center;
    margin: var(--ecomm-cart-checkout-margin-mobile);
    padding: var(--ecomm-cart-checkout-padding-mobile);
    text-decoration: none;
    width: var(--ecomm-cart-checkout-width-mobile);
  }

  // STYLINGS BELOW MIGHT NOT BE NECESSARY.
  .msc-cart__heading {
    @include font-content(var(--msv-font-weight-heavy), var(--msv-cart-heading-font-size), $msv-title-line-height-xs);
    color: var(--msv-cart-heading-font-color);
    flex: 0 0 100%;
    margin-bottom: $cart-heading-bottom;
    margin-top: $cart-heading-top;
    position: relative;

    span {
      background-color: var(--ecomm-cart-badge-bg);
      border-radius: $shopping-cart-badge-border-radius;
      color: var(--ecomm-cart-badge-font-colour);
      padding: $shopping-cart-badge-padding;
      text-align: center;
      vertical-align: initial;
    }
  }

  .msc-cart-line__content,
  .msc-cart-line__product,
  .msc-cart-line__quantity__select-menu,
  .msc-cart-line__product-price {
    display: none;
  }

  .msc-cart-lines-item > .msc-cart-line__add-to-wishlist {
    margin-left: auto;
  }

  .msc-cart-lines-item > .msc-cart-line__add-to-wishlist {
    margin-left: $cart-content-margin-left;
  }

  .msc-promo-code {
    &__input-box {
      height: $cart-promo-code-input-height;
    }
  }

  .msc-cart-lines-group {
    flex: 1;
    margin-right: $cart-cartline-wrapper-margin-right;
    padding-top: $cart-cartline-wrapper-grp-padding;
  }

  .msc-cart-lines-group-wraper {
    background-color: $msv-white;
    flex: 1;
    margin-bottom: $cart-cartline-wrapper-margin-bottom;
      .msc-cart-line-group__extra-actions {
        margin-left: $cart-line-group-extra-action-margin-left;
        margin-top: $cart-line-group-extra-action-margin-top;
      }
  }
  
  .msc-cart__promotion {
    width: 100%;
    background-color: $msv-white;
    padding: $cart-promotion-padding;
    margin-bottom: $cart-promotion-margin-bottom;

    &_link {
      margin-left: 8px;
      text-decoration: underline;
      cursor: pointer;
    }

    .msi-promotion-icon {
      margin-right: $msv-promotion-icon-right-margin;
      @include add-icon($msv-DollarSign, before);
      display: none;
    }

    &-popup {
      position: absolute;
      width: auto;
      height: auto;
      z-index: 2;
      background-color: $msv-white;
      left: 0;

      &_content {
        color: var(--msv-font-primary-color);
        background-clip: padding-box;
        background-color: var(--msv-bg-color);
        border: 1px solid $msv-gray-400;
        outline: 0;
        pointer-events: auto;
        position: relative;
        width: 400px;
        min-width: 335px;
        padding: 20px 10px;

        .msc-cart__promotion-popup_title {
          @include font-content-xl();

          margin: 0;
          text-transform: none;
        }

        .msc-cart__promotion-popup_close-button {
          @include add-icon($msv-x-shape);
          @include vfi();

          background-color: transparent;
          border: 0;
          color: var(--msv-font-primary-color);
          font-size: 20px;
          padding: $cart-promotion-popup-close-button-padding;
          position: absolute;
          right: 15px;
          text-shadow: 0 1px 0 var(--msv-bg-color);
          top: 22px;
        }

        ul {
          list-style: none;
          padding: 0px;

          .msc-cart__promotion-popup_name {
            display: block;
          }
        }
      }
    }
  }

  @media screen and (max-width: $msv-breakpoint-m) {
    .msc-cart__promotion {
      &-popup_content { width: 335px; }
    }
  }
}

.msc-cart__btn-checkout,
.msc-cart__btn-guestcheckout,
.msc-promo-code__apply-btn {
  @include button-default();
  @include primary-button(var(--msv-cart-primary-btn-bg), var(--msv-cart-primary-btn-font-color), var(--msv-cart-primary-btn-border));

  align-items: $msv-checkout-button-align-items;
  @include display-flex;
  justify-content: $msv-checkout-button-justify-content;
}

.msc-cart__btn {
  &-backtoshopping{
    @include button-link(var(--msv-cart-btn-link-color));
  }

  &-checkout {
    @include button-default();
    @include primary-button(var(--msv-cart-primary-btn-bg), var(--msv-cart-primary-btn-font-color), var(--msv-cart-primary-btn-border));
  }

  &-removeallcartitems {
    @include secondary-button(var(--msv-cart-secondary-btn-bg), var(--msv-cart-secondary-btn-font-color), var(--msv-cart-secondary-btn-border));

    width: 100%;
  }

  &-backtoshopping,
  &-checkout {
    margin: $msv-checkout-button-margin;
    width: $msv-checkout-button-width;
  }  
}

@media screen and (max-width: $msv-breakpoint-m) {
  .ecomm-cart {
    .msc-cart-lines-group-wraper {
      margin-right: 0px;
      width: 100%;
    }

    .msc-cart-lines-group {
      margin-right: 0px;
    }

    .msc-cart-lines-item > .msc-cart-line__add-to-wishlist,
    .msc-cart-lines-item {
      margin-left: $cart-cartline-item-content-margin-left-m;
    }

    .msc-cart-lines-item {
      background-color: $cart-lines-item-background-color;
      margin-bottom: 1rem;
      padding: 1.5rem;

      &-product {
        color: #ffffac;
        font-weight: lighter;
        position: relative;
        min-height: 100%;
  
        &-category {
            font-size: 13px;
            font-weight: 500;
            color: $msv-white;
            margin-bottom: 5px;
        }
  
        &-actions {
          position: absolute;
          bottom: 0;
  
          a {
            color: #ffffac;
              font-weight: bold;
          }
  
          a:hover {
              color: #ffffac;
              text-decoration: none;
          }
  
          a:visited {
              color: #ffffac;
          }
  
          span {
              font-weight: bold;
              margin-left: 3px;
              margin-right: 3px;
          }
        }
      }
  
      &-names {
        color: #ffffac;
        font-weight: lighter;
      }
  
      &-price {
        font-size: 20px;
        font-weight: bold;
        color: #ffffac;
        text-align: right;
      }

      .msc-cart-line {
        color: black;

        &__product-image {
          .msc-empty_image {
            @include image-placeholder($cart-empty-image-width-m);
          }
        }

        &__content {
          display: block;
        }

        &__bopis-container,
        &__extra-actions {
          margin-left: $cart-cartline-item-content-margin-left-m;
        }

        &__product {
          padding-bottom: $cart-cartline-line-product-padding-bottom;

          &-quantity {
            padding-bottom: $cart-cartline-line-product-padding-bottom;
          }

          &-savings {
            margin-left: 0px;
            text-align: left;
          }

          &-price {
            margin-left: $cart-cartline-line-product-price-margin-left;
            padding-top: $cart-cartline-line-product-price-padding-top;
            text-align: left;
          }
        }

        &-group {
          &__extra-actions {
            margin-left: $cart-cartline-item-content-margin-left-m;
            margin-top: $cart-line-group-extra-action-margin-top;
          }
        }
      }
    }

    .msc-promo-code {
      &__input-box {
        width: $cart-promo-code-input-width-m;
      }

      &__apply-btn {
        min-width: auto;
      }
    }

    .msc-order-summary-wrapper {
      display: none;
      padding: $cart-order-summary-padding-m;
      width: 100%;
    }

    .msc-order-summary__items {
      @include font-content-s();
    }
  }
}

.msc-cart-remove-item {
  background-color: Transparent;
    background-repeat:no-repeat;
    border: none;
    cursor:pointer;
    overflow: hidden;
    outline:none;
    font-weight: bold;
    color: #ffffac;
    padding: 0;
}