:root {
  --ecomm-cookie-bg: #{$msv-gray-300};
  --ecomm-cookie-colour: #{$msv-gray-900};
  --ecomm-cookie-padding: 1rem 3rem;

  --ecomm-cookie-content-margin: 0 0 1rem 0;

  --ecomm-cookie-link-colour: var(--brandPrimaryColor);

  --ecomm-cookie-text-colour: var(--brandPrimaryColor);
  --ecomm-cookie-text-weight: bold;

  --ecomm-cookie-accept-bg: #{$msv-white};
  --ecomm-cookie-accept-border: 1px solid #{$msv-gray-300};
  --ecomm-cookie-accept-colour: var(--brandPrimaryColor);
  --ecomm-cookie-accept-padding: .5rem 1rem;
}

.ms-cookie-compliance {
  background-color: var(--ecomm-cookie-bg);
  padding: var(--ecomm-cookie-padding);

  &__container {
    color: var(--ecomm-cookie-colour);

    a, a:hover {
      color: var(--ecomm-cookie-link-colour);
      text-decoration: none;
    }
  }

  &__content { margin: var(--ecomm-cookie-content-margin); }

  &__text { 
    p:first-child {
      color: var(--ecomm-cookie-text-colour);
      font-weight: var(--ecomm-cookie-text-weight);
    }
  }

  &__accept-button {
    background-color: var(--ecomm-cookie-accept-bg);
    border: var(--ecomm-cookie-accept-border);
    color: var(--ecomm-cookie-accept-colour);
    padding: var(--ecomm-cookie-accept-padding);
  }
}