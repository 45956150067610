:root {
    --footer-bg: var(--brandSecondaryColor);
    --footer-container-padding: 0px 50px 70px 50px;
    --footer-top-border: 1px solid #{$msv-white};
    --footer-top-padding: .5rem 0;
    --footer-top-link-colour: #{$msv-white};
    --footer-top-link-weight: bold;
    --footer-top-icon-margin: 0 0 0 .5rem;
    --footer-details-address-font-size: #{$msv-font-size-m};
    --footer-details-address-margin: 1rem 0;
    --footer-details-content-margin: 10px 0 0 0;
    --footer-details-border: 1px solid #{$msv-gray-500};
    --footer-details-padding: 40px 0;
    --footer-details-contact-colour: #{$msv-white};
    --footer-details-contact-margin: 0 0 1rem 0;
    --footer-details-contact-weight: bold;
    --footer-details-contact-icon-margin: 0 0 0 .5rem;
    --footer-details-sns-text-size: #{$msv-font-size-l};
    --footer-details-sns-text-margin: 0 0 .5rem 0;
    --footer-details-sns-icon-height: 2.5rem;
    --footer-font-colour: #{$msv-white};
    --footer-header-font-colour: var(--brandTextColour);
    --footer-info-padding: 0 .8rem 0 0;
    --footer-info-padding-mobile: 0;
    --footer-others-size: #{$msv-font-size-xs};
    --footer-others-padding: 20px 0;
    --footer-others-pages-margin: 0 10px 0 0;
    --footer-others-pages-padding: 0;
    --footer-others-copyright-padding: 0;

    // MOBILE STYLINGS
    --footer-container-padding-mobile: 0 1rem 4rem 1rem;
    --footer-details-padding-mobile: 2rem 0;
    --footer-info-margin-mobile: 1rem 0 0 0;
}

.ecomm-footer {
    @include breakpoint($msv-breakpoint-l) { padding: var(--footer-container-padding-mobile); }

    background-color: var(--footer-bg);
    color: var(--footer-font-colour);
    padding: var(--footer-container-padding);
    
    >.back-to-top {
        border-bottom: var(--footer-top-border);
        padding: var(--footer-top-padding);
        text-align: center;

        >a {
            @include add-icon($msv-Arrow-Up, after);
            @include align-items(center);
        
            color: var(--footer-top-link-colour);
            font-weight: var(--footer-top-link-weight);
        
            &:hover {
                color: var(--footer-top-link-colour);
                text-decoration: none;
            }
        
            &::after { margin: var(--footer-top-icon-margin); }
        }
    }

    &-details {
        @include breakpoint($msv-breakpoint-l) { padding: var(--footer-details-padding-mobile); }

        border-bottom: var(--footer-details-border);
        padding: var(--footer-details-padding);
        
        .info {
            @include breakpoint($msv-breakpoint-l) {
                &:not(:first-child) { margin: var(--footer-info-margin-mobile); }

                padding: var(--footer-info-padding-mobile);
            }

            &:not(:last-child) { padding: var(--footer-info-padding); }
        }

        .information {
            font-size: var(--footer-details-address-font-size);
            margin: var(--footer-details-address-margin);
        }

        .contact-link {
            @include add-icon($msv-ChevronRight, after);
            @include align-items(center);
        
            color: var(--footer-details-contact-colour);
            font-weight: var(--footer-details-contact-weight);
            margin: var(--footer-details-contact-margin);
        
            &:hover {
                color: var(--footer-details-contact-colour);
                text-decoration: none;
            }
        
            &::after { margin: var(--footer-details-contact-icon-margin); }
        }

        h5, .sub-title { color: var(--footer-header-font-colour); }
        
        .social-media {
            &-text {
                font-size: var(--footer-details-sns-text-size);
                margin: var(--footer-details-sns-text-margin);
            }

            &-icon {
                @include display-flex;
                @include justify-content(center);
            
                >img {
                    max-height: var(--footer-details-sns-icon-height);
                    object-fit: contain;
                }
            }
        }

        .content { margin: var(--footer-details-content-margin); }
    }

    &-others {
        font-size: var(--footer-others-size);
        padding: var(--footer-others-padding);
    
        .pages {
            padding: var(--footer-others-pages-padding);
    
            span { margin: var(--footer-others-pages-margin); }
        }
        
        .copyright {
            padding: var(--footer-others-copyright-padding);
            text-align: end;
        }
    }
}