$msv-checkout-margin-bottom: 28px;
$msv-checkout-plain-container-padding-left: 0px;
$msv-checkout-standard-margin-padding: 32px;
$msv-checkout-guided-form-margin-bottom: 20px;
$msv-checkout-guided-card-padding-top: 20px;
$msv-checkout-guided-card-padding-bottom: 20px;
$msv-checkout-guided-card-padding-left: 32px;
$msv-checkout-guided-card-padding-right: 32px;
$msv-checkout-guided-card-body-margin-top: 12px;
$msv-checkout-guided-card-footer-margin-top: 20px;
$msv-checkout-place-order-button-margin: 20px;
$msv-checkout-side-control-margin-top: 20px;
$msv-checkout-side-control-margin-bottom: 20px;
$msv-checkout-side-control-first-margin-top: 0px;
$msv-checkout-side-control-first-margin-auto: 15px auto;
$msv-checkout-side-control-place-order-button-width: 100%;
$msv-checkout-side-control-first-padding: 0 20px 20px 20px;
$msv-checkout-side-control-margin-side: 20px;
$msv-checkout-save-button-margin-left: 10px;
$msv-checkout-shipping-image-width: 80px;
$msv-checkout-shipping-margin-right: 12px;

// checkout__lines styling
$msv-checkout-line-items-padding: 32px;
$msv-checkout-line-image-size: 108px;
$msv-checkout-empty-image-width: $msv-checkout-line-image-size;
$msv-checkout-line-margin-left: 20px;
$msv-checkout-line-content-width: calc(100% - 128px);
$msv-checkout-line-header-margin-bottom: 20px;
$msv-checkout-line-heading-font-weight: 700;
$msv-checkout-line-edit-cart-font-weight: 400;
$msv-checkout-payment-instrument-error-max-width: 512px;
$msv-checkout-payment-instrument-error-padding: 8px;
$msv-checkout-payment-instrument-error-title-margin-bottom: 10px;
$msv-checkout-payment-instrument-error-margin-top: 2px;
$msv-checkout-payment-instrument-message-margin-buttom: 0px;
$msv-checkout-guided-card-title-step-width: 20px;
$msv-checkout-guided-card-title-text-padding-left: 12px;
$msv-checkout-guided-card-title-font-weight: 700;
$msv-checkout-edit-btn-padding: 0;
$msv-checkout-edit-btn-margin-left: auto;
$msv-checkout-cart-line-quantity-margin-left: 0;
$msv-checkout-cart-line-quantity-label-margin-right: 5px;
$msv-checkout-cart-line-price-strikethrough-margin-right: 10px;
$msv-checkout-pick-up-at-store-margin-left: 128px;
$msv-checkout-pick-up-at-store-margin-top: 10px;
$msv-checkout-pick-up-at-store-margin-bottom: 20px;
$msv-checkout-email-delivery-margin-left: 128px;
$msv-checkout-email-delivery-margin-top: 10px;
$msv-checkout-email-delivery-margin-bottom: 20px;

// terms-and-conditions
$msv-checkout-terms-and-conditions-margin-bottom: 4rem;

// Mobile styles
$msv-mobile-checkout-min-width: 320px;
$msv-mobile-checkout-padding-zero: 0px;
$msv-mobile-checkout-margin: 0px;
$msv-mobile-checkout-max-button-width: 100%;
$msv-mobile-checkout-place-order-margin-left: 0px;
$msv-mobile-checkout-place-order-margin-top: 10px;
$msv-mobile-checkout-keep-shopping-margin-top: 20px;

// Alert
$msv-checkout-error-message-padding: 18px;
$msv-checkout-error-message-margin-bottom: 20px;
$msv-checkout-error-message-width: 100%;
$msv-checkout-icon-margin-right: 8px;

:root {
  --msv-checkout-shoppingbag-border: #{$msv-gray-300};

  // background
  --msv-checkout-side-bg: var(--msv-bg-color);
  --msv-checkout-side-multiple-pickup-bg: #{$msv-white};

  //heading
  --msv-checkout-address-heading-font-color: var(--msv-font-primary-color);
  --msv-checkout-address-heading-font-size: var(--msv-body-font-size-s);

  // link
  --msv-checkout-btn-link-color: var(--msv-font-primary-color);

  // error
  --msv-checkout-error-message-bg: var(--msv-error-color);
  --msv-checkout-error-message-border: var(--msv-error-color);
  --msv-checkout-error-message-color: var(--msv-font-secondary-color);

  // primary button
  --msv-checkout-primary-btn-bg: var(--msv-accent-brand-color);
  --msv-checkout-primary-btn-font-color: var(--msv-font-secondary-color);
  --msv-checkout-primary-btn-border: var(--msv-accent-brand-color);

  // secondary button
  --msv-checkout-secondary-btn-bg: var(--msv-secondary-button-background-color);
  --msv-checkout-secondary-btn-font-color: var(--msv-font-primary-color);
  --msv-checkout-secondary-btn-border: var(--msv-accent-brand-color);

  // Checkout line items.
  --msv-checkout-line-heading-font-color: var(--msv-font-primary-color);
  --msv-checkout-line-heading-font-size: #{$msv-heading-text-size};

  --msv-checkout-line-title-font-color: var(--msv-font-primary-color);
  --msv-checkout-line-title-font-size: var(--msv-body-font-size-l);

  --msv-checkout-line-text-font-color: var(--msv-font-primary-color);
  --msv-checkout-line-text-font-size: var(--msv-body-font-size-m);

  --msv-checkout-shopping-bag-line-heading-font-size: var(--msv-body-font-size-m);
  --msv-checkout-shopping-bag-line-subheading-font-size: var(--msv-body-font-size-s);

  --ecomm-checkout-background-color: var(--brandPrimaryColor);
  --ecomm-checkout-text-colour: var(--brandTextColour);

  --ecomm-checkout-main-padding: 2rem 1rem;

  --ecomm-checkout-title-colour: var(--brandPrimaryColor);
  --ecomm-checkout-title-margin: 0 0 1rem 0;
  --ecomm-checkout-title-weight: bold;

  --ecomm-checkout-qr-height: 100%;

  --ecomm-checkout-qr-header-colour: #{$msv-black};
  --ecomm-checkout-qr-header-margin: 0 0 1rem 0;
  --ecomm-checkout-qr-header-weight: bold;

  --ecomm-checkout-qr-warning-colour: #{$msv-black};
  --ecomm-checkout-qr-warning-margin: 0 0 1rem 0;

  --ecomm-checkout-qr-download-margin: 1rem 0 0 0;
  --ecomm-checkout-qr-download-width: 12rem;

  --ecomm-checkout-qr-return-wrapper-margin: 2rem 0 0 0;

  --ecomm-checkout-qr-return-bg: transparent;
  --ecomm-checkout-qr-return-border: none;
  --ecomm-checkout-qr-return-colour: #{$msv-black};
  --ecomm-checkout-qr-return-padding: 0;
  --ecomm-checkout-qr-return-weight: bold;

  --ecomm-checkout-qr-return-icon-margin: 0 .5rem 0 0;
  
  --ecomm-checkout-information-text-color: #{$msv-gray-600};

  --ecomm-checkout-form-colour: #{$msv-black};

  --ecomm-checkout-contact-margin: 0 0 3rem 0;
  --ecomm-checkout-contact-row-margin: 0 0 1rem 0;

  --ecomm-checkout-contact-item-padding: 0 1rem 0 0;

  --ecomm-checkout-contact-label-colour: #{$msv-gray-500};
  --ecomm-checkout-contact-label-colour--req: var(--brandPrimaryColor);
  --ecomm-checkout-contact-label-size: 16px;
  --ecomm-checkout-contact-label-margin: 0 0 .5rem 0;
  --ecomm-checkout-contact-label-padding: 0;

  --ecomm-checkout-contact-label-optional-size: 14px;
  --ecomm-checkout-contact-label-optional-color: var(--brandSecondaryColor);
  --ecomm-checkout-contact-label-optional-text-align: right;

  --ecomm-checkout-contact-control-wrapper-padding: 0;

  --ecomm-checkout-contact-control-border: 1px solid #{$msv-gray-300};
  --ecomm-checkout-contact-control-border-radius: 0;

  --ecomm-checkout-consent-colour: var(--brandPrimaryColor);
  --ecomm-checkout-consent-margin: 1rem 0 0 0;
  --ecomm-checkout-consent-size: 1rem;

  --ecomm-checkout-consent-input-padding: 0;

  --ecomm-checkout-consent-info-margin: 0;
  --ecomm-checkout-consent-info-padding: 0;

  --ecomm-checkout-consent-main-weight: bold;
  --ecomm-checkout-consent-subtext-size: 15px;

  --ecomm-checkout-consent-sub-colour: #{$msv-gray-500};

  --ecomm-checkout-payment-margin: 0 0 2rem 0;
  --ecomm-checkout-payment-size: 1rem;

  --ecomm-checkout-payment-header-colour: var(--brandPrimaryColor);

  --ecomm-checkout-payment-methods-grid-gap: 2rem;
  --ecomm-checkout-payment-methods-margin: 1rem 0;
  --ecomm-checkout-payment-methods-grid: 30% 30% 30%;

  --ecomm-checkout-payment-main-margin: 1rem 0;
  --ecomm-checkout-payment-main-weight: bold;

  --ecomm-checkout-payment-options-margin-bottom: 2rem;

  --ecomm-checkout-payment-options-text-margin: 0 0 1rem 0;

  --ecomm-checkout-payment-options-logo-height: 44px;
  --ecomm-checkout-payment-options-logo-margin-right: 1.5rem;

  --ecomm-checkout-action-button-margin: 0 1rem 0 0;
  --ecomm-checkout-action-button-padding: .5rem 1rem;
  --ecomm-checkout-action-button-size: 1rem;
  --ecomm-checkout-action-button-weight: bold;
  --ecomm-checkout-action-button-width: 12rem;

  --ecomm-checkout-action-back-bg: #{$msv-white};
  --ecomm-checkout-action-back-border: 1px solid #{$msv-gray-300};
  
  --ecomm-checkout-action-back-proceed-bg: var(--brandPrimaryColor);
  --ecomm-checkout-action-back-proceed-colour: var(--brandTextColour);

  --ecomm-checkout-side-padding: 2rem 3rem;

  --ecomm-checkout-side-header-margin: 0 0 1rem 0;

  --ecomm-checkout-item-group-height: 20rem;
  --ecomm-checkout-item-group-padding: 0;

  --ecomm-checkout-item-margin: 0 0 1rem 0;

  --ecomm-checkout-item-content-flex: 1;
  --ecomm-checkout-item-content-padding: 0 0 0 .5rem;
  --ecomm-checkout-item-name-size: 16px;
  --ecomm-checkout-item-image-border: 1px solid #{$msv-gray-300};

  --ecomm-checkout-side-price-size: 24px;

  --ecomm-checkout-summary-label-colour: var(--brandPrimaryColor);
  --ecomm-checkout-summary-label-size: 24px;

  --ecomm-checkout-side-divider-border: 1px solid var(--brandTextColour);
  --ecomm-checkout-side-divider-margin: 2rem 0;

  --ecomm-checkout-side-total-size: 1.5rem;

  // MOBILE STYLINGS.
  --ecomm-checkout-contact-item-margin-mobile: 0 0 1rem 0;
  --ecomm-checkout-contact-item-padding-mobile: 0;

  --ecomm-checkout-action-button-margin-mobile: 0 0 1rem 0;
  --ecomm-checkout-action-button-width-mobile: 100%;
}

.ms-checkout {
  &__main {
    padding: var(--ecomm-checkout-main-padding);

    .ecomm-qr {
      height: var(--ecomm-checkout-qr-height);

      > h4 {
        color: var(--ecomm-checkout-qr-header-colour);
        font-weight: var(--ecomm-checkout-qr-header-weight);
        margin: var(--ecomm-checkout-qr-header-margin);
      }

      > .warning {
        color: var(--ecomm-checkout-qr-warning-colour);
        margin: var(--ecomm-checkout-qr-warning-margin);
      }

      .download-wrapper { 
        margin: var(--ecomm-checkout-qr-download-margin);

        > button { @include ecomm-primary-button(true, 'BT', var(--ecomm-checkout-qr-download-width)); }
      }

      .return-wrapper {
        margin: var(--ecomm-checkout-qr-return-wrapper-margin);

        button { 
          @include add-icon($msv-BackArrow, before);

          background-color: var(--ecomm-checkout-qr-return-bg);
          border: var(--ecomm-checkout-qr-return-border);
          color: var(--ecomm-checkout-qr-return-colour);
          padding: var(--ecomm-checkout-qr-return-padding);
          font-weight: var(--ecomm-checkout-qr-return-weight);

          &::before { margin: var(--ecomm-checkout-qr-return-icon-margin); }
        }
      }
    }
  }

  &__form {
    color: var(--ecomm-checkout-form-colour);

    > .title { 
      color: var(--ecomm-checkout-title-colour);
      font-weight: var(--ecomm-checkout-title-weight); 
      margin: var(--ecomm-checkout-title-margin);
    }

    &-contact {
      margin: var(--ecomm-checkout-contact-margin);

      > .row { margin: var(--ecomm-checkout-contact-row-margin); }

      .form-details-item { padding: var(--ecomm-checkout-contact-item-padding); }
      
      .form-label {
        color: var(--ecomm-checkout-contact-label-colour);
        font-size: var(--ecomm-checkout-contact-label-size);
        margin: var(--ecomm-checkout-contact-label-margin);
        padding: var(--ecomm-checkout-contact-label-padding);

        &.required::after {
          color: var(--ecomm-checkout-contact-label-colour--req);
          content: '*';
        }
      }

      .form-field { padding: var(--ecomm-checkout-contact-control-wrapper-padding); }

      .form-control {
        border: var(--ecomm-checkout-contact-control-border);
        border-radius: var(--ecomm-checkout-contact-control-border-radius);
      }

      .form-consent {
        font-size: var(--ecomm-checkout-consent-size);
        margin: var(--ecomm-checkout-consent-margin);

        > .input-wrapper { padding: var(--ecomm-checkout-consent-input-padding); }

        > .info-wrapper { 
          margin: var(--ecomm-checkout-consent-info-margin);
          padding: var(--ecomm-checkout-consent-info-padding); 
        }
  
        &-main { 
          color: var(--ecomm-checkout-consent-colour);
          font-weight: var(--ecomm-checkout-consent-main-weight); 
        }

        &-subtext{
          font-size: var(--ecomm-checkout-consent-subtext-size);
        }
      }
    }

    &-payment {
      font-size: var(--ecomm-checkout-payment-size);
      margin: var(--ecomm-checkout-payment-margin);

      > h4 { color: var(--ecomm-checkout-payment-header-colour); }

      > .ecomm-payment-methods {
        @include breakpoint($msv-breakpoint-l) { grid-template-columns: auto auto; }

        display: grid;
        grid-gap: var(--ecomm-checkout-payment-methods-grid-gap);
        grid-template-columns: var(--ecomm-checkout-payment-methods-grid);
        margin: var(--ecomm-checkout-payment-methods-margin);
      }

      &-main {
        font-weight: var(--ecomm-checkout-payment-main-weight);
        margin: var(--ecomm-checkout-payment-main-margin);
      }

      &-options {
        margin-bottom: var(--ecomm-checkout-payment-options-margin-bottom);

        > p { margin: var(--ecomm-checkout-payment-options-text-margin); }

        &-logo {
          height: var(--ecomm-checkout-payment-options-logo-height);
          margin-right: var(--ecomm-checkout-payment-options-logo-margin-right);
          display: inline;

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }

    &-actions {
      > .back { @include ecomm-primary-button(false, 'BT', var(--ecomm-checkout-action-button-width)); }

      > .proceed { @include ecomm-primary-button(true, 'BT', var(--ecomm-checkout-action-button-width)); }

      > button {
        @include breakpoint($msv-breakpoint-l) { width: var(--ecomm-checkout-action-button-width-mobile); }

        &:not(:last-child) { 
          @include breakpoint($msv-breakpoint-l) { margin: var(--ecomm-checkout-action-button-margin-mobile); }

          margin: var(--ecomm-checkout-action-button-margin); 
        }
      }
    }
  }

  &__side {
    background-color: var(--ecomm-checkout-background-color);
    padding: var(--ecomm-checkout-side-padding);

    > h4 { margin: var(--ecomm-checkout-side-header-margin); }

    .ms-checkout__line-items {
      min-height: var(--ecomm-checkout-item-group-height);
      padding: var(--ecomm-checkout-item-group-padding);

      &-header { display: none; }

      &-group-title { display: none; }

      .ms-checkout__line-items-delivery-group {
        .ms-checkout__line-items-list {
          .ms-checkout__line-item {
            .msc-cart-line {
              display: flex;
              margin: var(--ecomm-checkout-item-margin);

              &__product-image {
                img { border: var(--ecomm-checkout-item-image-border); }
              }

              &__content {
                flex-grow: var(--ecomm-checkout-item-content-flex);
                padding: var(--ecomm-checkout-item-content-padding);

                .msc-cart-line__product {
                  &-title {
                    font-size: var(--ecomm-checkout-item-name-size);
                    color: var(--ecomm-checkout-text-colour);
                  }
                }

                .msc-cart-line__quantity { display: none; }

                .msc-cart-line__product-price {
                  text-align: end;
                  
                  .msc-price {
                    position: relative;

                    .msc-price__actual {
                      font-size: var(--ecomm-checkout-side-price-size);
                      color: var(--ecomm-checkout-text-colour);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .divider {
      border-top: var(--ecomm-checkout-side-divider-border);
      margin: var(--ecomm-checkout-side-divider-margin);
    }

    .msc-order-summary-wrapper {
      .msc-order-summary__items {
        .msc-order-summary__line-total {
          font-size: var(--ecomm-checkout-side-total-size);

          .msc-price {
            float: right;
            position: relative;
          }
        }
      }
    }
  }

  // MIGHT NOT NEED THE STYLINGS BELOW.
  &__error-message {
    width: $msv-checkout-error-message-width;
    background-color: var(--msv-checkout-error-message-bg);
    border: 1px solid var(--msv-checkout-error-message-border);
    color: var(--msv-checkout-error-message-color);
    padding: $msv-checkout-error-message-padding;
    margin-bottom: $msv-checkout-error-message-margin-bottom;

    &:before {
      @include msv-icon();
      content: $msv-IncidentTriangle;
      margin-right: $msv-checkout-icon-margin-right;
    }
  }

  &-section-container {
    padding-left: $msv-checkout-plain-container-padding-left;

    &__item.hidden {
      display: none;
    }
  }

  &__side-control-first {
    display: block;
    justify-content: center;
    margin-top: $msv-checkout-side-control-first-margin-top;
    padding: $msv-checkout-side-control-first-padding;

    .ms-checkout__btn-place-order {
      width: $msv-checkout-side-control-place-order-button-width;
      margin: $msv-checkout-side-control-first-margin-auto;
    }

    .ms-checkout__btn-keep-shopping {
      display: block;
      text-decoration: underline;
    }
  }

  &__btn-place-order {
    &.is-busy {
      &:before {
        @include msv-icon();
        content: $msv-Spinner;
        margin-right: $msv-checkout-icon-margin-right;
        -webkit-animation: spin 1s steps(8) infinite;
        animation: spin 1s steps(8) infinite;
      }

      cursor: progress;
    }

    @include primary-button(
      var(--msv-checkout-primary-btn-bg),
      var(--msv-checkout-primary-btn-font-color),
      var(--msv-checkout-primary-btn-border)
    );
    margin-left: $msv-checkout-place-order-button-margin;
  }

  &__btn-keep-shopping {
    @include button-link(var(--msv-checkout-btn-link-color));
  }

  &__guided-form {
    margin-bottom: $msv-checkout-guided-form-margin-bottom;
  }

  &-payment-instrument__error {
    background-color: var(--msv-checkout-error-message-bg);
    border: 1px solid var(--msv-checkout-error-message-border);
    color: var(--msv-checkout-error-message-color);
    max-width: $msv-checkout-payment-instrument-error-max-width;
    padding: $msv-checkout-payment-instrument-error-padding;

    &-title {
      margin-bottom: $msv-checkout-payment-instrument-error-title-margin-bottom;
      margin-top: $msv-checkout-payment-instrument-error-margin-top;

      &:before {
        @include msv-icon();
        content: $msv-IncidentTriangle;
        margin-right: $msv-checkout-icon-margin-right;
      }

      display: block;
    }

    &-message {
      display: block;
      margin-bottom: $msv-checkout-payment-instrument-message-margin-buttom;
      color: var(--msv-font-secondary-color);
    }
  }

  &__guided-card {
    padding-bottom: $msv-checkout-guided-card-padding-bottom;
    padding-top: $msv-checkout-guided-card-padding-top;
    color: $msv-gray-500;

    &-header {
      @include display-flex;
    }

    &.hidden {
      display: none;
    }

    &.visted, &.active {
      color: var(--msv-checkout-address-heading-font-color);
      text-decoration: none;
    }

    &-title {
      @include display-flex;
      @include font-content-heading($msv-checkout-guided-card-title-font-weight);

      color: var(--ecomm-checkout-summary-label-colour);
      font-size: var(--ecomm-checkout-summary-label-size);
      line-height: var(--ecomm-checkout-summary-label-size);
    }

    &-title-step { width: $msv-checkout-guided-card-title-step-width; }

    &-body {
      margin-top: $msv-checkout-guided-card-body-margin-top;

      &.hidden { display: none; }
    }

    &-content {
      .ms-checkout-shipping-address {
        &__group-images { display: none; }

        .msc-address-form {
          color: #333333;
          padding-top: 1rem;
          
          #shipping_addressbuildingcompliment_container {
            display: none;
          }

          #shipping_addressstreetnumber_container {
            display: none;
          }

          #shipping_addresspostbox_container {
            display: none;
          }

          .msc-address-form__item {
            padding-bottom: 1rem;

            .msc-address-form__label {
              font-size: 16px;
              display: inherit;
            }

            .msc-address-form__input {
              height: 40px;
              border: 1px solid #D3D3EB;
            }
          }
        }
      }
    }

    &-btn-cancel {
      @include secondary-button(
        var(--msv-checkout-secondary-btn-bg),
        var(--msv-checkout-secondary-btn-font-color),
        var(--msv-checkout-secondary-btn-border)
      );
    }

    &-btn-save {
      @include primary-button(
        var(--msv-checkout-primary-btn-bg),
        var(--msv-checkout-primary-btn-font-color),
        var(--msv-checkout-primary-btn-border)
      );
    }

    &-btn-cancel,
    &-btn-save {
      margin-right: $msv-checkout-save-button-margin-left;

      &.is-submitting {
        &:before {
          @include msv-icon();
          content: $msv-Spinner;
          margin-right: $msv-checkout-icon-margin-right;
          -webkit-animation: spin 1s steps(8) infinite;
          animation: spin 1s steps(8) infinite;
        }

        cursor: progress;
      }
    }

    &-btn-edit {
      @include font-content-m-underline(var(--msv-font-weight-heavy));
      @include button-link(var(--msv-checkout-btn-link-color));
      padding: $msv-checkout-edit-btn-padding;
      margin-left: $msv-checkout-edit-btn-margin-left;
    }

    &-footer {
      margin-top: $msv-checkout-guided-card-footer-margin-top;
      @include display-flex;
    }
  }

  &__side-control {
    &-first,
    &-second {
      margin-bottom: $msv-checkout-side-control-margin-bottom;
    }
  }

  &__line-items {
    background-color: transparent;
    line-height: $msv-line-height-s;
    padding: $msv-checkout-line-items-padding;

    &-edit-cart-link {
      @include font-content-m-underline(var(--msv-font-weight-heavy));
      @include button-link(var(--msv-checkout-btn-link-color));
      padding: $msv-checkout-edit-btn-padding;
      margin-left: auto;
    }

    &-header {
      margin-bottom: $msv-checkout-line-header-margin-bottom;

      .ms-checkout__line-items-heading {
        color: var(--msv-checkout-line-heading-font-color);
        display: inline;
        font-size: var(--msv-checkout-line-heading-font-size);
        font-weight: var(--msv-font-weight-normal);
        line-height: $msv-heading-line-height;
      }

      .ms-checkout__line-items-edit-cart-link {
        color: var(--msv-checkout-btn-link-color);
        font-weight: $msv-checkout-line-edit-cart-font-weight;
        float: right;
        font-size: var(--msv-checkout-line-heading-font-size);
      }
    }

    &-delivery-group {
      .ms-checkout__line-items-group-title {
        font-weight: $msv-checkout-line-heading-font-weight;
        color: var(--msv-checkout-line-text-font-color);
      }

      .ms-checkout__line-items-group-title-multiple-pickup-pickupicon {
        @include add-icon($msv-cube, before);
        margin-left: 20px ;
        margin-right: 20px;
        margin-top: 10px;
        padding-top: 10px;
        position: absolute;
        display: none;
      }

      .ms-checkout__line-items-group-title-multiple-pickup-shipicon {
        @include add-icon($msv-DeliveryTruck, before);
        margin-left: 20px ;
        margin-right: 20px;
        margin-top: 10px;
        padding-top: 10px;
        position: absolute;
        display: none;
      }

      .ms-checkout__line-items-group-title-multiple-pickup-emailicon {
        @include add-icon($msv-DeliveryTruck, before);
        margin-left: 20px ;
        margin-right: 20px;
        margin-top: 10px;
        padding-top: 10px;
        position: absolute;
      }

      .ms-checkout__line-items-group-title-multiple-pickup-heading {
        @include font-content(var(--msv-font-weight-bold), var(--msv-checkout-shopping-bag-line-heading-font-size),$msv-line-height-m);
        color: var(--msv-checkout-line-text-font-color);
        margin-left: 50px;
        padding-top: 10px;
        display: none;
      }

      .ms-checkout__line-items-group-title-multiple-pickup-subheading {
        @include font-content(var(--msv-font-weight-light), var(--msv-checkout-shopping-bag-line-subheading-font-size),$msv-line-height-s);
        color: var(--msv-checkout-line-text-font-color);
        margin-left: 50px;
        display: none;
      }

      .ms-checkout__pick-up-at-store {
        margin-left: $msv-checkout-pick-up-at-store-margin-left;
        margin-top: $msv-checkout-pick-up-at-store-margin-top;
        margin-bottom: $msv-checkout-pick-up-at-store-margin-bottom;

        .ms-checkout__store-location {
          font-weight: var(--msv-font-weight-bold);
        }
      }

      .ms-checkout__email-delivery {
        margin-left: $msv-checkout-email-delivery-margin-left;
        margin-top: $msv-checkout-email-delivery-margin-top;
        margin-bottom: $msv-checkout-email-delivery-margin-bottom;

        .ms-checkout__store-location {
          font-weight: var(--msv-font-weight-bold);
        }
      }

      .msc-cart-line {
        &__product-image {
          position: relative;
          width: $msv-checkout-line-image-size;
          height: $msv-checkout-line-image-size;
          margin-right: unset;
        }

        &__quantity,
        &__product-variants {
          font-weight: var(--msv-font-weight-normal);
          color: var(--msv-checkout-line-text-font-color);
          font-size: var(--msv-checkout-line-text-font-size);
          line-height: $msv-line-height-m;
        }

        // &__content {
        //   display: block;
        //   margin-left: $msv-checkout-line-margin-left;
        //   width: $msv-checkout-line-content-width;

        //   .msc-cart-line__product-title {
        //     color: var(--msv-checkout-line-title-font-color);
        //     font-weight: var(--msv-font-weight-bold);
        //     font-size: var(--msv-checkout-line-title-font-size);
        //     line-height: $msv-line-height-l;
        //   }

        //   .msc-cart-line-item-product-discount {
        //     color: var(--msv-success-color);
        //   }

        //   .msc-cart-line__quantity {
        //     margin-left: $msv-checkout-cart-line-quantity-margin-left;

        //     .quantity-label {

        //       line-height: $msv-text-line-height;
        //       margin-right: $msv-checkout-cart-line-quantity-label-margin-right;
        //     }

        //     .quantity-value {
        //       display: inline;
        //       text-align: left;
        //     }
        //   }

        //   .msc-cart-line__product-price {
        //     margin-left: $msv-checkout-cart-line-quantity-margin-left;
        //     text-align: left;
        //     display: block;
        //     font-size: var(--msv-checkout-line-text-font-size);

        //     .msc-price__strikethrough {
        //       color: var(--msv-checkout-line-text-font-color);
        //       line-height: $msv-text-line-height;
        //       display: inline;
        //       margin-right: $msv-checkout-cart-line-price-strikethrough-margin-right;
        //     }

        //     .msc-price__actual {
        //       color: var(--msv-checkout-line-text-font-color);
        //       font-size: var(--msv-checkout-line-text-font-size);
        //       line-height: $msv-text-line-height;
        //     }
        //   }

        //   .msc-cart-line__product-savings {
        //     display: none;
        //     margin-left: 0px;
        //     text-align: left;
        //   }
        // }
      }
    }
  }

  .msc-empty_image { @include image-placeholder($msv-checkout-empty-image-width); }

  .multiple-pickup {
    border-top: 0;
    margin-top: 10px;
    position: relative;
  }

  .multiple-pickup-enabled { background-color: transparent; }

  @media screen and (max-width: $msv-breakpoint-m) {
    &__main {
      min-width: $msv-mobile-checkout-min-width;
      padding-right: $msv-mobile-checkout-padding-zero;
    }

    &__guided-card {
      &-body {
        padding-left: $msv-mobile-checkout-padding-zero;
        padding-right: $msv-mobile-checkout-padding-zero;
      }

      &-btn-save {
        width: $msv-mobile-checkout-max-button-width;
      }

      &-btn-cancel {
        width: $msv-mobile-checkout-max-button-width;
      }
    }

    &__body { width: $msv-mobile-checkout-max-button-width; }

    margin-right: $msv-mobile-checkout-margin;
    margin-left: $msv-mobile-checkout-margin;

    &__side-control-second {
      background: var(--msv-checkout-side-bg);
      display: block;

      .ms-checkout__btn-keep-shopping,
      .ms-checkout__btn-place-order {
        width: $msv-mobile-checkout-max-button-width;
      }

      .ms-checkout__btn-place-order {
        margin-left: $msv-mobile-checkout-place-order-margin-left;
        margin-top: $msv-mobile-checkout-place-order-margin-top;
      }

      .ms-checkout__btn-keep-shopping {
        margin-top: $msv-mobile-checkout-keep-shopping-margin-top;
      }
    }
  }
}
