:root {
  --sutra-bg: #{$msv-white};
  --sutra-bg-active: var(--brandPrimaryColor);
  --sutra-bg-hover: #{$pink-lace};
  --sutra-padding: 1rem;

  --sutra-time-colour: #{$msv-gray-800};
  --sutra-time-colour-active: #{$msv-white};

  --sutra-title-colour: var(--brandPrimaryColor);
  --sutra-title-colour-active: #{$msv-white};
  --sutra-title-padding: 0;
  --sutra-title-weight: #{$msv-font-weight-bold};

  --sutra-action-bg: transparent;
  --sutra-action-bg-active: transparent;
  --sutra-action-bg-done: #{$msv-gray-200};
  --sutra-action-bg-hover: var(--brandPrimaryColor);
  --sutra-action-border: 1px solid #{$msv-gray-200};
  --sutra-action-button-padding: .5rem 2rem;
  --sutra-action-colour: var(--brandPrimaryColor);
  --sutra-action-colour-active: var(--brandTextColour);
  --sutra-action-colour-done: #{$msv-white};
  --sutra-action-colour-hover: #{$msv-white};
  --sutra-action-height: 3rem;
  --sutra-action-padding: 0;

  // MOBILE STYLINGS
  --sutra-bg-mobile: #{$msv-white};
}

.ecomm-event {  
  background-color: var(--sutra-bg);
  padding: var(--sutra-padding);

  &.mobile { background-color: var(--sutra-bg-mobile); }

  &.active {
    background-color: var(--sutra-bg-active);

    .ecomm-event-text {
      .time { color: var(--sutra-time-colour-active); }
      .title { color: var(--sutra-title-colour-active); }
    }

    .ecomm-event-action button {
      background-color: var(--sutra-action-bg-active);
      color: var(--sutra-action-colour-active);
    }
  }

  &-text {
    padding: var(--sutra-title-padding);

    .time { color: var(--sutra-time-colour); }

    .title { 
      color: var(--sutra-title-colour); 
      font-weight: var(--sutra-title-weight);
    }
  }

  &-action {
    @include display-flex;
    @include justify-content(center);

    padding: var(--sutra-action-padding);

    button {
      background-color: var(--sutra-action-bg);
      border: var(--sutra-action-border);
      color: var(--sutra-action-colour);
      max-height: var(--sutra-action-height);
      padding: var(--sutra-action-button-padding);
    }
  }

  &:hover { 
    background-color: var(--sutra-bg-hover); 

    .ecomm-event-text {
      .time { color: var(--sutra-time-colour); }
      .title { color: var(--sutra-title-colour);  }
    }
    
    .ecomm-event-action button {
      background-color: var(--sutra-action-bg-hover);
      color: var(--sutra-action-colour-hover);
    }
  }
}