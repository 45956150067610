:root {
  --template-column-padding-left: 12px;
  --template-column-padding-right: 12px;

  --template-font-colour: #{$msv-black};

  --template-img-border: none;
  --template-img-width: 100%;

  --template-img-container-margin: 0 0 1rem 0;

  --template-title-font-colour: var(--brandPrimaryColor);
  --template-title-font-weight: #{$msv-font-weight-bold};
  --template-title-margin: 0 0 1rem 0;

  --template-actions-margin: 1rem 0 0 0;

  --template-link-padding: .5rem 2rem;

  --template-link-first-bg: var(--brandPrimaryColor);
  --template-link-first-colour: var(--brandTextColour);
  --template-link-first-margin: 0 .5rem 0 0;

  --template-link-second-bg: #{$msv-white};
  --template-link-second-border: 1px solid #{$msv-gray-300};
  --template-link-second-colour: var(--brandPrimaryColor);

  // MOBILE STYLINGS
  --template-column-first-margin-mobile: 0 0 1rem 0;

  --template-column-padding-mobile: 0;

  --template-img-margin-mobile: 0 0 1rem 0;

  --template-title-margin-mobile: 0 0 1rem 0;
  --template-title-margin-mobile-4: 0 0 1rem 0;
}

.ecomm-content-template {
  &-1 {
    color: var(--template-font-colour);

    > div:first-child { 
      @include breakpoint($msv-breakpoint-l) { 
        margin: var(--template-column-first-margin-mobile);
        padding: var(--template-column-padding-mobile); 
      }

      padding-right: var(--template-column-padding-right); 
    }

    > div:last-child { 
      @include breakpoint($msv-breakpoint-l) { padding: var(--template-column-padding-mobile); }

      padding-left: var(--template-column-padding-left); 
    }

    .img-container {
      @include display-flex;
      @include justify-content(center);

      img { 
        border: var(--template-img-border); 
        object-fit: contain;
      }
    }

    .info-container {
      @include breakpoint($msv-breakpoint-l) { padding: var(--template-container-padding-mobile); }

      h6 {
        @include breakpoint($msv-breakpoint-l) { margin: var(--template-title-margin-mobile); }

        font-weight: var(--template-title-font-weight);
        margin: var(--template-title-margin);
      }

      h6.enable-primary-colour { color: var(--template-title-font-colour); }
    }
  }

  &-2 {
    color: var(--template-font-colour);

    .img-container {
      margin: var(--template-img-container-margin);

      div {
        @include display-flex;
        @include justify-content(center);

        img { 
          border: var(--template-img-border); 
          object-fit: contain;
        }
      }

      .first-container { 
        @include breakpoint($msv-breakpoint-l) { 
          margin: var(--template-img-margin-mobile);
          padding: var(--template-column-padding-mobile); 
        }

        padding-right: var(--template-column-padding-right); 
      }

      .second-container { 
        @include breakpoint($msv-breakpoint-l) { padding: var(--template-column-padding-mobile); }

        padding-left: var(--template-column-padding-left); 
      }
    }
  }

  &-3 {
    .img-container {
      > div:first-child { 
        @include display-flex;
        margin: var(--template-img-container-margin); 
        @include justify-content(center);

        img { 
          border: var(--template-img-border);
          object-fit: contain; 
        }
      }

      div.row {
        div { 
          @include display-flex;
          @include justify-content(center);

          img { 
            border: var(--template-img-border);
            object-fit: contain;
          }
        }

        > div:first-child { 
          @include breakpoint($msv-breakpoint-l) { 
            margin: var(--template-img-margin-mobile);
            padding: var(--template-column-padding-mobile); 
          }

          padding-right: var(--template-column-padding-right); 
        }

        > div:last-child { 
          @include breakpoint($msv-breakpoint-l) { padding: var(--template-column-padding-mobile); }

          padding-left: var(--template-column-padding-left); 
        }
      }
    }
  }

  &-4 {
    color: var(--template-font-colour);
    
    .info-container {
      > p:first-child {
        @include breakpoint($msv-breakpoint-l) { margin: var(--template-title-margin-mobile-4); }

        font-weight: var(--template-title-font-weight);
        margin: var(--template-title-margin);

        &.enable-primary-colour { color: var(--template-title-font-colour); }
      }
    }
  }

  &-5 {
    color: var(--template-font-colour);

    > div:first-child {
      @include breakpoint($msv-breakpoint-l) { 
        margin: var(--template-column-first-margin-mobile);
        padding: var(--template-column-padding-mobile); 
      }

      padding-right: var(--template-column-padding-right); 
    }

    > div:last-child {
      @include breakpoint($msv-breakpoint-l) { padding: var(--template-column-padding-mobile); }

      padding-left: var(--template-column-padding-left); 
    }

    .img-container {
      @include display-flex;
      @include justify-content(center);

      img { 
        border: var(--template-img-border);
        width: var(--template-img-width); 
      }
    }

    .info-container {
      @include breakpoint($msv-breakpoint-l) { padding: var(--template-container-padding-mobile); }

      h6 {
        @include breakpoint($msv-breakpoint-l) { margin: var(--template-title-margin-mobile); }

        font-weight: var(--template-title-font-weight);
        margin: var(--template-title-margin);
      }

      h6.enable-primary-colour { color: var(--template-title-font-colour); }

      .actions-container {
        margin: var(--template-actions-margin);

        > a {
          display: inline-block;
          padding: var(--template-link-padding);

          &:hover { text-decoration: none; }
        }

        > a.under-construction {
          @include add-icon($msv-BackArrow, before);

          &:before{
            padding-right: 5px;
          }
        }

        > a:first-child { 
          color: var(--template-link-first-colour);
          background-color: var(--template-link-first-bg);
          margin: var(--template-link-first-margin); 

          &:hover { color: var(--template-link-first-colour); }
        }

        > a.alternate:first-child {
          background-color: var(--template-link-second-bg);
          border: var(--template-link-second-border);
          color: var(--template-link-second-colour);
          margin: var(--template-link-first-margin); 

          &:hover { color: var(--template-link-second-colour); }
        }

        > a:nth-child(2) {
          background-color: var(--template-link-second-bg);
          border: var(--template-link-second-border);
          color: var(--template-link-second-colour);

          &:hover { color: var(--template-link-second-colour); }
        }

        > a.alternate:nth-child(2) {
          background-color: var(--template-link-first-bg);
          color: var(--template-link-first-colour);

          &:hover { color: var(--template-link-first-colour); }
        }
      }
    }
  }
}