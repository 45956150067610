:root {
  --carousel-arrow-border: 0;
  --carousel-arrow-border-radius: 35px;
  --carousel-arrow-colour: var(--brandPrimaryColor);
  --carousel-arrow-font-size: #{$msv-font-size-l};
  --carousel-arrow-height: 43px;
  --carousel-arrow-outline: 0;
  --carousel-arrow-position-left: 0;
  --carousel-arrow-position-right: 0;
  --carousel-arrow-width: 43px;
  --carousel-arrow-transform-x-left: translate(-15px);
  --carousel-arrow-transform-x-right: translate(15px);
  --carousel-arrow-z-index: 1000;
  --carousel-arrow-z-index-before: 2;

  --slider-container-padding: 2rem 1rem;

  --carousel-list-margin: 0;
  --carousel-list-padding: 0;

  --slider-top-container-margin: 0 0 1rem 0;
  --slider-top-container-padding: 0 2rem;
  --slider-top-container-title-font-size: 1.5rem;
  --slider-top-container-title-font-weight: #{$msv-font-weight-bold};

  --slider-top-title-font-colour: var(--brandPrimaryColor);

  --slider-top-nav-button-bg: #{$msv-white};
  --slider-top-nav-button-bg-hover: #{$msv-gray-300};
  --slider-top-nav-button-border: 1px solid #{$msv-gray-300};
  --slider-top-nav-button-colour: var(--brandPrimaryColor);
  --slider-top-nav-button-font-size: .8rem;
  --slider-top-nav-button-padding: .2rem 2rem;
  --slider-top-nav-button-font-weight: #{$msv-font-weight-heavy};

  // MOBILE STYLINGS
  --slider-container-padding-mobile: 1rem;

  --slider-top-container-padding-mobile: 0 1rem;
  --slider-top-container-title-font-size-mobile: 1rem;

  --slider-top-nav-button-bg-mobile: transparent;
  --slider-top-nav-button-border-mobile: none;
  --slider-top-nav-button-padding-mobile: 0;
}

.ecomm-product-slider {
  @include breakpoint($msv-breakpoint-l) { padding: var(--slider-container-padding-mobile); }

  background-repeat: no-repeat;
  background-size: cover;
  padding: var(--slider-container-padding);

  &-top {
    @include breakpoint($msv-breakpoint-l) { padding: var(--slider-top-container-padding-mobile); }

    color: var(--slider-top-title-font-colour);
    margin: var(--slider-top-container-margin);
    padding: var(--slider-top-container-padding);
  }

  &-title { 
    @include breakpoint($msv-breakpoint-l) {
      font-size: var(--slider-top-container-title-font-size-mobile);
      padding: var(--slider-top-container-padding-mobile);
    }

    font-size: var(--slider-top-container-title-font-size);
    font-weight: var(--slider-top-container-title-font-weight); 
  }

  &-nav {
    @include breakpoint($msv-breakpoint-l) {
      @include align-items(center);
      padding: var(--slider-top-container-padding-mobile);
    }

    @include display-flex;
    @include justify-content(end);

    > a {
      @include breakpoint($msv-breakpoint-l) {
        background-color: var(--slider-top-nav-button-bg-mobile);
        border: var(--slider-top-nav-button-border-mobile);
        padding: var(--slider-top-nav-button-padding-mobile);

        &:hover { background-color: var(--slider-top-nav-button-bg-mobile); }
      }

      background-color: var(--slider-top-nav-button-bg);
      border: var(--slider-top-nav-button-border);
      color: var(--slider-top-nav-button-colour);
      font-size: var(--slider-top-nav-button-font-size);
      font-weight: var(--slider-top-nav-button-font-weight);
      padding: var(--slider-top-nav-button-padding);

      &:hover { 
        background-color: var(--slider-top-nav-button-bg-hover);
        text-decoration: none; 
      }
    }
  }
}

.react-multi-carousel {
  &-list {
    @include display-flex;
    @include align-items(center);

    overflow: hidden;
    position: relative;
  }

  &-track {
    @include display-flex;
    
    backface-visibility: hidden;
    flex-direction: row;
    margin: var(--carousel-list-margin);
    padding: var(--carousel-list-padding);
    position: relative;
    transform-style: preserve-3d;
    will-change: transform, transition;
  }

  &-item {
    @include display-flex;
    @include justify-content(center);
  
    backface-visibility: hidden;
    transform-style: preserve-3d;
  }
}

.react-multiple-carousel__arrow {
  background-color: transparent;
  border: var(--carousel-arrow-border);
  border-radius: var(--carousel-arrow-border-radius);
  color: var(--carousel-arrow-colour);
  min-height: var(--carousel-arrow-height);
  min-width: var(--carousel-arrow-width);
  outline: var(--carousel-arrow-outline);
  position: absolute;
  transition: all .5s;
  z-index: var(--carousel-arrow-z-index);

  &::before {
    position: relative;
    text-align: center;
    z-index: var(--carousel-arrow-z-index-before);
  }

  &--left {
    @include add-icon($msv-ChevronLeft, before, block, var(--carousel-arrow-font-size));   

    left: var(--carousel-arrow-position-left);
    transform: var(--carousel-arrow-transform-x-left);
  }

  &--right {
    @include add-icon($msv-ChevronRight, before, block, var(--carousel-arrow-font-size));  

    right: var(--carousel-arrow-position-right);
    transform: var(--carousel-arrow-transform-x-right);
  }
}