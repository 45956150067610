:root {
  --ecomm-pagination-active-bg: var(--brandSecondaryColor);
  --ecomm-pagination-active-font-colour: var(--brandTextColour);
  --ecomm-pagination-border: 1px solid $msv-gray-800;
  --ecomm-pagination-font-colour: var(--brandSecondaryColor);

  --ecomm-pagination-link-margin: 0 5px;
  --ecomm-pagination-link-width: 30px;
}

.ecomm-pagination {
  @include breakpoint($msv-breakpoint-l) { @include justify-content(center); }

  @include display-flex;
  @include justify-content(end);

  li {
    border: var(--ecomm-pagination-border);
    color:var(--ecomm-pagination-font-colour);
    margin: var(--ecomm-pagination-link-margin);
    text-align: center;
    width: var(--ecomm-pagination-link-width);

    &.active {
      background-color: var(--ecomm-pagination-active-bg);
      color: var(--ecomm-pagination-active-font-colour);
      text-decoration: none;
    }
  }
}