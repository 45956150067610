:root {
    --header-color: #901C32;
    --header-font-size: 24px;
    --header-font-weight: bold;
    --header-padding-left: .5rem;
    --body-color: #333333;
    --body-padding: .5rem;
    --body-highlight-color: #ffc000;
    --button-font-size: 16px;
    --button-font-weight: bold;
    --button-border: 1px solid #C7C7CC;
    --button-padding: .75rem 1rem;
    --button-text-decoration: none;
    --stay-button-color: #901C32;
    --go-button-color: var(--brandTextColour);
    --go-portal-button-color: #6CD20A;
    --go-button-background-color: #901C32;
}

.accordion {
    list-style: none;
    margin: 0 -1000px;
}

.container {
    padding: 0;
}