:root {
  --ecomm-side-bg: var(--brandPrimaryColor);
  --ecomm-side-height: 100vh;

  --ecomm-top-padding: 2rem 1rem;

  --ecomm-top-title-padding: 0;
  --ecomm-top-title-size: 1.5rem;
  --ecomm-top-title-weight: bold;

  --ecomm-top-count-padding: 0;

  --ecomm-filter-padding: 2rem;

  --ecomm-filter-button-active-colour: var(--brandTextColour);

  --ecomm-filter-button-bg: transparent;
  --ecomm-filter-button-border: none;
  --ecomm-filter-button-colour: #{$msv-white};
  --ecomm-filter-button-margin: 0 0 2rem 0;
  --ecomm-filter-button-padding: 0;
  --ecomm-filter-button-weight: bold;
  --ecomm-filter-button-width: 100%;
}

.ecomm-side-filter {
  background-color: var(--ecomm-side-bg);
  min-height: var(--ecomm-side-height);

  > .top { 
    border-left: transparent;
    border-right: transparent;
    border-style: dashed;
    border-top: transparent;
    padding: var(--ecomm-top-padding);

    > .title {
      font-size: var(--ecomm-top-title-size);
      font-weight: var(--ecomm-top-title-weight);
      padding: var(--ecomm-top-title-padding);
    }
    
    > .count { 
      padding: var(--ecomm-top-count-padding);
      text-align: end; 
    }
  }

  > .filter {
    padding: var(--ecomm-filter-padding);

    > button {
      background-color: var(--ecomm-filter-button-bg);
      border: var(--ecomm-filter-button-border);
      color: var(--ecomm-filter-button-colour);
      font-weight: var(--ecomm-filter-button-weight);
      padding: var(--ecomm-filter-button-padding);
      text-align: start;
      width: var(--ecomm-filter-button-width);
      
      &:not(:last-child) { margin: var(--ecomm-filter-button-margin); }

      &.active { color: var(--ecomm-filter-button-active-colour); }
    }
  }
}