:root {
  --ecomm-order-summary-padding: 2rem 1rem;
  --ecomm-order-summary-background-color: var(--brandPrimaryColor);
  --ecomm-order-summary-header-size: 1.5rem;
  --ecomm-order-summary-header-weight: 500;
  --ecomm-order-summary-header-color: var(--brandTextColour);
  --ecomm-order-summary-header-padding-bottom: 2rem;

  --ecomm-order-summary-item-margin: 0 0 1rem 0;

  --ecomm-order-summary-item-image-height: 108px;
  --ecomm-order-summary-item-image-border: 1px solid #{$msv-gray-300};

  --ecomm-order-summary-item-content-color: var(--brandTextColour);
  --ecomm-order-summary-item-content-padding: 0 0 0 .5rem;

  --ecomm-order-summary-item-content-padding--col: 0;

  --ecomm-order-summary-item-content-name-padding: 0;
  --ecomm-order-summary-item-content-name-size: 1rem;
  --ecomm-order-summary-item-content-name-weight: bold;

  --ecomm-order-summary-item-content-price-size: 1.5rem;

  --ecomm-order-summary-divider-border: 1px solid var(--brandTextColour);
  --ecomm-order-summary-divider-margin: 3rem 0 1rem 0;
  
  --ecomm-order-total-font-size: 1.5rem;
  --ecomm-order-total-font-weight: 500;
  --ecomm-order-total-color: var(--brandTextColour);
  --ecomm-order-total-padding-bottom: 2rem;
}

.ecomm-order-confirm-summary {
  > .summary {
    background-color: var(--ecomm-order-summary-background-color);
    padding: var(--ecomm-order-summary-padding);

    p.header {
      color: var(--ecomm-order-summary-header-color);
      font-size: var(--ecomm-order-summary-header-size);
      font-weight: var(--ecomm-order-summary-header-weight);
      padding-bottom: var(--ecomm-order-summary-header-padding-bottom);
    }

    > .item {
      margin: var(--ecomm-order-summary-item-margin);

      img {
        border: var(--ecomm-order-summary-item-image-border);
        display: inline-block;
        height: var(--ecomm-order-summary-item-image-height);
      }

    > .item-content {
        color: var(--ecomm-order-summary-item-content-color);
        padding: var(--ecomm-order-summary-item-content-padding);

        > .item-name {
          font-size: var(--ecomm-order-summary-item-content-name-size);
          font-weight: var(--ecomm-order-summary-item-content-name-weight);
        }

        > .item-price {
          font-size: var(--ecomm-order-summary-item-content-price-size);
          text-align: end;
        }

        > .item-name, .item-price { padding: var(--ecomm-order-summary-item-content-padding--col); }
      }
    }

    hr.line-end {
      border-top: var(--ecomm-order-summary-divider-border);
      margin: var(--ecomm-order-summary-divider-margin);
    }

    > .total {
      color: var(--ecomm-order-total-color);
      display: block;
      font-size: var(--ecomm-order-total-font-size);
      font-weight: var(--ecomm-order-total-font-weight);
      padding-bottom: var(--ecomm-order-total-padding-bottom);

      .total-text { float: left; }

      .total-amount { float: right; }
    }
  }
}