:root {
  --staff-card-english-font-colour: var(--brandPrimaryColor);

  --staff-card-font-colour: #{$msv_black};
  --staff-card-padding-mobile: 0 .5rem;

  --staff-card-img-container-margin-bottom: 10px;

  --staff-card-img-dimension: 250px;
  --staff-card-img-dimension-mobile: initial;

  --staff-card-info-font-size: .75rem;
  --staff-card-info-font-weight: #{$msv-font-weight-bold};
  --staff-card-info-margin-top: 10px;
}

.ecomm-staff-card {
  @include breakpoint($msv-breakpoint-l) { padding: var(--staff-card-padding-mobile); }

  color: var(--staff-card-font-colour);
  
  .img-container {
    @include display-flex;
    @include justify-content(center);
  
    background-position: center center;
    background-repeat: no-repeat;
    margin-bottom: var(--staff-card-img-container-margin-bottom);

    img {
      @include breakpoint($msv-breakpoint-l) {
        height: var(--staff-card-img-dimension-mobile);
        width: var(--staff-card-img-dimension-mobile);
      }

      height: var(--staff-card-img-dimension);
      object-position: center;
      width: var(--staff-card-img-dimension);
    }
  }

  .info-container {
    text-align: center;

    h6 { color: var(--staff-card-english-font-colour); }

    h6, p { font-weight: var(--staff-card-info-font-weight); }

    p { 
      font-size: var(--staff-card-info-font-size); 
      margin-top: var(--staff-card-info-margin-top);
    }
  }
}