:root {
    --ecomm-unsuccessful-content-padding-left: 0;
    --ecomm-unsuccessful-content-padding-top: 5rem;
    --ecomm-unsuccessful-content-padding-bottom: 2rem;

    --ecomm-unsuccessful-header-font-size: 40px;
    --ecomm-unsuccessful-header-font-weight: 500;
    --ecomm-unsuccessful-header-color: #333333;
    --ecomm-unsuccessful-header-padding-bottom: 2rem;

    --ecomm-unsuccessful-message-font-size: 18px;
    --ecomm-unsuccessful-message-color: #333333;
    --ecomm-unsuccessful-message-padding-bottom: 2rem;

    --ecomm-unsuccessful-button-background-color: transparent;
    --ecomm-unsuccessful-button-color: #901C32;
    --ecomm-unsuccessful-button-font-size: 16px;
    --ecomm-unsuccessful-button-font-weight: bold;
    --ecomm-unsuccessful-button-border: 1px solid #C7C7CC;
    --ecomm-unsuccessful-button-padding: 1rem 2rem;

    --ecomm-unsuccessful-button-download-margin-right: 2rem;
    --ecomm-unsuccessful-button-download-margin-bottom: 1rem;
    --ecomm-unsuccessful-button-download-icon-margin-right: 5px;
}

.ecomm-transaction-unsucessful {
    > .content {
        padding-left: var(--ecomm-unsuccessful-content-padding-left);
        padding-top: var(--ecomm-unsuccessful-content-padding-top);
        padding-bottom: var(--ecomm-unsuccessful-content-padding-bottom);

        p.header {
            font-size: var(--ecomm-unsuccessful-header-font-size);
            font-weight: var(--ecomm-unsuccessful-header-font-weight);
            color: var(--ecomm-unsuccessful-header-color);
            padding-bottom: var(--ecomm-unsuccessful-header-padding-bottom);
        }

        p.message {
            font-size: var(--ecomm-unsuccessful-message-font-size);
            color: var(--ecomm-unsuccessful-message-color);
            padding-bottom: var(--ecomm-unsuccessful-message-padding-bottom);
        }

        button {
            background-color: var(--ecomm-unsuccessful-button-background-color);
            color: var(--ecomm-unsuccessful-button-color);
            font-size: var(--ecomm-unsuccessful-button-font-size);
            font-weight: var(--ecomm-unsuccessful-button-font-weight);
            border: var(--ecomm-unsuccessful-button-border);
            padding: var(--ecomm-unsuccessful-button-padding);
        }

        .retry {
            margin-bottom: var(--ecomm-unsuccessful-button-download-margin-bottom);
            @include add-icon($msv-sync, before, inline);

            &::before { margin-right: var(--ecomm-unsuccessful-button-download-icon-margin-right); }
        }
    }
}