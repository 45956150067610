:root {
  --ecomm-payment-method-card-border-active: .3rem solid var(--brandSecondaryColor);

  --ecomm-payment-method-card-border-radius: 1rem;
  --ecomm-payment-method-card-box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
  --ecomm-payment-method-card-padding: 1rem;
  --ecomm-payment-method-card-width: 10rem;

  --ecomm-payment-method-card-name-weight: bold;
  --ecomm-payment-method-card-name-width: 100%;

  --ecomm-payment-method-card-img-width: 100%;
}

.ecomm-payment-method-card {
  border-radius: var(--ecomm-payment-method-card-border-radius);
  box-shadow: var(--ecomm-payment-method-card-box-shadow);
  padding: var(--ecomm-payment-method-card-padding);
  min-width: var(--ecomm-payment-method-card-width);

  > h4 { 
    font-weight: var(--ecomm-payment-method-card-name-weight); 
    width: var(--ecomm-payment-method-card-name-width);
    text-align: center;
  }

  > .img-wrapper {
    @include display-flex;
    @include justify-content(center);

    width: var(--ecomm-payment-method-card-img-width);
  }

  &.active { border: var(--ecomm-payment-method-card-border-active); }
}