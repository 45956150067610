@import '../00-settings/colors';
@import '../00-settings/font';
@import '../00-settings/responsive-breakpoints';

$msv-btn-min-width: 160px;
$msv-btn-side-padding: 20px;
$msv-btn-vertical-padding: 12px;

:root {
  // Background
  --msv-secondary-button-background-color: #{$msv-white};

  --ecomm-button-bg--hover: #{$msv-gray-700};
  --ecomm-button-border: 1px solid #{$msv-gray-700};
  --ecomm-button-min-width: 7rem;
  --ecomm-button-opacity--disabled: .7;
  --ecomm-button-padding: .5rem 1rem;
  --ecomm-button-size: 1rem;
  --ecomm-button-weight: bold;

  --ecomm-primary-button-bg: var(--brandPrimaryColor);
  --ecomm-primary-button-colour: var(--brandTextColour);

  --ecomm-primary-button-colour--gp: #{$msv-white};
}

@mixin button-link($color: $msv-link-color) {
  border-color: transparent;
  background-color: transparent;
  color: $color;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

@mixin primary-button($bg-color: var(--brandPrimaryColor), $color: var(--msv-font-secondary-color), $border-color: var(--brandPrimaryColor)) {
  background-color: $bg-color;
  border: 1px solid $border-color;
  color: $color;
  padding: $msv-btn-vertical-padding $msv-btn-side-padding;
  min-width: $msv-btn-min-width;

  &:hover,
  &:focus {
    background-color: var(--brandSecondaryColor);
    border-color: var(--brandSecondaryColor);
  }

  &:disabled,
  &[disabled] {
    cursor: default;
    border-color: $msv-gray-300;
    background-color: $msv-gray-300;
    color: var(--msv-font-secondary-color);
  }
}

@mixin secondary-button($bg-color: var(--msv-secondary-button-background-color), $color: var(--textDefaultColor), $border-color: var(--brandPrimaryColor), $disableBtn: true) {
  background-color: $bg-color;
  border: 1px solid $border-color;
  color: $color;
  padding: $msv-btn-vertical-padding $msv-btn-side-padding;
  min-width: $msv-btn-min-width;
  
  &:hover,
  &:focus {
    background-color: var(--msv-accent-secondary-btn);
  }
  
  @if $disableBtn {
    &:disabled,
    &[disabled] {
      cursor: default;
      border-color: $msv-gray-300;
      background-color: $msv-gray-300;
      color: var(--msv-font-secondary-color);
    }
  }
}

@mixin button-default() {
  @include vfi();
  @include font-content-l();
  cursor: pointer;
  padding: 12px 20px;
  height: 48px;
  min-width: 160px;
  text-align: center;
}

@mixin button-icon {
  width: 48px;
  height: 48px;
}

@mixin ecomm-primary-button($isPrimary: true, $org: 'BT', $width: 7rem) {
  border: var(--ecomm-button-border);
  cursor: pointer;
  font-size: var(--ecomm-button-size);
  font-weight: var(--ecomm-button-weight);
  min-width: #{$width};
  padding: var(--ecomm-button-padding);
  text-align: center;
  text-decoration: none;

  &:hover, &:active { 
    background-color: var(--ecomm-button-bg--hover); 
    text-decoration: none;
  }

  &:disabled, &.disabled {
    cursor: not-allowed;
    opacity: var(--ecomm-button-opacity--disabled);
  }

  @if $isPrimary {
    background-color: var(--ecomm-primary-button-bg);

    @if $org == 'BT' {
      color: var(--ecomm-primary-button-colour);
    } @else {
      color: var(--ecomm-primary-button-colour--gp);
    }
  } @else {
    background-color: var(--ecomm-secondary-button-bg);
    color: var(--ecomm-secondary-button-colour);
  }
}