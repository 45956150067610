:root {
  --ecomm-idp-footer-bg: #{$msv-white};
  --ecomm-idp-footer-colour: #{$msv-black};
  --ecomm-idp-footer-padding: 1rem 0;
  --ecomm-idp-footer-size: .8rem;
}

.ecomm-idp-footer {
  @include display-flex;
  @include justify-content(center);
  @include align-items(center);
  
  background-color: var(--ecomm-idp-footer-bg);
  color: var(--ecomm-idp-footer-colour);
  font-size: var(--ecomm-idp-footer-size);
  padding: var(--ecomm-idp-footer-padding);

  > .copyright { text-align: center; }
}