:root {
  --ecomm-main-menu-bg: #{$msv-white};
  --ecomm-main-menu-padding: 1rem 4rem;
  --ecomm-main-menu-width: 100%;
  --ecomm-main-menu-z: 9999;

  --ecomm-main-menu-border-height: .5rem;

  --ecomm-main-menu-logo-dimension: 60px;

  --ecomm-main-menu-nav-width: 100%;

	--ecomm-main-menu-node-parent-colour: var(--brandPrimaryColor);
  --ecomm-main-menu-node-parent-size: .9rem;
  --ecomm-main-menu-node-parent-weight: bold;

  --ecomm-main-menu-nolink-opacity: .5;

  --ecomm-main-menu-dd-border: 0;
  --ecomm-main-menu-dd-border-radius: 0;
  --ecomm-main-menu-dd-height: 150px;
  --ecomm-main-menu-dd-offset: 3rem 0 0 0;
  --ecomm-main-menu-dd-width: 100%;
  --ecomm-main-menu-dd-y: translateY(-3rem);
  --ecomm-main-menu-dd-z: 9999;

  --ecomm-main-menu-dd-content-bg: var(--brandPrimaryColor);
  --ecomm-main-menu-dd-content-column: auto auto auto auto;
  --ecomm-main-menu-dd-content-gap: 1rem;
  --ecomm-main-menu-dd-content-padding: 1.5rem 4rem;

  --ecomm-main-menu-ul-colour: var(--brandTextColour);
  --ecomm-main-menu-ul-margin: 0;
  --ecomm-main-menu-ul-padding: 0;
  --ecomm-main-menu-ul-weight: bold;

  --ecomm-main-menu-li-colour: #{$msv-white};
  --ecomm-main-menu-li-margin: 1rem 0 0 0;

  --ecomm-main-menu-li-link-icon-margin: 0 .2rem 0 0;

  --ecomm-main-menu-li-link-colour: #{$msv-white};
  
  --ecomm-main-menu-li-nolink-colour: #{$msv-gray-300};
  --ecomm-main-menu-li-nolink-opacity: .5;

  --ecomm-main-menu-action-wrapper-width: 100%;

  --ecomm-main-menu-action-icon-margin: 0 0 0 .5rem;

  --ecomm-main-menu-cart-margin: 0;

  --ecomm-main-menu-action-bg-hover: #{$msv-gray-300};  
	--ecomm-main-menu-action-border: 1px solid #{$msv-gray-700};
  --ecomm-main-menu-action-margin: 0 .5rem 0 0;
  --ecomm-main-menu-action-padding: .5rem 1rem;
  --ecomm-main-menu-action-width: 7rem;

  --ecomm-main-menu-account-bg: var(--brandPrimaryColor);
  --ecomm-main-menu-account-radius: 50%;

  --ecomm-main-menu-account-sign-bg: var(--brandPrimaryColor);
  --ecomm-main-menu-account-sign-colour: var(--brandTextColour);
  --ecomm-main-menu-account-sign-colour-hover: var(--brandPrimaryColor);

  --ecomm-main-menu-account-initial-colour: var(--brandTextColour);

  --ecomm-main-menu-account-dropdown-colour: var(--brandPrimaryColor);
  --ecomm-main-menu-account-dropdown-left: -7.5rem;

	--ecomm-sub-menu-bg: var(--brandPrimaryColor);
  --ecomm-sub-menu-height: 3rem;
  --ecomm-sub-menu-padding: 0 4rem;
  --ecomm-sub-menu-width: 100%;

  --ecomm-sub-menu-div-padding: 0;

  --ecomm-sub-menu-q-link-size: .9rem;

  --ecomm-sub-menu-q-label-margin: 0 .2rem 0 0;

  --ecomm-sub-menu-q-link-colour: var(--brandTextColour);
  --ecomm-sub-menu-q-link-colour-portal: var(--brandTextColour);
  --ecomm-sub-menu-q-link-colour-none: #{$msv-gray-300};
  --ecomm-sub-menu-q-link-opacity-none: .5;
  --ecomm-sub-menu-q-link-weight: bold;

  --ecomm-sub-menu-q-divider-margin: 0 .2rem;

  --ecomm-sub-menu-lang-bg: var(--brandPrimaryColor);
	--ecomm-sub-menu-lang-border: 1px solid var(--brandTextColour);
  --ecomm-sub-menu-lang-dd-colour: var(--brandTextColour);
  --ecomm-sub-menu-lang-radius: 0;

  --ecomm-sub-menu-lang-dd-bg: transparent;
  --ecomm-sub-menu-lang-dd-border: none;
  --ecomm-sub-menu-lang-dd-padding: .3rem 1.5rem;

  --ecomm-sub-menu-lang-dd-icon-margin: 0 .3rem;

  --ecomm-quicklink-new-label-color: #{$msv-black};
  --ecomm-quicklink-new-lable-background-color: var(--brandTextColour);
  --ecomm-quicklink-new-label-width: 50px;
  --ecomm-quicklink-new-label-margin-left: 0.5rem;
  --ecomm-quicklink-new-label-border-radius: 50px;
  --ecomm-quicklink-new-label-font-size: 0.8rem;

  --ecomm-mainlink-new-label-color: var(--brandTextColour);
  --ecomm-mainlink-new-lable-background-color: var(--brandPrimaryColor);
}

.ecomm-main-menu.desktop {
  > .border {
		background-repeat: no-repeat;
		background-size: cover;
		height: var(--ecomm-main-menu-border-height);
	}

  > .navbar {
    background-color: var(--ecomm-main-menu-bg);
    padding: var(--ecomm-main-menu-padding);

    // VERY IMPORTANT DROPDOWN ON HOVER CSS.
    .dropdown-toggle:after { display: none; }

    // VERY IMPORTANT DROPDOWN ON HOVER CSS.
    .dropdown:hover .dropdown-menu { display: block; }

    > .logo-wrapper {
      > .logo {
        width: var(--ecomm-main-menu-logo-dimension);
        height: var(--ecomm-main-menu-logo-dimension);

        > img { object-fit: contain; }
      }
    }

    .navbar-nav {
      width: var(--ecomm-main-menu-nav-width);

      a.nav-link {
        color: var(--ecomm-main-menu-node-parent-colour);
        font-size: var(--ecomm-main-menu-node-parent-size);
        font-weight: var(--ecomm-main-menu-node-parent-weight);

        &::after { display: none; }
      }

      > .main-link {
        &.nav-item.dropdown {
          position: static;

          > .dropdown-menu {
            background-color: transparent;
            border: var(--ecomm-main-menu-dd-border);
            border-radius: var(--ecomm-main-menu-dd-border-radius);
            min-height: var(--ecomm-main-menu-dd-height);
            padding: var(--ecomm-main-menu-dd-offset);
            transform: var(--ecomm-main-menu-dd-y);
            width: var(--ecomm-main-menu-dd-width);
            z-index: var(--ecomm-main-menu-dd-z);
  
            > .dropdown-content {
              background-color: var(--ecomm-main-menu-dd-content-bg);
              display: grid;
              grid-gap: var(--ecomm-main-menu-dd-content-gap);
              grid-template-columns: var(--ecomm-main-menu-dd-content-column);
              padding: var(--ecomm-main-menu-dd-content-padding); 
  
              ul {
                display: inline-block;
                list-style: none;
                margin: var(--ecomm-main-menu-ul-);
                padding: var(--ecomm-main-menu-ul-padding);
                vertical-align: top;
          
                &::before {
                  color: var(--ecomm-main-menu-ul-colour);
                  content: attr(aria-label);
                  font-weight: var(--ecomm-main-menu-ul-weight);
                }
            
                li {        
                  color: var(--ecomm-main-menu-li-colour);
                  margin: var(--ecomm-main-menu-li-margin);
            
                  a { 
                    @include add-icon($msv-CaretRight, before, inline);

                    @include align-items(center);
                    color: var(--ecomm-main-menu-li-link-colour);
  
                    &:hover {
                      color: var(--ecomm-main-menu-li-link-colour);
                      text-decoration: none;
                    }

                    &::before { margin: var(--ecomm-main-menu-li-link-icon-margin); }
                  }

                  &.no-link {
                    color: var(--ecomm-main-menu-li-nolink-colour);
                    opacity: var(--ecomm-main-menu-li-nolink-opacity);

                    a { color: var(--ecomm-main-menu-li-nolink-colour); }
                  }
                }
              }
            }
          }
        }

        &.no-link {
          opacity: var(--ecomm-main-menu-nolink-opacity);
          pointer-events: none;
        }

        > a.nav-link { white-space: nowrap; 

          > .mainlink-item-new{
            color: var(--ecomm-mainlink-new-label-color);
            background-color: var(--ecomm-mainlink-new-lable-background-color);
            border-radius: var(--ecomm-quicklink-new-label-border-radius);
            width: var(--ecomm-quicklink-new-label-width);
            text-align: center;
            margin-left: var(--ecomm-quicklink-new-label-margin-left);
            font-size: var(--ecomm-quicklink-new-label-font-size);
         }
        }

      }

      > .actions-wrapper {
        @include display-flex;
        @include justify-content(end);

        width: var(--ecomm-main-menu-action-wrapper-width);
        
        height: fit-content;
  
        > .action {
          border: var(--ecomm-main-menu-action-border);
          cursor: pointer;
          display: inline-block;
          min-width: var(--ecomm-main-menu-action-width);
          padding: var(--ecomm-main-menu-action-padding);
          text-align: center;
          text-decoration: none;
      
          &:hover { 
            background-color: var(--ecomm-main-menu-action-bg-hover);
            text-decoration: none; 
          }
      
          &:not(:last-child) { margin: var(--ecomm-main-menu-action-margin); }
      
          &--search {
            @include add-icon($msv-magnifying-glass, after, inline);
          }
      
          &--cart {
            @include add-icon($msv-ShoppingCart, after, inline);
      
            &::after { margin: var(--ecomm-main-menu-action-icon-margin); }

            > p { 
              display: inline-block;
              margin: var(--ecomm-main-menu-cart-margin); 
            }
          }
      
          &--sign-in {
            @include add-icon($msv-SignIn, after, inline);
      
            background-color: var(--ecomm-main-menu-account-sign-bg);
            color: var(--ecomm-main-menu-account-sign-colour);
      
            &::after { margin: var(--ecomm-main-menu-action-icon-margin); }
      
            &:hover { color: var(--ecomm-main-menu-account-sign-colour-hover); }
          }
        }

        > .account {
          background-color: var(--ecomm-main-menu-account-bg);
          border-radius: var(--ecomm-main-menu-account-radius);

          > a.nav-link { color: var(--ecomm-main-menu-account-initial-colour); }

          > .dropdown-menu {
            left: var(--ecomm-main-menu-account-dropdown-left);

            > a.dropdown-item { color: var(--ecomm-main-menu-account-dropdown-colour); }
          }
        }
      }
    }
  }

  > .sub-menu {
    @include align-items(center);
    
    background-color: var(--ecomm-sub-menu-bg);
    min-height: var(--ecomm-sub-menu-height);
		padding: var(--ecomm-sub-menu-padding);
		width: var(--ecomm-sub-menu-width);

    > div { padding: var(--ecomm-sub-menu-div-padding); }

		> .quicklinks-wrapper {
      font-size: var(--ecomm-sub-menu-q-link-size);
  
      > .label {
        margin: var(--ecomm-sub-menu-q-label-margin);
        display: inline;
      }

      > .quicklink-item {
        display: inline-block;

        > .quicklink-item-group {
          display: inline-block;

          > a {
            color: var(--ecomm-sub-menu-q-link-colour);
            font-weight: var(--ecomm-sub-menu-q-link-weight);
  
            &.portal { 
              color: var(--ecomm-sub-menu-q-link-colour-portal); 
  
              &:hover { color: var(--ecomm-sub-menu-q-link-colour-portal); }
            }
  
            &.no-link { 
              color: var(--ecomm-sub-menu-q-link-colour-none);
              opacity: var(--ecomm-sub-menu-q-link-opacity-none);
              pointer-events: none;
  
              &:hover {
                cursor: pointer;
                text-decoration: inherit;
              }
            }
    
            &:hover {
              color: var(--ecomm-sub-menu-q-link-colour);
              cursor: pointer;
              text-decoration: inherit;
            }
          }

          .quicklink-item-new{
                  color: var(--ecomm-quicklink-new-label-color);
                  background-color: var(--ecomm-quicklink-new-lable-background-color);
                  border-radius: var(--ecomm-quicklink-new-label-border-radius);
                  width: var(--ecomm-quicklink-new-label-width);
                  text-align: center;
                  margin-left: var(--ecomm-quicklink-new-label-margin-left);
                  font-size: var(--ecomm-quicklink-new-label-font-size);
               }
        }

        > .divider { 
          margin: var(--ecomm-sub-menu-q-divider-margin);
          display: inline;
        }
      }
		}

    > .language-wrapper .dropdown {
      background-color: var(--ecomm-sub-menu-lang-bg);
      border: var(--ecomm-sub-menu-lang-border);
      border-radius: var(--ecomm-sub-menu-lang-radius);
      float: right;
  
      > button {
        @include add-icon($msv-globe, before, inline);
        
        background-color: var(--ecomm-sub-menu-lang-dd-bg);
        border: var(--ecomm-sub-menu-lang-dd-border);
        color: var(--ecomm-sub-menu-lang-dd-colour);
        padding: var(--ecomm-sub-menu-lang-dd-padding);

        &::before { margin: var(--ecomm-sub-menu-lang-dd-icon-margin); }

        &:after { margin: var(--ecomm-sub-menu-lang-dd-icon-margin); }

        &:focus {
          background-color: var(--ecomm-sub-menu-lang-bg);
          box-shadow: none;
          color: var(--ecomm-sub-menu-lang-dd-colour);
        }
    
        &:hover {
          background-color: var(--ecomm-sub-menu-lang-bg);
          color: var(--ecomm-sub-menu-lang-dd-colour);
        }
      }
    }
  }
}