:root {
  --ecomm-mobile-menu-navbar-bg: #{$msv-white};
  --ecomm-mobile-menu-navbar-padding: 0;

  --ecomm-mobile-menu-header-padding: .5rem 1rem;
  --ecomm-mobile-menu-header-width: 100%;

  --ecomm-mobile-menu-brand-margin: 0;
  --ecomm-mobile-menu-brand-padding: 0;
  --ecomm-mobile-menu-brand-width: 3rem;

  --ecomm-mobile-menu-toggler-border: none;

  --ecomm-mobile-menu-toggler-icon-bg: none;
  --ecomm-mobile-menu-toggler-icon-colour: var(--brandPrimaryColor);
  --ecomm-mobile-menu-toggler-icon-size: 1.5rem;

  --ecomm-mobile-menu-lang-colour: var(--brandPrimaryColor);
  
  --ecomm-mobile-menu-lang-icon-margin: 0 .3rem;

  --ecomm-mobile-menu-lang-dd-left: -4rem;

  --ecomm-mobile-menu-action-colour: var(--brandPrimaryColor);
  --ecomm-mobile-menu-action-padding: .5rem;
  --ecomm-mobile-menu-action-weight: bold;

  --ecomm-mobile-menu-cart-margin: 0 .5rem 0 0;

  --ecomm-mobile-menu-account-bg: var(--brandPrimaryColor);
  --ecomm-mobile-menu-account-radius: 50%;
  --ecomm-mobile-menu-account-width: 3rem;

  --ecomm-mobile-menu-account-link-colour: var(--brandTextColour);
  --ecomm-mobile-menu-account-link-weight: bold;

  --ecomm-mobile-menu-account-dd-left: -6rem;

  --ecomm-mobile-menu-account-dd-item-colour: var(--brandPrimaryColor);

  --ecomm-mobile-menu-collapse-bg: var(--brandPrimaryColor);
  --ecomm-mobile-menu-collapse-height: 70vh;
  --ecomm-mobile-menu-collapse-overflow: scroll;
  --ecomm-mobile-menu-collapse-padding: 1rem;

  --ecomm-mobile-menu-nav-link-colour: #{$msv-white};
  --ecomm-mobile-menu-nav-link-padding: .5rem 0;
  --ecomm-mobile-menu-nav-link-size: .8rem;

  --ecomm-mobile-menu-nav-nolink-opacity: .5;

  --ecomm-mobile-menu-q-wrapper-border-b: 1px solid #{$msv-white};
  --ecomm-mobile-menu-q-wrapper-padding: 0 0 1rem 0;

  --ecomm-mobile-menu-q-label-size: 1rem;
  --ecomm-mobile-menu-q-label-weight: bold;

  --ecomm-mobile-menu-q-portal-icon-margin: 0 0.5rem 0;
  --ecomm-mobile-menu-q-portal-icon-size: .8rem;

  --ecomm-mobile-menu-sitelinks-wrapper-padding: 1rem 0 0 0;

  --ecomm-mobile-menu-sitelinks-main-size: 1rem;
  --ecomm-mobile-menu-sitelinks-main-v-align: .1em;
  --ecomm-mobile-menu-sitelinks-main-weight: bold;

  --ecomm-mobile-menu-sitelinks-dd-padding: 0;

  --ecomm-mobile-menu-sitelinks-dd-ul-colour: var(--brandTextColour);
  --ecomm-mobile-menu-sitelinks-dd-ul-padding: 0;
  --ecomm-mobile-menu-sitelinks-dd-ul-size: .8rem;
  --ecomm-mobile-menu-sitelinks-dd-ul-weight: bold;

  --ecomm-mobile-menu-sitelinks-dd-li-icon-colour: #{$msv-white};
  --ecomm-mobile-menu-sitelinks-dd-li-icon-margin: 0 .5rem 0 0;

  --ecomm-mobile-menu-sitelinks-dd-li-nolink-colour: #{$msv-gray-300};
  --ecomm-mobile-menu-sitelinks-dd-li-nolink-opacity: .5;

  --ecomm-quicklink-new-label-color: #{$msv-black};
  --ecomm-quicklink-new-lable-background-color: var(--brandTextColour);
  --ecomm-quicklink-new-label-width: 50px;
  --ecomm-quicklink-new-label-margin-left: 0.5rem;
  --ecomm-quicklink-new-label-border-radius: 50px;
  --ecomm-quicklink-new-label-font-size: 0.8rem;

}

.ecomm-main-menu.mobile {
  .navbar {
    background-color: var(--ecomm-mobile-menu-navbar-bg);
    padding: var(--ecomm-mobile-menu-navbar-padding);
  
    > .header-wrapper {
      @include display-flex;
      @include align-items(center);
      
      padding: var(--ecomm-mobile-menu-header-padding);
      width: var(--ecomm-mobile-menu-header-width);

      > .language-wrapper {
        margin-left: auto;

        > .dropdown {
          > button {
            @include add-icon($msv-globe, before, inline);
  
            background-color: transparent;
            border: none;
            color: var(--ecomm-mobile-menu-lang-colour);
  
            &::before { margin: var(--ecomm-mobile-menu-lang-icon-margin); }

            &:focus { box-shadow: none; }
          }

          > .dropdown-menu { left: var(--ecomm-mobile-menu-lang-dd-left); }
        }
      }

      > .actions-wrapper {
        @include display-flex;
        margin-left: auto;
  
        > a.nav-link.action {
          color: var(--ecomm-mobile-menu-action-colour);
          font-weight: var(--ecomm-mobile-menu-action-weight);
          padding: var(--ecomm-mobile-menu-action-padding);
  
          &--cart { 
            @include add-icon($msv-ShoppingCart, before, inline); 

            &::before { margin: var(--ecomm-mobile-menu-cart-margin); }

            > p { display: none; }
          }
        }
  
        // LOGGED IN DESGIN.
        > .account {
          @include display-flex;
          @include justify-content(center);
          
          background-color: var(--ecomm-mobile-menu-account-bg);
          border-radius: var(--ecomm-mobile-menu-account-radius);
          width: var(--ecomm-mobile-menu-account-width);
  
          > a.nav-link { 
            color: var(--ecomm-mobile-menu-account-link-colour); 
            font-size: var(--ecomm-mobile-menu-account-link-weight);
  
            &::after { display: none; }
  
            &:hover { color: inherit; }
          }
  
          > .dropdown-menu {
            left: var(--ecomm-mobile-menu-account-dd-left);
  
            > a.dropdown-item { color: var(--ecomm-mobile-menu-account-dd-item-colour); }
          }
        }
      }
    }
  
    &-brand {
      margin: var(--ecomm-mobile-menu-brand-margin);
      padding: var(--ecomm-mobile-menu-brand-padding);
      width: var(--ecomm-mobile-menu-brand-width);
    }

    &-toggler {
      border: var(--ecomm-mobile-menu-toggler-border);

      &-icon {
        @include add-icon($msv-GlobalNavButton, before, inline);
        
        background-image: var(--ecomm-mobile-menu-toggler-icon-bg);

        &::before { 
          color: var(--ecomm-mobile-menu-toggler-icon-colour); 
          font-size: var(--ecomm-mobile-menu-toggler-icon-size);
        }
      }
    }

    &-collapse {
      background-color: var(--ecomm-mobile-menu-collapse-bg);
      max-height: var(--ecomm-mobile-menu-collapse-height);
      overflow-y: var(--ecomm-mobile-menu-collapse-overflow);
      padding: var(--ecomm-mobile-menu-collapse-padding);
    }

    &-nav {
      font-size: var(--ecomm-mobile-menu-nav-link-size);

      a { 
        color: var(--ecomm-mobile-menu-nav-link-colour);
        padding: var(--ecomm-mobile-menu-nav-link-padding);
      }
      
      > .quicklinks-wrapper {
        border-bottom: var(--ecomm-mobile-menu-q-wrapper-border-b);
        padding: var(--ecomm-mobile-menu-q-wrapper-padding);

        > .label { 
          font-size: var(--ecomm-mobile-menu-q-label-size);
          font-weight: var(--ecomm-mobile-menu-q-label-weight); 
        }

        > .quicklink-item {
          > .quicklink-item-group {
            > a.portal { 
              @include add-icon($msv-external, after, inline); 

              &:before {
                display: none;
              }
              &:after{
                font-size: var(--ecomm-mobile-menu-q-portal-icon-size);
                margin: var(--ecomm-mobile-menu-q-portal-icon-margin);
                line-height: inherit;
              }
            }

            .quicklink-item-new{
              color: var(--ecomm-quicklink-new-label-color);
              background-color: var(--ecomm-quicklink-new-lable-background-color);
              border-radius: var(--ecomm-quicklink-new-label-border-radius);
              width: var(--ecomm-quicklink-new-label-width);
              text-align: center;
              margin-left: var(--ecomm-quicklink-new-label-margin-left);
              font-size: var(--ecomm-quicklink-new-label-font-size);
           }
            
          }

          > .divider { display: none; }
        }
      }

      > .sitelinks-wrapper {
        padding: var(--ecomm-mobile-menu-sitelinks-wrapper-padding);

        > .main-link {
          > a { 
            color: var(--ecomm-mobile-menu-nav-link-colour);
            font-size: var(--ecomm-mobile-menu-sitelinks-main-size);
            font-weight: var(--ecomm-mobile-menu-sitelinks-main-weight); 

            &.dropdown-toggle::after { vertical-align: var(--ecomm-mobile-menu-sitelinks-main-v-align); }

            &:hover { color: var(--ecomm-mobile-menu-nav-link-colour); }

            > .mainlink-item-new{
              color: var(--ecomm-quicklink-new-label-color);
              background-color: var(--ecomm-quicklink-new-lable-background-color);
              border-radius: var(--ecomm-quicklink-new-label-border-radius);
              width: var(--ecomm-quicklink-new-label-width);
              text-align: center;
              margin-left: var(--ecomm-quicklink-new-label-margin-left);
              font-size: var(--ecomm-quicklink-new-label-font-size);
           }
           
          }

          > .dropdown-menu {
            background-color: transparent;
            border: none;
            padding: var(--ecomm-mobile-menu-sitelinks-dd-padding);

            > .dropdown-content {
              > ul {
                font-size: var(--ecomm-mobile-menu-sitelinks-dd-ul-size);
                list-style-type: none;
                padding: var(--ecomm-mobile-menu-sitelinks-dd-ul-padding);

                &::before {
                  color: var(--ecomm-mobile-menu-sitelinks-dd-ul-colour);
                  content: attr(aria-label);
                  font-weight: var(--ecomm-mobile-menu-sitelinks-dd-ul-weight);
                }

                > li {
                  @include add-icon($msv-CaretRight, before, inline);

                  @include display-flex;
                  @include align-items(center);

                  &::before {
                    color: var(--ecomm-mobile-menu-sitelinks-dd-li-icon-colour);
                    margin: var(--ecomm-mobile-menu-sitelinks-dd-li-icon-margin);
                  }

                  &.no-link {
                    opacity: var(--ecomm-mobile-menu-sitelinks-dd-li-nolink-opacity);

                    &::before { color: var(--ecomm-mobile-menu-sitelinks-dd-li-nolink-colour); }

                    > a { color: var(--ecomm-mobile-menu-sitelinks-dd-li-nolink-colour); }
                  }
                }
              }
            }
          }

          &.no-link {
            opacity: var(--ecomm-mobile-menu-nav-nolink-opacity);
            pointer-events: none;
          }

        }
      }
    }
  }
}