:root {
  --ecomm-product-card-weight: #{$msv-font-weight-heavy};
  --ecomm-product-card-width: 10rem;

  --ecomm-product-card-info-padding: 10px 0;

  --ecomm-product-card-img-grey: grayscale(100%) contrast(50%);
  --ecomm-product-card-img-z: 1;

  --ecomm-product-card-name-colour: var(--brandPrimaryColor);
  --ecomm-product-card-name-height: 2rem;

  --ecomm-product-card-price-colour: #{$medium-carmine};

  --ecomm-product-card-tag-font-size: 12px;
  --ecomm-product-card-tag-font-weight: normal;
  --ecomm-product-card-tag-left: 0;
  --ecomm-product-card-tag-bottom: 5%;
  --ecomm-product-card-tag-padding-left: 15px;
  --ecomm-product-card-tag-width: 90%;
  --ecomm-product-card-tag-height: 27px;
  --ecomm-product-card-tag-background-color: var(--brandPrimaryColor);
  --ecomm-product-card-tag-color: #{$msv-white};
  --ecomm-product-card-tag-opacity: 80%;
  --ecomm-product-card-tag-transform-origin: 0 0;
  --ecomm-product-card-tag-transform: skewX(-20deg);

  // MOBILE STYLINGS
  --ecomm-product-card-width-mobile: 8rem;

  --ecomm-product-card-name-height-mobile: 3rem;
}

.ecomm-product-card {
  font-weight: var(--ecomm-product-card-weight);
  margin-left: auto;
  margin-right: auto;

  .img-container {
    position: relative;
    overflow: hidden;

    img { 
      @include breakpoint($msv-breakpoint-l) { max-width: var(--ecomm-product-card-width-mobile); }

      max-width: var(--ecomm-product-card-width); 
    }

    div {
      position: absolute;
      font-size: var(--ecomm-product-card-tag-font-size);
      font-weight: var(--ecomm-product-card-tag-font-weight);
      left: var(--ecomm-product-card-tag-left);
      bottom: var(--ecomm-product-card-tag-bottom);
      width: var(--ecomm-product-card-tag-width);
      height: var(--ecomm-product-card-tag-height);
      padding-left: var(--ecomm-product-card-tag-padding-left);
      background-color: var(--ecomm-product-card-tag-background-color);
      color: var(--ecomm-product-card-tag-color);
      opacity: var(--ecomm-product-card-tag-opacity);
      transform-origin: var(--ecomm-product-card-tag-transform-origin);
      transform: var(--ecomm-product-card-tag-transform);
    }

    .msc-empty_image { @include image-placeholder(var(--ecomm-product-card-width)); }
  }

  .info-container {
    @include breakpoint($msv-breakpoint-l) { max-width: var(--ecomm-product-card-width-mobile); }

    max-width: var(--ecomm-product-card-width);
    padding: var(--ecomm-product-card-info-padding);

    p:first-child { 
      @include breakpoint($msv-breakpoint-l) { min-height: var(--ecomm-product-card-name-height-mobile); }

      color: var(--ecomm-product-card-name-colour);
      min-height: var(--ecomm-product-card-name-height); 
    }

    p:last-child { color: var(--ecomm-product-card-price-colour); }
  }

  &.not-available {
    .img-container {
      @include display-flex;
      @include align-items(center);
      @include justify-content(center);

      position: relative;

      p { 
        position: absolute;
        z-index: var(--ecomm-product-card-img-z); 
      }

      img {
        -webkit-filter: var(--ecomm-product-card-img-grey);
        -moz-filter: var(--ecomm-product-card-img-grey);
        -o-filter: var(--ecomm-product-card-img-grey);
        -ms-filter: var(--ecomm-product-card-img-grey);
        filter: var(--ecomm-product-card-img-grey); 
      }
    }
  }
}