$container-title-padding: 20px 0;

:root {
  --container-title-colour: var(--brandPrimaryColor);
  --container-title-font-weight: #{$msv-font-weight-bold};
}

.ecomm-job-card-container {
  &-title {
    color: var(--container-title-colour);
    font-weight: var(--container-title-font-weight);
    padding: $container-title-padding;
    text-align: center;
  }
}