:root {
  --ecomm-popup-title-color: var(--brandPrimaryColor);
  --ecomm-popup-title-size: 24px;
  --ecomm-popup-title-weight: bold;
  --ecomm-popup-title-padding-left: .5rem;

  --ecomm-popup-body-colour: #{$msv-black};
  --ecomm-popup-body-colour--highlight: #{$amber};
  --ecomm-popup-body-padding: .5rem;
  --ecomm-popup-body-weight: bold;

  --ecomm-popup-body-header-colour: var(--brandSecondaryColor);

  --ecomm-popup-btn-bg: var(--brandPrimaryColor);
  --ecomm-popup-btn-border: 1px solid #{$msv-gray-300};
  --ecomm-popup-btn-colour--stay: var(--brandPrimaryColor);
  --ecomm-popup-btn-colour--go: var(--brandTextColour);
  --ecomm-popup-btn-colour--go-portal: var(--brandTextColour);
  --ecomm-popup-btn-padding: .75rem 1rem;
  --ecomm-popup-btn-size: 16px;
  --ecomm-popup-btn-weight: bold;
  --ecomm-popup-btn-text-decor: none;

  --ecomm-popup-lamp-bg: #{$msv-white};
  --ecomm-popup-lamp-border: 1px solid #{$msv-gray-700};
  --ecomm-popup-lamp-padding: 0.5rem 0;
}

.ecomm-popup {
  &-title {
    color: var(--ecomm-popup-title-color);
    font-size: var(--ecomm-popup-title-size);
    font-weight: var(--ecomm-popup-title-weight);
    padding-left: var(--ecomm-popup-title-padding-left);
  }

  &-body {
    color: var(--ecomm-popup-body-colour);
    padding: var(--ecomm-popup-body-padding);

    &-highlight {
      color: var(--ecomm-popup-body-colour--highlight);
      font-weight: var(--ecomm-popup-body-weight);
    }

    &-header {
      color: var(--ecomm-popup-body-header-colour);
      font-weight: var(--ecomm-popup-body-weight);
    }

    .lamp-option-group {
      display: inline;

      input[type=checkbox]:checked + div { background-color: var(--ecomm-popup-lamp-bg); }
    }

    .lamp-option {
      background-color: var(--ecomm-popup-lamp-bg);
      border: var(--ecomm-popup-lamp-border);
      padding: var(--ecomm-popup-lamp-padding);
    }
  }

  &-button {
    @include justify-content(center);
    
    font-size: var(--ecomm-popup-btn-size);
    font-weight: var(--ecomm-popup-btn-weight);
    border: var(--ecomm-popup-btn-border);
    padding: var(--ecomm-popup-btn-padding);

    &-stay, &-stay:hover, &-stay:active, &-stay:visited {
      color: var(--ecomm-popup-btn-colour--stay);
      text-decoration: var(--ecomm-popup-btn-text-decor);
    }

    &-go, &-go:hover, &-go:active, &-go:visited {
      color: var(--ecomm-popup-btn-colour--go);
      background-color: var(--ecomm-popup-btn-bg);
      text-decoration: var(--ecomm-popup-btn-text-decor);
    }

    &-go-portal, &-go-portal:hover, &-go-portal:active, &-go-portal:visited {
      color: var(--ecomm-popup-btn-colour--go-portal);
      background-color: var(--ecomm-popup-btn-bg);
      text-decoration: var(--ecomm-popup-btn-text-decor);
    }
  }
}