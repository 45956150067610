:root {
    --ecomm-portal-popup-title-color: var(--brandPrimaryColor);
    --ecomm-portal-popup-title-size: 24px;
    --ecomm-portal-popup-title-weight: bold;
    --ecomm-portal-popup-title-padding-left: 2rem;
  
    --ecomm-portal-popup-body-colour: #{$msv-black};
    --ecomm-portal-popup-body-colour--highlight: #{$amber};
    --ecomm-portal-popup-body-padding: .5rem;
    --ecomm-portal-popup-body-weight: bold;
  
    --ecomm-portal-popup-body-header-colour: var(--brandSecondaryColor);
  
    --ecomm-portal-popup-btn-bg: none;
    --ecomm-portal-popup-btn-border: 1px solid #{$msv-gray-300};
    --ecomm-portal-popup-btn-colour--stay: var(--brandTextColour);
    --ecomm-portal-popup-btn-colour--go: var(--brandTextColour);
    --ecomm-portal-popup-btn-colour--go-portal: #{$msv-gray-400};
    --ecomm-portal-popup-btn-padding: .75rem 1rem;
    --ecomm-portal-popup-btn-size: 16px;
    --ecomm-portal-popup-btn-weight: bold;
    --ecomm-portal-popup-btn-text-decor: none;
  
    --ecomm-portal-popup-lamp-bg: #{$msv-white};
    --ecomm-portal-popup-lamp-border: 1px solid #{$msv-gray-700};
    --ecomm-portal-popup-lamp-padding: 0.5rem 0;

    --ecomm-portal-modal-animation-duration: 0.2s;
    --ecomm-portal-modal-backdrop-opacity: 0.2;
  }
  
  .ecomm-portal-popup {
    &-title {
      color: var(--ecomm-portal-popup-title-color);
      font-size: var(--ecomm-portal-popup-title-size);
      font-weight: var(--ecomm-portal-popup-title-weight);
      padding-left: var(--ecomm-portal-popup-title-padding-left);
      margin-left: auto;
    }
  
    &-body {
      color: var(--ecomm-portal-popup-body-colour);
      padding: var(--ecomm-portal-popup-body-padding);
      text-align: center;
      padding-bottom: 0px;
      padding-top: 0px;
  
      &-highlight {
        color: var(--ecomm-portal-popup-body-colour--highlight);
        font-weight: var(--ecomm-portal-popup-body-weight);
      }
  
      &-header {
        color: var(--ecomm-portal-popup-body-header-colour);
        font-weight: var(--ecomm-portal-popup-body-weight);
      }
  
      .lamp-option-group {
        display: inline;
  
        input[type=checkbox]:checked + div { background-color: var(--eshop-popup-lamp-bg); }
      }
  
      .lamp-option {
        background-color: var(--ecomm-portal-popup-lamp-bg);
        border: var(--ecomm-portal-popup-lamp-border);
        padding: var(--ecomm-portal-popup-lamp-padding);
      }
    }

    &-wrapper{
        width:85%;
        padding-right: 4rem;
    }
  
    &-button {
      @include justify-content(center);
      
      font-size: var(--ecomm-portal-popup-btn-size);
      font-weight: var(--ecomm-portal-popup-btn-weight);
      border: var(--ecomm-portal-popup-btn-border);
      padding: var(--ecomm-portal-popup-btn-padding);
  
      &-stay, &-stay:hover, &-stay:active, &-stay:visited {
        color: var(--ecomm-portal-popup-btn-colour--stay);
        text-decoration: var(--ecomm-portal-popup-btn-text-decor);
        background-color: var(--brandPrimaryColor);
        font-weight: normal;
      }
  
      &-go, &-go:hover, &-go:active, &-go:visited {
        color: var(--ecomm-portal-popup-btn-colour--go);
        background-color: var(--ecomm-portal-popup-btn-bg);
        text-decoration: var(--ecomm-portal-popup-btn-text-decor);
        border: none;
      }
  
      &-go-portal, &-go-portal:hover, &-go-portal:active, &-go-portal:visited {
        color: var(--ecomm-portal-popup-btn-colour--go-portal);
        background-color: var(--ecomm-portal-popup-btn-bg);
        text-decoration: var(--ecomm-portal-popup-btn-text-decor);
        border: none;
      }
    }
  }

  .modal-backdrop {
    background-color: $msv-black;
    height: 100vh;
    left: 0;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: $msv-modal-z-index - 10;

    &.show {
        animation: modal-backdrop-appear var(--ecomm-portal-msv-modal-animation-duration);
        opacity: var(--ecomm-portal-modal-backdrop-opacity);
    }

    &.fade:not(.show) {
        animation: modal-backdrop-appear var(--ecomm-portal-msv-modal-animation-duration);
        opacity: 0;
    }
}