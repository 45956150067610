:root {
  --notif-banner-bg: #{$msv-gray-900};

  --notif-banner-button-colour: var(--brandTextColour);
  --notif-banner-button-padding: 0;

  --notif-banner-font-colour: #{$msv-white};
  --notif-banner-font-bold-colour: var(--brandTextColour);

  --notif-banner-padding: 3rem;
  --notif-banner-padding-mobile: 2rem;

  --notif-banner-para-size: .8rem;
  --notif-banner-para-margin: 10px 0 0 0;
  --notif-banner-para-padding: 0 10rem 0 0;
  --notif-banner-para-padding-mobile: 0;

  --notif-banner-title-colour: var(--brandTextColour);
  --notif-banner-title-padding: 0;

  --notif-banner-width: 100%;
}

.ecomm-notif-banner {
  background-color: var(--notif-banner-bg);
  color: var(--notif-banner-font-colour);
  padding: var(--notif-banner-padding);
  width: var(--notif-banner-width);

  @include breakpoint($msv-breakpoint-l) { padding: var(--notif-banner-padding-mobile); }

  .title { 
    color: var(--notif-banner-title-colour);
    padding: var(--notif-banner-title-padding);
  }

  .button-close {
    @include display-flex;
    @include justify-content(end);
  
    padding: var(--notif-banner-button-padding);

    button {
      @include add-icon($msv-Cancel, before);

      background-color: transparent;
      border: none;
      color: var(--notif-banner-button-colour);
    }
  }

  &-editor {
    margin: var(--notif-banner-para-margin);
    padding: var(--notif-banner-para-padding);

    @include breakpoint($msv-breakpoint-l) { 
      font-size: var(--notif-banner-para-size);
      padding: var(--notif-banner-para-padding-mobile); 
    }

    p strong { color: var(--notif-banner-font-bold-colour); }
  }
}