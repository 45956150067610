:root {
	--ecomm-upcoming-programmes-padding: 2rem 1rem;

	--ecomm-upcoming-programmes-top-colour: var(--brandPrimaryColor);
	--ecomm-upcoming-programmes-top-margin: 0 0 1rem 0;
	--ecomm-upcoming-programmes-top-padding: 0 2rem;

	--ecomm-upcoming-programmes-title-size: 1.5rem;
	--ecomm-upcoming-programmes-title-weight: bold;

	--ecomm-upcoming-programmes-nav-bg: #{$msv-white};
	--ecomm-upcoming-programmes-nav-bg-hover: #{$msv-gray-300};
	--ecomm-upcoming-programmes-nav-border: 1px solid #{$msv-gray-300};
	--ecomm-upcoming-programmes-nav-colour: var(--brandPrimaryColor);
	--ecomm-upcoming-programmes-nav-size: .8rem;
	--ecomm-upcoming-programmes-nav-padding: .2rem 2rem;
	--ecomm-upcoming-programmes-nav-weight: #{$msv-font-weight-heavy};

	--ecomm-upcoming-programmes-carousel-li-padding: 0 .5rem;

	// MOBILE STYLINGS
	--ecomm-upcoming-programmes-top-padding-mobile: 0 1rem;

	--ecomm-upcoming-programmes-title-padding-mobile: 0;
	--ecomm-upcoming-programmes-title-size-mobile: 1rem;

	--ecomm-upcoming-programmes-nav-wrapper-padding-mobile: 0;
}

.ecomm-upcoming-programmes {
	background-repeat: no-repeat;
	background-size: cover;
	padding: var(--ecomm-upcoming-programmes-padding);

	> .top {
		@include breakpoint($msv-breakpoint-l) {
			padding: var(--ecomm-upcoming-programmes-top-padding-mobile);
		}

		color: var(--ecomm-upcoming-programmes-top-colour);
		margin: var(--ecomm-upcoming-programmes-top-margin);
		padding: var(--ecomm-upcoming-programmes-top-padding);

		> .title {
			@include breakpoint($msv-breakpoint-l) {
				font-size: var(--ecomm-upcoming-programmes-title-size-mobile);
				padding: var(--ecomm-upcoming-programmes-title-padding-mobile);
			}

			font-size: var(--ecomm-upcoming-programmes-title-size);
			font-weight: var(--ecomm-upcoming-programmes-title-weight);
		}

		> .nav {
			@include display-flex;
			@include justify-content(end);

			@include breakpoint($msv-breakpoint-l) {
				justify-content: start;
				padding: var(--ecomm-upcoming-programmes-nav-wrapper-padding-mobile);
			}

			> a {
				background-color: var(--ecomm-upcoming-programmes-nav-bg);
				border: var(--ecomm-upcoming-programmes-nav-border);
				color: var(--ecomm-upcoming-programmes-nav-colour);
				font-size: var(--ecomm-upcoming-programmes-nav-size);
				font-weight: var(--ecomm-upcoming-programmes-nav-weight);
				padding: var(--ecomm-upcoming-programmes-nav-padding);

				&:hover {
					background-color: var(--ecomm-upcoming-programmes-nav-bg-hover);
					color: var(--ecomm-upcoming-programmes-nav-colour);
					text-decoration: none;
				}
			}
		}
	}

	> .react-multi-carousel-list {
		> ul {
			> li {
				padding: var(--ecomm-upcoming-programmes-carousel-li-padding);
			}
		}
	}
}
