:root {
  --ecomm-button-bg: var(--brandPrimaryColor);
  --ecomm-button-colour: #{$msv-white};
  --ecomm-button-padding: .5rem 2rem;
}

.ecomm-button {
  background-color: var(--ecomm-button-bg);
  display: inline-block;
  padding: var(--ecomm-button-padding);

  > a {
    color: var(--ecomm-button-colour);

    &:hover { 
      color: var(--ecomm-button-colour);
      text-decoration: none;
    }
  }
}