:root {
  --hero-banner-action-button-margin: 0 1rem 0 0;
  --hero-banner-action-button-padding: .5rem 2rem;
  --hero-banner-action-margin: 3rem 0 0 0;

  --hero-banner-button-bg-first: var(--brandPrimaryColor);
  --hero-banner-button-colour-first: var(--brandTextColour);

  --hero-banner-button-bg-second: #{$msv-white};
  --hero-banner-button-border-second: #{$msv-gray-300};
  --hero-banner-button-colour-second: var(--brandPrimaryColor);

  --hero-banner-content-padding: 1rem;
  --hero-banner-content-padding-mobile: 0;
  --hero-banner-content-width: 50%;

  --hero-banner-description-font-colour: #{$msv-gray-900};

  --hero-banner-min-height: 40rem;
  --hero-banner-min-width: 100%;

  --hero-banner-padding: 4rem;

  --hero-banner-title-font-colour: var(--brandPrimaryColor);
  --hero-banner-title-margin: 0 0 1rem 0;

  --hero-banner-mobile-height: 10rem;
  --hero-banner-mobile-padding: 0;

  --hero-banner-normal-border-radius: 1rem;
  --hero-banner-normal-colour-caption: #{$msv-white};
  --hero-banner-normal-colour-title: var(--brandTextColour);
  --hero-banner-normal-height: 2rem;
  --hero-banner-normal-padding: 3rem;
  --hero-banner-normal-padding-content: 0;
  --hero-banner-normal-width: 100%;
}

.ecomm-hero-banner {
  @include breakpoint($msv-breakpoint-l) {
    display: block;
    min-height: var(--hero-banner-mobile-height);
    padding: var(--hero-banner-mobile-padding);
  }

  @include display-flex;
  @include align-items(center);
  
  background-repeat: no-repeat;
  background-size: cover;
  min-height: var(--hero-banner-min-height);
  min-width: var(--hero-banner-min-width);
  padding: var(--hero-banner-padding);

  &.right { @include justify-content(end); }

  &-content {
    @include breakpoint($msv-breakpoint-l) { padding: var(--hero-banner-content-padding-mobile); }

    padding: var(--hero-banner-content-padding);
    width: var(--hero-banner-content-width);

    .title { 
      margin: var(--hero-banner-title-margin);

      .enable-primary-colour { color: var(--hero-banner-title-font-colour); }
    }

    .description { color: var(--hero-banner-description-font-colour); }
  }

  &-actions { 
    margin: var(--hero-banner-action-margin);

    a {
      margin: var(--hero-banner-action-button-margin);
      padding: var(--hero-banner-action-button-padding);

      &:hover { text-decoration: none; }

      &.first-button {
        background-color: var(--hero-banner-button-bg-first);
        color: var(--hero-banner-button-colour-first);
      }

      &.second-button {
        background-color: var(--hero-banner-button-bg-second);
        border: 1px solid var(--hero-banner-button-border-second);
        color: var(--hero-banner-button-colour-second);
      }
    }
  }

  &.normal {
    @include breakpoint($msv-breakpoint-l) {
      border-radius: var(--hero-banner-normal-border-radius);
      min-height: var(--hero-banner-normal-height);
      padding: var(--hero-banner-normal-padding);

      &.right { text-align: end; }

      > .ecomm-hero-banner-content { 
        background-color: transparent;
        padding: var(--hero-banner-normal-padding-content);
        width: var(--hero-banner-normal-width);
        
        > a { 
          display: block; 

          > .caption { color: var(--hero-banner-normal-colour-caption); }
          > .title { color: var(--hero-banner-normal-colour-title); }

          &:hover { text-decoration: none; }
          &:visited { text-decoration: none; }
        }
      }
    }
  }
}