$filter-padding-lr: calc(2rem + 15px);

:root {
  --product-slider-button-filter-bg: var(--brandSecondaryColor);

  --product-slider-button-filter-border: none;

  --product-slider-button-filter-colour: var(--brandTextColour);

  --product-slider-button-filter-container-margin: 0 0 1.5rem 0;
  --product-slider-button-filter-container-padding: 0 #{$filter-padding-lr};

  --product-slider-button-filter-font-weight: #{$msv-font-weight-heavy};

  --product-slider-button-filter-height: 2rem;
  --product-slider-button-filter-margin: 0 .5rem .5rem 0;
  --product-slider-button-filter-padding: 0 30px;
}

.ecomm-product-slider-categories {
  margin: var(--product-slider-button-filter-container-margin);
  padding: var(--product-slider-button-filter-container-padding);

  button {
    background-color: var(--product-slider-button-filter-bg);
    border: var(--product-slider-button-filter-border);
    color: var(--product-slider-button-filter-colour);
    font-weight: var(--product-slider-button-filter-font-weight);
    height: var(--product-slider-button-filter-height);
    margin: var(--product-slider-button-filter-margin);
    padding: var(--product-slider-button-filter-padding);
  }    
}