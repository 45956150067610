:root {
  --ecomm-mobile-dd-btn-bg: $msv-white;
  --ecomm-mobile-dd-btn-border-colour: #{$msv-gray-300};
  --ecomm-mobile-dd-btn-colour: var(--brandSecondaryColor);
  --ecomm-mobile-dd-btn-margin-left: auto;
  --ecomm-mobile-dd-btn-radius: 0;
  --ecomm-mobile-dd-btn-width: 100%;

  --ecomm-mobile-dd-border-colour: #{$msv-gray-300};
  --ecomm-mobile-dd-radius: 0;
  --ecomm-mobile-dd-width: 100%;

  --ecomm-mobile-dd-item-colour: var(--brandSecondaryColor);
}

.mobile {
  > .dropdown {
    > button.dropdown-toggle {
      @include display-flex;
      @include align-items(center);
      
      background-color: var(--ecomm-mobile-dd-btn-bg);
      border-color: var(--ecomm-mobile-dd-btn-border-colour);
      border-radius: var(--ecomm-mobile-dd-btn-radius);
      color: var(--ecomm-mobile-dd-btn-colour);
      width: var(--ecomm-mobile-dd-btn-width);

      &:focus { box-shadow: none; }

      &::after { margin-left: var(--ecomm-mobile-dd-btn-margin-left); }
    }
    
    > div.dropdown-menu {
      border-color: var(--ecomm-mobile-dd-border-colour);
      border-radius: var(--ecomm-mobile-dd-radius);
      width: var(--ecomm-mobile-dd-width);

      > a.dropdown-item { color: var(--ecomm-mobile-dd-item-colour); }
    }
  }
}