:root {
	--ecomm-cart-summary-bg: #{$msv-red};

	--ecomm-cart-summary-link-colour: var(--brandPrimaryColor);
    --ecomm-cart-summary-text-colour: var(--brandPrimaryColor);
}

.breadcrumb {
    color: var(--ecomm-cart-summary-link-colour);
    background-color: transparent;
    padding: 0;

    &-current {
        font-weight: bold;
    }

    &-divider {
        @include add-icon($msv-ChevronRight, after, inline);
        margin-left: 1rem;
        margin-right: 1rem;
    }

    &-divider::after {
        font-size: 14px;
        font-weight: 500;
    }

    a {
        color: var(--ecomm-cart-summary-link-colour);
    }

    a:hover {
        text-decoration: none;
    }

    a:visited {
        color: var(--ecomm-cart-summary-link-colour);
    }
}