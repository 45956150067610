:root {
    --ecomm-order-content-padding-left: 0;
    --ecomm-order-content-padding-top: 5rem;
    --ecomm-order-content-padding-bottom: 2rem;
    --ecomm-order-header-font-size: 40px;
    --ecomm-order-header-font-weight: 500;
    --ecomm-order-header-color: var(--brandPrimaryColor);
    --ecomm-order-header-padding-bottom: 2rem;
    --ecomm-order-message-font-size: 18px;
    --ecomm-order-message-color: #{$msv-black};
    --ecomm-order-message-padding-bottom: 2rem;
    --ecomm-order-button-background-color: transparent;
    --ecomm-order-button-color: var(--brandPrimaryColor);
    --ecomm-order-button-font-size: 16px;
    --ecomm-order-button-font-weight: bold;
    --ecomm-order-button-border: 1px solid #{$msv-gray-300};
    --ecomm-order-button-padding: 1rem 2rem;
    --ecomm-order-button-download-margin-right: 2rem;
    --ecomm-order-button-download-margin-bottom: 1rem;
    --ecomm-order-button-download-icon-margin-right: 5px;
    --ecomm-order-summary-padding: 2.5rem 3rem;
    --ecomm-order-summary-background-color: var(--brandPrimaryColor);
    --ecomm-order-summary-header-font-size: 24px;
    --ecomm-order-summary-header-font-weight: 500;
    --ecomm-order-summary-header-color: #{$msv-yellow};
    --ecomm-order-summary-header-padding-bottom: 2rem;
    --ecomm-order-summary-item-padding-top: 1rem;
    --ecomm-order-summary-item-image-height: 108px;
    --ecomm-order-summary-item-image-border: 1px solid #{$msv-gray-300};
    --ecomm-order-summary-item-content-margin-left: 1rem;
    --ecomm-order-summary-item-content-color: #{$msv-yellow};
    --ecomm-order-summary-item-content-name-font-size: 16px;
    --ecomm-order-summary-item-content-price-font-size: 24px;
    --ecomm-order-summary-item-content-price-font-weight: 500;
    --ecomm-order-summary-divider-border: 1px solid #{$msv-yellow};
    --ecomm-order-summary-divider-margin: 3rem 0 1rem 0;

    --ecomm-order-total-font-size: 24px;
    --ecomm-order-total-font-weight: 500;
    --ecomm-order-total-color: #{$msv-yellow};
    --ecomm-order-total-padding-bottom: 2rem;

    --ecomm-order-confirm-overlay-bg: #{$msv-white};
}

.overlay-content {
  align-items: center;
  background-color: var(--ecomm-order-confirm-overlay-bg);
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  width: 100%;
}

.overlay-spinner {
  color: var(--ecomm-order-summary-background-color);
  margin-bottom: 2rem;
}

.overlay-text {
  color: var(--ecomm-order-message-color);
  font-weight: 1000
}

.ecomm-order-confirmation {
  >.content {
    padding-left: var(--ecomm-order-content-padding-left);
    padding-top: var(--ecomm-order-content-padding-top);
    padding-bottom: var(--ecomm-order-content-padding-bottom);

    p.header {
      font-size: var(--ecomm-order-header-font-size);
      font-weight: var(--ecomm-order-header-font-weight);
      color: var(--ecomm-order-header-color);
      padding-bottom: var(--ecomm-order-header-padding-bottom);
    }

    p.message {
      font-size: var(--ecomm-order-message-font-size);
      color: var(--ecomm-order-message-color);
      padding-bottom: var(--ecomm-order-message-padding-bottom);
    }

    button {
      background-color: var(--ecomm-order-button-background-color);
      color: var(--ecomm-order-button-color);
      font-size: var(--ecomm-order-button-font-size);
      font-weight: var(--ecomm-order-button-font-weight);
      border: var(--ecomm-order-button-border);
      padding: var(--ecomm-order-button-padding);
    }
    
    .download {
      @include add-icon($msv-pdf, before, inline);

      margin-right: var(--ecomm-order-button-download-margin-right);
      margin-bottom: var(--ecomm-order-button-download-margin-bottom);

      &::before { margin-right: var(--ecomm-order-button-download-icon-margin-right); }
    }
  }
}