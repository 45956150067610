:root {
	--ecomm-order-summary-bg: var(--brandPrimaryColor);
  --ecomm-order-summary-height: 40vh;
  --ecomm-order-summary-padding: 1.5rem;

  --ecomm-order-summary-header-margin: 0 0 1rem 0;

  --ecomm-order-summary-item-margin: 0 0 1rem 0;
  --ecomm-order-summary-item-weight: #{$msv-font-weight-light};

  --ecomm-order-summary-product-padding: 0 1rem 0 0;  

  --ecomm-order-summary-tax-weight: #{$msv-font-weight-light};

  --ecomm-order-summary-info-padding: 0;

  --ecomm-order-summary-divider-border: 1px solid var(--brandTextColour);
  --ecomm-order-summary-divider-margin: 3rem 0 1rem 0;

  --ecomm-order-summary-total-wrapper-width: 100%;

  --ecomm-order-summary-total-size: 1.2rem;
  --ecomm-order-summary-total-weight: bold;

  --ecomm-order-summary-total-price-right: 0;

  --ecomm-order-summary-action-bg: var(--brandPrimaryColor);
	--ecomm-order-summary-action-border: 1px solid var(--brandTextColour);
	--ecomm-order-summary-action-colour: var(--brandTextColour);
  --ecomm-order-summary-action-margin: 1rem 0 0 0;
  --ecomm-order-summary-action-padding: .5rem;
  --ecomm-order-summary-action-weight: #{$msv-font-weight-heavy};
  --ecomm-order-summary-action-width: 100%;

  // MOBILE STYLINGS
  --ecomm-order-summary-height-mobile: 1vh;
  --ecomm-order-summary-padding-mobile: 0;

  --ecomm-order-summary-total-price-left-mobile: 0;

  --ecomm-order-summary-banner-bg: var(--brandPrimaryColor);
  --ecomm-order-summary-banner-padding: 1rem;

  --ecomm-order-summary-toggle-bg: var(--brandPrimaryColor);
  --ecomm-order-summary-toggle-border: 1px solid var(--brandTextColour);
  --ecomm-order-summary-toggle-colour: var(--brandTextColour);
  --ecomm-order-summary-toggle-padding: .5rem;

  --ecomm-order-summary-backdrop-bg: rgba(0, 0, 0, .3);
  --ecomm-order-summary-backdrop-left: 0;
  --ecomm-order-summary-backdrop-top: 0;
  --ecomm-order-summary-backdrop-z: 99999;

  --ecomm-order-summary-panel-height: 100%;

  --ecomm-order-summary-content-bg: var(--brandPrimaryColor);
  --ecomm-order-summary-content-height: 100%;
  --ecomm-order-summary-content-padding: 1rem;

  --ecomm-order-summary-header-margin: 0 0 .5rem 0;
  --ecomm-order-summary-header-size: .8rem;
  --ecomm-order-summary-header-weight: bold;

  --ecomm-order-summary-item-size-mobile: .6rem; 
  --ecomm-order-summary-item-margin-mobile: 0 0 .5rem 0;

  --ecomm-order-summary-product-padding: 0 .5rem 0 0;

  --ecomm-order-summary-divider-margin-mobile: 1rem 0;
  --header-footer-btn-opacity: .7;

  --ecomm-order-summary-insurance-font-size: 0.8rem;
  --ecom-order-summary-insurance-font-style: italic;
  --ecomm-order-summary-insurance-font-size-mobile: 0.5rem;
}

.ecomm-order-summary {
  background-color: var(--ecomm-order-summary-bg);
  min-height: var(--ecomm-order-summary-height);
  padding: var(--ecomm-order-summary-padding);

  h4 { margin: var(--ecomm-order-summary-header-margin); }

  > .line {
    &-item {
      font-weight: var(--ecomm-order-summary-item-weight);
      margin: var(--ecomm-order-summary-item-margin);

      > .product { 
        padding: var(--ecomm-order-summary-product-padding);
        word-wrap: break-word; 
        >.insurance {
          font-size: var(--ecomm-order-summary-insurance-font-size);
          font-style: var(--ecom-order-summary-insurance-font-style);
          padding: var(--ecomm-order-summary-product-padding);
      }
      }
    }

    &-tax { font-weight: var(--ecomm-order-summary-tax-weight); }
    
    &-end {
      border-top: var(--ecomm-order-summary-divider-border);
      margin: var(--ecomm-order-summary-divider-margin);
    }
  }

  .information { 
    padding: var(--ecomm-order-summary-info-padding);
    text-align: right; 
  }

  > .action {
    background-color: var(--ecomm-order-summary-action-bg);
    border: var(--ecomm-order-summary-action-border);
    color: var(--ecomm-order-summary-action-colour);
    font-weight: var(--ecomm-order-summary-action-weight);
    margin: var(--ecomm-order-summary-action-margin);
    padding: var(--ecomm-order-summary-action-padding);
    text-align: center;
    width: var(--ecomm-order-summary-action-width);
    &:disabled {
      cursor: not-allowed;
      opacity: var(--header-footer-btn-opacity);
    }
  }

  .msc {
    &-order-summary {
      font-size: var(--ecomm-order-summary-total-size);
      font-weight: var(--ecomm-order-summary-total-weight);
      position: relative;
      width: var(--ecomm-order-summary-total-wrapper-width);
    }

    &-price {
      position: absolute;
      right: var(--ecomm-order-summary-total-price-right);
    }
  }

  &.mobile {
    background-color: transparent;
    min-height: var(--ecomm-order-summary-height-mobile);
    padding: var(--ecomm-order-summary-padding-mobile);

    > .banner {
      background-color: var(--ecomm-order-summary-banner-bg);
      background-size: cover;
      padding: var(--ecomm-order-summary-banner-padding);

      .msc {
        &-order-summary__label { display: block; }

        &-price { left: var(--ecomm-order-summary-total-price-left-mobile); }
      }

      > button.toggle {
        background-color: var(--ecomm-order-summary-toggle-bg);
        border: var(--ecomm-order-summary-toggle-border);
        color: var(--ecomm-order-summary-toggle-colour);
        padding: var(--ecomm-order-summary-toggle-padding);
      }
    }

    .sliding-panel-container.active {
      background-color: var(--ecomm-order-summary-backdrop-bg);
      background-size: contain;
      left: var(--ecomm-order-summary-backdrop-left);
      position: fixed;
      top: var(--ecomm-order-summary-backdrop-top);
      z-index: var(--ecomm-order-summary-backdrop-z);

      .panel-content {
        height: var(--ecomm-order-summary-panel-height);
        pointer-events: all;
  
        > .content {
          background-color: var(--ecomm-order-summary-content-bg);
          background-size: contain;
          font-size: var(--ecomm-order-summary-item-size-mobile);
          font-weight: var(--ecomm-order-summary-item-weight);
          height: var(--ecomm-order-summary-content-height);
          padding: var(--ecomm-order-summary-content-padding);

          > .header {
            font-size: var(--ecomm-order-summary-header-size);
            font-weight: var(--ecomm-order-summary-header-weight);
            margin: var(--ecomm-order-summary-header-margin);
          }

          > .line-item {
            @include align-items(center);
            
            margin: var(--ecomm-order-summary-item-margin-mobile);

            > .product { 
              line-height: normal;
              padding: var(--ecomm-order-summary-product-padding);
              word-wrap: break-word; 

              >.insurance {
                font-size: var(--ecomm-order-summary-insurance-font-size-mobile);
                font-style: var(--ecom-order-summary-insurance-font-style);
                padding: var(--ecomm-order-summary-product-padding);
            }
            }
          }

          > .line-end {
            border-top: var(--ecomm-order-summary-divider-border);
            margin: var(--ecomm-order-summary-divider-margin-mobile);
          }
        }
      }
    }
  }
}