:root {
  --ecomm-sandbox-text-colour: var(--msv-font-primary-color);
  --ecomm-sandbox-primary-colour: var(--msv-accent-brand-color);
  --ecomm-sandbox-secondary-colour: var(--msv-accent-brand-alt-color);

  --ecomm-sandbox-text: #FFFFAC;
  --ecomm-sandbox-primary: #901C32;
  --ecomm-sandbox-secondary: #08493F;
}

.ecomm-sandbox {
  &-text { 
    color: var(--ecomm-sandbox-text-colour);

    strong { color: var(--ecomm-sandbox-text); }
  }

  &-primary { 
    color: var(--ecomm-sandbox-primary-colour);
    
    strong { color: var(--ecomm-sandbox-primary); }
  }

  &-secondary {
    color: var(--ecomm-sandbox-secondary-colour);

    strong { color: var(--ecomm-sandbox-secondary); }
  }
}