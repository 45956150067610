:root {
  --ecomm-history-card-color: #333333;
  --card-width: 100%;
  --card-border: 1px solid #C7C7CC;
  --card-padding: 1.5rem 0.5rem;
  --card-margin-bottom: 1.5rem;

  --ecomm-history-card-product-margin: 0 0 .5rem 0;
  
  --product-category-font-size: 13px;
  --product-category-font-weight: bold;
  --product-category-color: var(--brandPrimaryColor);

  --product-name-font-size: 16px;
  --product-name-font-weight: bold;

  --product-serial-font-size: 14px;
  --product-serial-font-weight: 500;

  --product-event-font-size: 16px;
  --product-event-font-weight: bold;

  --product-price-font-size: 20px;
  --product-price-font-weight: bold;

  --content-font-size: 14px;
  --content-date-color: #6A6A6A;
  --ecomm-history-card-content-date-margin: .5rem 0 0 0;

  --hr-border-color: #C7C7CC;
  --hr-margin: 0.5rem 1rem;

  --receipt-font-size: 14px;
  --receipt-title-font-weight: 500;
}

.ecomm-history-card {
  color: var(--ecomm-history-card-color);
  width: var(--card-width);
  border: var(--card-border);
  padding: var(--card-padding);
  margin-bottom: var(--card-margin-bottom);

  &-product {
    margin: var(--ecomm-history-card-product-margin);

    &-category {
      font-size: var(--product-category-font-size);
      font-weight: var(--product-category-font-weight);
      color: var(--product-category-color);
    }

    &-name {
      font-size: var(--product-name-font-size);
      font-weight: var(--product-name-font-weight);
    }

    &-serial {
      font-size: var(--product-serial-font-size);
      font-weight: var(--product-serial-font-weight);
    }

    &-events {
      font-size: var(--product-event-font-size);
      font-weight: var(--product-event-font-weight);
    }

    &-price {
      font-size: var(--product-price-font-size);
      font-weight: var(--product-price-font-weight);

      p {
        float: right;
      }
    }
  }

  &-content {
    font-size: var(--content-font-size);

    &-date {
      color: var(--content-date-color);
      margin: var(--ecomm-history-card-content-date-margin);
    }

    &-price {
      font-size: var(--product-price-font-size);
      font-weight: var(--product-price-font-weight);
    }
  }

  hr {
    border-color: var(--hr-border-color);
    margin: var(--hr-margin);
  }

  &-receipt {
    font-size: var(--receipt-font-size);

    &-title {
      font-weight: var(--receipt-title-font-weight);
    }
  }
}