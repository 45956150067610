:root {
  --ecomm-report-navi-bg: #{$clear-day};
  --ecomm-report-navi-bg-active: var(--brandSecondaryColor);
  
  --ecomm-report-navi-font-colour: #{$msv-gray-900};
  --ecomm-report-navi-font-colour-active: var(--brandTextColour);
  --ecomm-report-navi-font-weight: bold;

  --ecomm-report-navi-padding: 10px;
  --ecomm-report-navi-width: 100%;
}

.ecomm-navi {
  button {
    background-color: var(--ecomm-report-navi-bg);
    border: none;
    color: var(--ecomm-report-navi-font-colour);
    display: block;
    font-weight: var(--ecomm-report-navi-font-weight);
    padding: var(--ecomm-report-navi-padding);
    text-align: left;
    text-decoration: none;
    width: var(--ecomm-report-navi-width);

    &.active {
      background-color: var(--ecomm-report-navi-bg-active);
      color: var(--ecomm-report-navi-font-colour-active);
    }
  }
}