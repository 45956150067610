$ecomm-separator-margin: 20px 0;
$ecomm-separator-width: 100%;

:root {
  --separator-colour: #{$msv-gray-300};
}

.ecomm-separator {
  border-top: 1px solid var(--separator-colour);
  margin: #{$ecomm-separator-margin};
  width: #{$ecomm-separator-width};
}