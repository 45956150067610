:root {
	--product-slider-card-padding-mobile: 0 .15rem;

	--product-slider-card-action-button-bg: var(--brandPrimaryColor);
	--product-slider-card-action-button-font-colour: var(--brandTextColour);
	--product-slider-card-action-button-font-size: .8rem;
	--product-slider-card-action-button-padding: .3rem .8rem;

	--product-slider-card-img-height: 10rem;

	--product-slider-card-info-category-font-colour: #{$msv-gray-400};

	--product-slider-card-info-container-font-colour: var(--brandPrimaryColor);
	--product-slider-card-info-container-font-size: .8rem;
	--product-slider-card-info-container-font-weight: #{$msv-font-weight-heavy};
	--product-slider-card-info-container-height: 80px;
	--product-slider-card-info-container-max-width: 10rem;

	--product-slider-card-info-description-font-colour: #{$msv-black};
	--product-slider-card-info-description-margin: 5px 0 0 0;
	--product-slider-card-info-description-font-weight: normal;

  // MOBILE STYLINGS 
  --product-slider-card-info-container-height-mobile: auto;
}

.ecomm-product-slider-card {
	@include breakpoint($msv-breakpoint-l) {
		padding: var(--product-slider-card-padding-mobile);
	}

	.img-container {
		@include display-flex;
		@include justify-content(center);

		img {
			height: var(--product-slider-card-img-height);
			object-fit: contain;
		}

		.msc-empty_image {
			height: var(--product-slider-card-img-height);
		}
	}

	.info-container {
		@include breakpoint($msv-breakpoint-l) { height: var(--product-slider-card-info-container-height-mobile); }

		color: var(--product-slider-card-info-container-font-colour);
		font-size: var(--product-slider-card-info-container-font-size);
		font-weight: var(--product-slider-card-info-container-font-weight);
    height: var(--product-slider-card-info-container-height);
		max-width: var(--product-slider-card-info-container-max-width);

		.info-category {
			color: var(--product-slider-card-info-category-font-colour);
			font-weight: normal;
		}

		.info-description {
			color: var(--product-slider-card-info-description-font-colour);
			font-weight: var(--product-slider-card-info-description-font-weight);
			margin: var(--product-slider-card-info-description-margin);
		}
	}

	.action-container {
		@include display-flex;
		@include justify-content(end);

		button {
			background-color: var(--product-slider-card-action-button-bg);
			border: none;
			color: var(--product-slider-card-action-button-font-colour);
			font-size: var(--product-slider-card-action-button-font-size);
			padding: var(--product-slider-card-action-button-padding);
		}
	}
}
