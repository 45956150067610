:root {
  --ecomm-event-products-grid-gap: 1rem;
  --ecomm-event-products-margin: 0;
  --ecomm-event-products-padding: 0;
}

.ecomm-event-products {
  ul {
    @include breakpoint($msv-breakpoint-l) { grid-template-columns: auto auto; }

    display: grid;
    grid-gap: var(--ecomm-event-products-grid-gap);
    grid-template-columns: auto auto auto auto;
    list-style-type: none;
    margin: var(--ecomm-event-products-margin);
    padding: var(--ecomm-event-products-padding);
  }
}