:root {
  --ecomm-col-display-padding: 0 0 0 1rem;
  --ecomm-col-report-padding: 0 1rem 0 0;

  --ecomm-display-height: 100vh;
  --ecomm-display-width: 100%;
}

.ecomm-annual-reports {
  .report-navi {
    padding: var(--ecomm-col-report-padding);
  }

  .report-display {
    padding: var(--ecomm-col-display-padding);

    iframe {
      border: none;
      height: var(--ecomm-display-height);
      width: var(--ecomm-display-width);
    }
  }
}