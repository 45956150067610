:root {
  --user-profile-colour: #{$msv-black};
  --user-profile-padding: 2rem 10rem;

  --user-profile-header-margin: 0 0 2rem 0;

  --user-profile-saved-colour: #{$msv-green};
  --user-profile-saved-margin: 0 0 0 .5rem;

  --user-profile-div-margin: 0 0 1rem 0;

  --user-profile-left-padding: 0 12px 0 0;
  --user-profile-right-padding: 0 0 0 12px;

  --user-profile-loading-bg: rgba(107, 114, 122, 0.3);
  --user-profile-loading-height: 100%;
  --user-profile-loading-width: 100%;
  --user-profile-loading-z: 99;

  --user-profile-mandatory-colour: #{$msv-red};

  --user-profile-input-border: 1px solid #{$msv-gray-300};
  --user-profile-input-height: 2rem;
  --user-profile-input-padding: 0 .5rem;
  --user-profile-input-width: 100%;

  --user-profile-details-margin: 0 0 1rem 0;

  --user-profile-email-colour: #{$msv-gray-300};
  --user-profile-email-weight: bold;

  --user-profile-pw-colour: var(--brandPrimaryColor);
  --user-profile-pw-weight: bold;

  --user-profile-pw-icon-margin: 0 0 0 .5rem;

  --user-profile-address-border: 1px solid #{$msv-gray-300};
  --user-profile-address-margin: 0 0 1rem 0;
  --user-profile-address-padding: 1rem 0;

  --user-profile-address-header-margin: 0 0 1rem 0;
  --user-profile-address-header-padding: 0;
  --user-profile-address-header-weight: bold;
  --user-profile-address-header-width: 100%;

  --user-profile-pdpa-margin: 0 0 2rem 0;

  --user-profile-pdpa-header-margin: 1rem 0;
  --user-profile-pdpa-header-weight: bold;

  --user-profile-pdpa-label-colour: var(--brandPrimaryColor);
  --user-profile-pdpa-label-margin: 0 0 0 .5rem;
  --user-profile-pdpa-label-weight: bold;

  --user-profile-action-padding: .5rem 1rem;
  --user-profile-action-weight: bold;
  --user-profile-action-width: 12rem;

  --user-profile-discard-bg: #{$msv-white};
  --user-profile-discard-border: 1px solid #{$msv-gray-300};
  --user-profile-discard-colour: var(--brandPrimaryColor);
  --user-profile-discard-margin: 0 1rem 0 0;

  --user-profile-submit-bg: var(--brandPrimaryColor);
  --user-profile-submit-colour: var(--brandTextColour);

  // MOBILE STYLINGS
  --user-profile-padding-mobile: 2rem 1rem;

  --user-profile-details-padding-mobile: 0;

  --user-profile-submit-margin-mobile: 0 0 1rem 0;
}

.ecomm-user-profile {
  @include breakpoint($msv-breakpoint-l) { padding: var(--user-profile-padding-mobile); }
  
  @include display-flex;
  @include justify-content(center);

  @include align-items(center);
  color: var(--user-profile-colour);
  padding: var(--user-profile-padding);
  position: relative;

  > .loading {
    background-color: var(--user-profile-loading-bg);
    height: var(--user-profile-loading-height);
    position: absolute;
    width: var(--user-profile-loading-width);
    z-index: var(--user-profile-loading-z);
  }

  > .form-header { 
    margin: var(--user-profile-header-margin); 

    &.saved {
      @include add-icon($msv-Checkbox-Circle-Checked, after);

      &::after {
        color: var(--user-profile-saved-colour);
        margin: var(--user-profile-saved-margin);
      }
    }
  }

  > form {
    > .details, .address {
      label { 
        @include ellipsis;
        
        display: block; 

        &.mandatory {
          &::after {
            color: var(--user-profile-mandatory-colour);
            content: '*';
          }
        }
      }

      input { 
        border: var(--user-profile-input-border);
        height: var(--user-profile-input-height);
        padding: var(--user-profile-input-padding);
        width: var(--user-profile-input-width); 
      }

      > div { margin: var(--user-profile-div-margin); }
    }

    > .details {
      margin: var(--user-profile-details-margin);

      > div:nth-child(odd) { 
        @include breakpoint($msv-breakpoint-l) { padding: var(--user-profile-details-padding-mobile); }

        padding: var(--user-profile-left-padding); 
      }

      > div:nth-child(even) { 
        @include breakpoint($msv-breakpoint-l) { padding: var(--user-profile-details-padding-mobile); }

        padding: var(--user-profile-right-padding); 
      }

      .email {
        color: var(--user-profile-email-colour);
        font-weight: var(--user-profile-email-weight);
      }

      > .change-password {
        @include display-flex;
        @include align-items(center);

        > a {
          @include add-icon($msv-ChevronRight, after);

          @include display-flex;
          @include align-items(center);

          color: var(--user-profile-pw-colour);
          font-weight: var(--user-profile-pw-weight);

          &:hover {
            color: var(--user-profile-pw-colour);
            text-decoration: none;
          }

          &::after { margin: var(--user-profile-pw-icon-margin); }
        }
      }
    }

    > .address {
      border-bottom: var(--user-profile-address-border);
      border-top: var(--user-profile-address-border);
      margin: var(--user-profile-address-margin);
      padding: var(--user-profile-address-padding);

      > .address-header {
        font-weight: var(--user-profile-address-header-weight);
        margin: var(--user-profile-address-header-margin);
        padding: var(--user-profile-address-header-padding);
        width: var(--user-profile-address-header-width);
      }

      > div:nth-child(even) { 
        @include breakpoint($msv-breakpoint-l) { padding: var(--user-profile-details-padding-mobile); }

        padding: var(--user-profile-left-padding); 
      }

      > div:nth-child(odd) { 
        @include breakpoint($msv-breakpoint-l) { padding: var(--user-profile-details-padding-mobile); }

        padding: var(--user-profile-right-padding); 
      }
    }

    > .pdpa {
      margin: var(--user-profile-pdpa-margin);

      > .pdpa-header {
        font-weight: var(--user-profile-pdpa-header-weight);
        margin: var(--user-profile-pdpa-header-margin);
      }

      label { 
        color: var(--user-profile-pdpa-label-colour);
        font-weight: var(--user-profile-pdpa-label-weight);
        margin: var(--user-profile-pdpa-label-margin);
      }
    }

    > .actions {
      > div {
        @include display-flex;
        @include justify-content(center);
      }

      button {
        font-weight: var(--user-profile-action-weight);
        min-width: var(--user-profile-action-width);
        padding: var(--user-profile-action-padding);
      }

      .submit {
        @include breakpoint($msv-breakpoint-l) { margin: var(--user-profile-submit-margin-mobile); }

        background-color: var(--user-profile-submit-bg);
        color: var(--user-profile-submit-colour);
        border: none;
      }

      .cancel {
        background-color: var(--user-profile-discard-bg);
        border: var(--user-profile-discard-border);
        color: var(--user-profile-discard-colour);
      }
    }
  }
}