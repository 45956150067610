:root {
    --mobile-stream-player-wrapper-height: auto;
    --mobile-stream-player-wrapper-width: auto;
    --mobile-stream-react-player-padding-top: 56.25%; // Percentage ratio for 16:9.
    --mobile-stream-video-top: 0;
    --mobile-stream-frame-border: none;
    --mobile-stream-frame-height: 60vh;
    --mobile-stream-frame-width: 100%;
    --mobile-stream-selector-border: 1px solid #{$msv-gray-300};
    --mobile-stream-selector-colour: var(--brandPrimaryColor);
    --mobile-stream-selector-font-weight: #{$msv-font-weight-bold};
}

.ecomm-live-stream-mobile {
    .mobile-floor-selector {
        >span+div {
            border: none;
            box-shadow: none;
            &:hover {
                box-shadow: none;
            }
            >div {
                font-weight: var(--mobile-stream-selector-font-weight);
                span {
                    background-color: transparent;
                }
                >div {
                    color: var(--mobile-stream-selector-colour);
                }
            }
            &+div {
                border: var(--mobile-stream-selector-border);
                box-shadow: none;
                color: var(--mobile-stream-selector-colour);
            }
        }
    }
    .mobile-video {
        .player-wrapper {
            // height: var(--mobile-stream-player-wrapper-height);
            // width: var(--mobile-stream-player-wrapper-width);
            position: relative;
            overflow: hidden;
            width: 100%;
            padding-top: 56.25%;
            .player {
                // padding-top: var(--mobile-stream-react-player-padding-top);
                // position: relative;
                overflow: hidden;
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                width: 100%;
                height: 100%;
                >div {
                    position: absolute;
                    top: var(--mobile-stream-video-top);
                }
            }
        }
    }
    &-sutras {
        >iframe {
            border: var(--mobile-stream-frame-border);
            height: var(--mobile-stream-frame-height);
            width: var(--mobile-stream-frame-width);
        }
    }
}