:root {
    --ecomm-buybox-div-padding: 0;
    --ecomm-buybox-div-margin-first-mobile: 0 0 1rem 0;
    --ecomm-buybox-product-title-colour: var(--brandPrimaryColor);
    --ecomm-buybox-product-price-wrapper-margin: 1rem 0;
    --ecomm-buybox-product-price-colour: var(--brandPrimaryColor);
    --ecomm-buybox-product-price-margin: 0 .5rem 0 0;
    --ecomm-buybox-product-price-size: 1.8rem;
    --ecomm-buybox-product-prompt-colour: #{$msv-black};
    --ecomm-buybox-product-summary-colour: #{$msv-black};
    --ecomm-buybox-action-wrapper-margin: 2rem 0 0 0;
    --ecomm-buybox-action-bg: #{$msv-white};
    --ecomm-buybox-action-border: 1px solid #{$msv-gray-300};
    --ecomm-buybox-action-colour: var(--brandPrimaryColor);
    --ecomm-buybox-action-padding: .5rem 2rem;
    --ecomm-buybox-action-weight: bold;
}

.popup-card {
    background-color: #{$msv-white};
    text-align: center;
    max-width: 520px;
    padding: 1rem
}

.popup-header {
    color: var(--ecomm-buybox-product-title-colour);
    // font-size: var(--ecomm-buybox-product-price-size);
    font-weight: var(--ecomm-buybox-action-weight);
}

.popup-subtext {
    color: var(--ecomm-buybox-product-summary-colour);
    // font-size: var(--ecomm-buybox-product-price-size);
    // font-weight: var(--ecomm-buybox-action-weight);
    padding: 1rem 1rem;
}

.overlay-content {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background: #3a3937ef;
}

.signin-popup {
    color: var(--brandTextColor);
    background-color: var(--brandPrimaryColor);
    padding: .5rem 1rem;
    border: 1px solid #B2AEAE;
}

.dismiss-popup {
    color: var(--brandPrimaryColor);
    padding: .5rem 1rem;
    border: 1px solid #B2AEAE;
}

.ecomm-buybox.ms-buybox {
    @include breakpoint($msv-breakpoint-l) {
        >div {
            padding: var(--ecomm-buybox-div-padding);
            &:first-child {
                margin: var(--ecomm-buybox-div-margin-first-mobile);
            }
        }
    }
    >div {
        padding: var(--ecomm-buybox-div-padding);
    }
    >.ms-buybox__media-gallery {
        @include display-flex;
        @include justify-content(center);
    }
    >.ms-buybox__content {
        >.ms-buybox__product-title {
            color: var(--ecomm-buybox-product-title-colour);
        }
        >.ecomm-buybox-price-wrapper {
            @include display-flex;
            @include align-items(center);
            margin: var(--ecomm-buybox-product-price-wrapper-margin);
            >span.msc-price {
                margin: var(--ecomm-buybox-product-price-margin);
                position: inherit;
                >span.msc-price__actual {
                    color: var(--ecomm-buybox-product-price-colour);
                    font-size: var(--ecomm-buybox-product-price-size);
                }
            }
            >p {
                color: var(--ecomm-buybox-product-prompt-colour);
            }
        }
        >.ms-buybox__product-description {
            color: var(--ecomm-buybox-product-summary-colour);
        }
        >.ms-buybox__quantity {
            display: none;
        }
        >.ecomm-buybox-action-wrapper {
            margin: var(--ecomm-buybox-action-wrapper-margin);
            >button.ecomm-buybox-action {
                background-color: var(--ecomm-buybox-action-bg);
                border: var(--ecomm-buybox-action-border);
                color: var(--ecomm-buybox-action-colour);
                font-weight: var(--ecomm-buybox-action-weight);
                padding: var(--ecomm-buybox-action-padding);
            }
        }
    }
}