:root {
  --stream-mobile-banner-border-radius: 1rem;
  --stream-mobile-banner-padding: 3rem;
  --stream-mobile-banner-caption-colour: #{$msv-white};
}

.ecomm-mobile-stream-banner {
  border-radius: var(--stream-mobile-banner-border-radius);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: var(--stream-mobile-banner-padding);

  > .caption { color: var(--stream-mobile-banner-caption-colour); }

  &.right { text-align: end; }
}