:root {
  --msv-text-block-font-color: #{$msv-black};
  --msv-text-block-font-size-s: var(--msv-body-font-size-s);
  --msv-text-block-font-size-m: var(--msv-body-font-size-m);
  --msv-text-block-font-size-l: var(--msv-body-font-size-l);
  --msv-text-block-font-size-xl: var(--msv-body-font-size-xl);

  --ecomm-text-font-primary-colour: var(--brandPrimaryColor);

  --ecomm-page-title-margin: 1rem 0 0 0;
 }

.ecomm-page-title {
  color: var(--msv-text-block-font-color);
  line-height: normal;
  margin: var(--ecomm-page-title-margin);

  &.alignment__center { text-align: center; }

  &.alignment__left { text-align: left; }

  &.alignment__right { text-align: right; }

  &.enable-primary-colour { color: var(--ecomm-text-font-primary-colour); }

  &.fontsize__small { font-size: var(--msv-text-block-font-size-s); }

  &.fontsize__medium { font-size: var(--msv-text-block-font-size-m); }

  &.fontsize__large { font-size: var(--msv-text-block-font-size-l); }

  &.fontsize__extralarge { font-size: var(--msv-text-block-font-size-xl); }

  a {
    color: var(--msv-text-block-font-color);
    text-decoration: underline;
  }
}