.ms-social-share {
    margin-bottom: 20px;
    margin-top: 20px;
    width: auto;
    &-ul {
        @include display-flex;
        list-style: none;
        color: #9B3347;
    
        &.vertical {
            display: block;
    
            li {
                margin-bottom: 10px;
            }
        
            li:last-of-type {
                margin-bottom: 0;
            }
        }
    }

    &-item {
      @include display-flex;
      @include justify-content(center);
      @include align-items(center);

        width: 100%;
        height: 100%;  
        overflow: hidden;
    }

    &-li {
        margin: 0 5px;
        height: 20px;
        width: 20px;
        &:hover {
            cursor: pointer;
          }
    }

    &-ul {
        &.caption::before {
            content: attr(data-title);
            display: block;
            font-weight: bold;
            height: 30px;
            padding: 4px;
        }
    }
}