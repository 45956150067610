:root {
    --container-all-padding: 2rem 4rem;
    --container-horizontal-padding: 4rem;
    --container-horizontal-padding-mobile: 2rem;
    --container-vertical-padding: 2rem;
    --container-vertical-padding-mobile: 1rem;
    --margin-all: 1rem;
    --padding-column-left: 1rem;
    --padding-column-right: 1rem;
    --scroll-bar-width: 0;
    --header-top: 0;
    --header-z: 800;
}

html {
    scroll-behavior: smooth;
}

header {
      position: sticky;
      top: var(--header-top);
      z-index: var(--header-z);
}

body main {
    .margin--bottom {
        margin-bottom: var(--margin-all);
    }
    .margin--top {
        margin-top: var(--margin-all);
    }
    .margin--bottom-none {
        margin-bottom: 0;
    }
    .margin--top-none {
        margin-top: 0;
    }
    .container-padding--all {
        padding: var(--container-all-padding);
    }
    .container-padding--bottom {
        @include breakpoint($msv-breakpoint-l) {
            padding-bottom: var(--container-horizontal-padding-mobile);
        }
        padding-bottom: var(--container-vertical-padding);
    }
    .container-padding--left {
        padding-left: var(--container-horizontal-padding);
    }
    .container-padding--right {
        padding-right: var(--container-horizontal-padding);
    }
    .container-padding--top {
        @include breakpoint($msv-breakpoint-l) {
            padding-top: var(--container-horizontal-padding-mobile);
        }
        padding-top: var(--container-vertical-padding);
    }
    .container-padding--horizontal {
        @include breakpoint($msv-breakpoint-l) {
            padding-left: var(--container-horizontal-padding-mobile);
            padding-right: var(--container-horizontal-padding-mobile);
        }
        padding-left: var(--container-horizontal-padding);
        padding-right: var(--container-horizontal-padding);
    }
    .padding-column--left {
        padding-left: var(--padding-column-left);
    }
    .padding-column--right {
        padding-right: var(--padding-column-right);
    }
}

::-webkit-scrollbar {
      background: transparent;
      width: var(--scroll-bar-width);
}

.overlay-content {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background: #edebe9ef;
}

// body { height: 800vh; }