// Import bootstrap css
@import "bootstrap/scss/bootstrap";
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/grid";
@import "../00-settings/responsive-breakpoints";

:root {
  --bootstrap-col-padding: 0 15px;
}

.row {
  margin: 0;
}

div[class^="col"] { padding: 0; }

body .container
{
  padding-left: 20px;
  padding-right: 20px;

  @media screen and (min-width: $msv-breakpoint-s) {
    max-width: $msv-breakpoint-xl;
    width: 100%;
  }
}
