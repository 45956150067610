@mixin visually-hidden {
  position: absolute !important;
  height: 1px !important;
  width: 1px !important;
  overflow: hidden !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  border: 0 !important;
  white-space: nowrap !important; /* added line */
}

.sr-only {
  @include visually-hidden();
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.no-display { display: none; }

.no-visible { visibility: hidden; }