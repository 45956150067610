:root {
  --ecomm-mobile-iso-bg: #{$msv-white};
  --ecomm-mobile-iso-padding: 1rem 2rem;

  --ecomm-mobile-iso-title-colour: var(--brandPrimaryColor);
  --ecomm-mobile-iso-title-padding: 0 12px 0 0;
  --ecomm-mobile-iso-title-size: 1.2rem;
  --ecomm-mobile-iso-title-weight: bold;

  --ecomm-mobile-iso-logo-padding: 0 0 0 12px;
  --ecomm-mobile-iso-logo-width: 4rem;
}

.ecomm-mobile-iso {
  background-color: var(--ecomm-mobile-iso-bg);
  padding: var(--ecomm-mobile-iso-padding);

  > .title {
    color: var(--ecomm-mobile-iso-title-colour);
    padding: var(--ecomm-mobile-iso-title-padding);

    > h4 {
      font-size: var(--ecomm-mobile-iso-title-size);
      font-weight: var(--ecomm-mobile-iso-title-weight);
    }
  }

  > .logo {
    @include display-flex;
    @include justify-content(center);
    @include align-items(center);
    
    padding: var(--ecomm-mobile-iso-logo-padding);

    > img { width: var(--ecomm-mobile-iso-logo-width); }
  }
}