:root {
  --banner-img-height: 50vh;

  --banner-title-colour: #{$msv-black};
  --banner-title-margin: 2rem 0 0 0;
}

.ecomm-product-list-banner {
  .image { height: var(--banner-img-height); }

  .title {
    color: var(--banner-title-colour);
    margin: var(--banner-title-margin);
  }
}