:root {
  --ecomm-iframe-border: none;
  --ecomm-iframe-width: 100%;
}

.ms-iframe { 
  iframe { 
    border: var(--ecomm-iframe-border); 
    width: var(--ecomm-iframe-width);
  }
}