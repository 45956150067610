:root {
    --header-color: #901C32;
    --header-font-size: 24px;
    --header-font-weight: bold;
    --header-padding-left: .5rem;
    --body-color: #333333;
    --body-padding: .5rem;
    --body-highlight-color: #ffc000;
    --button-font-size: 16px;
    --button-font-weight: bold;
    --button-border: 1px solid #C7C7CC;
    --button-padding: .75rem 1rem;
    --button-text-decoration: none;
    --stay-button-color: #901C32;
    --go-button-color: var(--brandTextColour);
    --go-portal-button-color: #6CD20A;
    --go-button-background-color: #901C32;
}

.accordion-item {
    width: 100%;
}

.accordion-item:not(:first-of-type) {
    border-top: 0;
}

.accordion-item-title {
    width: 100%;
    margin: 0;
    border: 20px;
    padding: 1.055rem 1.5rem;
    border-bottom: 1px solid #ccc;
    background-color: white;
}

.accordion-item-title-text {
    width: 100%;
    margin: 0;
    color: #901C32;
    // color: black;
}

.accordion-item-title-time {
    font-weight: 100;
    font-size: 14px;
    padding-top: 4px;
    width: 100%;
    margin: 0;
    color: #6A6A6A;
    // color: black;
}

.accordion-item-btn {
    display: flex;
    align-items: center;
    width: 100%;
    background-color: #fff;
    border-width: 1px;
    border-style: solid;
    border-color: #C7C7CC;
    padding: 15px 20px;
    font-size: 18px;
    font-weight: 400;
    cursor: pointer;
    user-select: none;
    color: #901C32;
}

.accordion-item-sutra-opened-btn {
    display: flex;
    align-items: center;
    // width: 100%;
    margin-top: 8px;
    background-color: #08493f;
    color: #FFFFAC;
    padding: 12px 24px;
    font-size: 16px;
    font-weight: 400;
    cursor: pointer;
    user-select: none;
    border-width: 1px;
    border-style: solid;
    border-color: #C7C7CC;
}

.accordion-item-sutra-closed-btn {
    display: flex;
    align-items: center;
    // width: 100%;
    margin-top: 8px;
    background-color: white;
    padding: 12px 24px;
    font-size: 16px;
    font-weight: 400;
    cursor: pointer;
    user-select: none;
    color: #901C32;
    border-width: 1px;
    border-style: solid;
    border-color: #C7C7CC;
}

.accordion-item-btn::after {
    content: '';
    flex-shrink: 0;
    width: 18px;
    height: 18px;
    margin-left: auto;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-size: 18px;
    transition: transform 0.2s ease-in-out;
}

.accordion-item.open .accordion-item-btn::after {
    transform: rotate(-180deg);
}

.accordion-item.active {
    background-color: #901c32;
}

.accordion-item-title.active {
    background-color: #901c32;
}

.accordion-item-title-text.active {
    color: white;
}

.accordion-item-title-time.active {
    color: white;
}

.accordion-item-container {
    transition: height 0.2s ease-in-out;
    overflow: hidden;
    background-color: white;
    width: 100%;
    height: 100%;
}

.accordion-item-content {
    border-top: 1px solid #cccccc;
    width: 100%;
    height: 100%;
}