$msv-password-reset-width: 40%;
$msv-password-reset-width-m: 80%;
$msv-password-reset-message-margin-top: 20px;
$msv-password-reset-message-padding: 20px;
$msv-password-reset-account-item-margin-top: 20px;
$msv-password-reset-input-margin-top: 4px;
$msv-password-reset-button-group-spacing: 32px;
$msv-password-reset-button-margin-top: 20px;

//style presets
:root {
  --msv-password-reset-font-size: var(--msv-body-font-size-m);
  --msv-password-reset-font-color: var(--msv-font-primary-color);

  // heading
  --msv-password-reset-heading-font-size: var(--msv-body-font-size-xl);
  --msv-password-reset-heading-font-color: var(--msv-font-primary-color);

  //error
  --msv-password-reset-error-font-color: #{$msv-red};
  --msv-password-reset-error-font-size: var(--msv-body-font-size-m);

  // alert
  --msv-password-reset-success-alert-bg: #{$msv-gray-300};
  --msv-password-reset-success-alert-font-color: var(--msv-font-primary-color);
  --msv-password-reset-success-alert-border: #{$msv-gray-900};
  
  --msv-password-reset-error-alert-bg: var(--msv-error-color);
  --msv-password-reset-error-alert-font-color: var(--msv-font-secondary-color);
  --msv-password-reset-error-alert-border: var(--msv-error-color);

  // primary button
  --msv-password-reset-primary-btn-bg: var(--msv-accent-brand-color);
  --msv-password-reset-primary-btn-font-color: var(--msv-font-secondary-color);
  --msv-password-reset-primary-btn-border: var(--msv-accent-brand-color);

  // secondary button
  --msv-password-reset-secondary-btn-bg: var(--msv-secondary-button-background-color);
  --msv-password-reset-secondary-btn-font-color: var(--msv-font-primary-color);
  --msv-password-reset-secondary-btn-border: var(--msv-accent-brand-color);
}

.ms-password-reset {
  @include font-content(var(--msv-font-weight-normal),var(--msv-password-reset-font-size), $msv-line-height-m);
  color: var(--msv-password-reset-font-color);

  @include display-flex;
  @include justify-content(center);
  
  align-items: center;
  flex-direction: column;

  &__container {
    width: $msv-password-reset-width;
  }

  &__heading {
    @include font-content(var(--msv-font-weight-normal), var(--msv-password-reset-heading-font-size), $msv-line-height-xl);
    color: var(--msv-password-reset-heading-font-color);
  }

  &__account-item {
    margin-top: $msv-password-reset-account-item-margin-top;

    &-input {
      @include form-input-el();
      border:1px solid $msv-gray-500;
      margin-top: $msv-password-reset-input-margin-top;
      width: 100%;
    }
  }

  &__page-success {
    @include validation-success(var(--msv-password-reset-success-alert-bg), var(--msv-password-reset-success-alert-border), var(--msv-password-reset-success-alert-font-color));
    display: none;
    margin-top: $msv-password-reset-message-margin-top;
    padding: $msv-password-reset-message-padding;
  }

  &__page-error {
    @include validation-error(var(--msv-password-reset-error-alert-bg), var(--msv-password-reset-error-alert-border), var(--msv-password-reset-error-alert-font-color));
    display: none;
    margin-top: $msv-password-reset-message-margin-top;
    padding: $msv-password-reset-message-padding;
  }

  &__item-error {
    @include font-content(var(--msv-font-weight-normal), var(--msv-password-reset-error-font-size), $msv-line-height-m);
    color: var(--msv-password-reset-error-font-color);
  }

  &__buttons {
    margin-top: $msv-password-reset-button-group-spacing;

    &__continue-button {
      margin-top: 0px;
    }
  }

  &__continue-button {
    @include primary-button(var(--msv-password-reset-primary-btn-bg), var(--msv-password-reset-primary-btn-font-color), var(--msv-password-reset-primary-btn-border));
    margin-top: $msv-password-reset-button-margin-top;
    width: 100%;
  }

  &__cancel-button {
    @include secondary-button(var(--msv-password-reset-secondary-btn-bg), var(--msv-password-reset-secondary-btn-font-color), var(--msv-password-reset-secondary-btn-border));
    margin-top: $msv-password-reset-button-margin-top;
    width: 100%;
  }

  &__verifying-modal,
  &__verifying-modal-message {
    display: none;
  }

  @media screen and (max-width: $msv-breakpoint-m) {
    &__container {
      width: $msv-password-reset-width-m;
    }
  }
}