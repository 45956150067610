:root {
  --ecomm-product-list-grid-gap: 1rem;
}

.ecomm-product-list {
  @include breakpoint($msv-breakpoint-l) { grid-template-columns: auto auto; }

  display: grid;
  grid-gap: var(--ecomm-product-list-grid-gap);
  grid-template-columns: auto auto auto;
}