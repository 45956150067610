$msv-icon-font-family: 'Font Awesome 5 Pro Light';
$msv-productDescription-font-family: 'Proxima Nova Semibold';
$msv-solid-icon-weight: 900;
$msv-outline-icon-weight: 400;

$msv-font-path: '../../../webfonts';

$msv-CommentSolid: "\f27a";
$msv-ChevronDown: "\f078";
$msv-ChevronUp: "\f077";
$msv-Edit: "\f303";
$msv-Add: "\f067";
$msv-Cancel: "\f00d";
$msv-More: "\f141";
$msv-Settings: "\f013";
$msv-Phone: "\f095";
$msv-Shop: "\f290";
$msv-Shop-Address: "\f3c5";
$msv-Filter: "\f0b0";
$msv-Search: "\f002";
$msv-FavoriteStar: "\f005";
$msv-FavoriteStarFill: "\f005";
$msv-half-star: "\f089";
$msv-Delete: "\f2ed";
$msv-Save: "\f0c7";
$msv-Play: "\f04b";
$msv-Pause: "\f04c";
$msv-ChevronLeft: "\f053";
$msv-ChevronRight: "\f054";
$msv-CaretRight: "\f0da";
$msv-Contact: "\f007";
$msv-Warning: "\f071";
$msv-IncidentTriangle: "\f071";
$msv-Dislike: "\f165";
$msv-Like: "\f164";
$msv-DislikeSolid: "\f165";
$msv-LikeSolid: "\f164";
$msv-Comment: "\f27a";
$msv-Heart: "\f004";
$msv-HeartFill: "\f004";
$msv-IdCard: "\f2c2";
$msv-DeliveryTruck: "\f0d1";
$msv-SolidPlay: "\f04b";
$msv-Mute: "\f6a9";
$msv-UnMute: "\f028";
$msv-Fullscreen: "\f065";
$msv-ExitFullscreen: "\f066";
$msv-ClosedCaptions: "\f20a";
$msv-Selected: "\f00c";
$msv-Error: "\f06a";
$msv-Checkbox: "\f0c8";
$msv-Checkbox-Checked: "\f14a";
$msv-Checkbox-Circle-Checked: "\f058";
$msv-RadioBtnOff: "\f111";
$msv-RadioBtnOn: "\f192";
$msv-SignIn: "\f2f6";
$msv-SignOut: "\f2f5";
$msv-History: "\f1da";
$msv-MapPin: "\f276";
$msv-ContactCard: "\f2c2";
$msv-GlobalNavButton: "\f0c9";
$msv-Remove:"\f068";
$msv-ShoppingCart:"\f07a";
$msv-ToggleLeft:"\f204";
$msv-ToggleRight:"\f205";
$msv-arrow-right: '\f061';
$msv-Spinner: '\f110';
$msv-file: '\f15c';
$msv-StoreAlt: '\f54f';
$msv-DollarSign: '\f155';
$msv-OrganizationUsers:'\f0c0';
$msv-ListAlt: '\f022';
$msv-List:'\f0ca';
$msv-Arrow-Right-In-Circle:'\f35a';
$msv-Ellipses-V: '\f142';
$msv-Arrow-Down: '\f063';
$msv-Arrow-Up: '\f062';
$msv-PlusSquare: '\f0fe';
$msv-PlusCircle: '\f055';
$msv-MinusCircle: '\f056';
$msv-InfoCircle: '\f05a';
$msv-Plus: '\f067';
$msv-BackArrow: '\f060';
$msv-invoice: '\f570';
$msv-cube: '\f1b2';
$msv-flower-daffodil: '\f800';
$msv-burn: '\f46a';
$msv-coin: '\f51e';
$msv-hand-receiving: '\f47c';
$msv-praying-hands: '\f684';
$msv-add-to-cart: '\f067';
$msv-remove: '\f00d';
$msv-home: '\f015';
$msv-creditcard: '\f09d';
$msv-coins: '\f51e';
$msv-globe: '\f0ac';
$msv-facebook: '\f09a';
$msv-trash: '\f1f8';
$shopping-cart: '\f07a';
$msv-magnifying-glass: $msv-Search;
$msv-heart: $msv-Heart;
$msv-shopping-bag: $msv-Shop;
$msv-x-shape: $msv-Cancel;
$msv-book-open: '\f518';
$msv-sync: '\f021';
$msv-external: '\f08e';
$msv-pdf: '\f1c1';
$msv-angle-up: '\f106';
$msv-angle-down: '\f107';


@font-face {
    font-family: $msv-productDescription-font-family;
    font-family: 'Font Awesome 5 Free';
    font-style: normal;
    font-weight: $msv-outline-icon-weight;
    font-display: auto;
    src: url('#{$msv-font-path}/ProximaNovaSemibold.eot') format('eot');
}

@font-face {
    font-family: $msv-icon-font-family;
    font-family: 'Font Awesome 5 Free';
    font-style: normal;
    font-weight: $msv-outline-icon-weight;
    font-display: auto;
    src: url('#{$msv-font-path}/fa-regular-400.woff2') format('woff2'),
    url('#{$msv-font-path}/fa-regular-400.woff') format('woff');
}

@font-face {
    font-family: 'Font Awesome 5 Duotone Solid';
    font-style: normal;
    font-weight: $msv-outline-icon-weight;
    font-display: auto;
    src: url('#{$msv-font-path}/fa-duotone-900.woff2') format('woff2'),
    url('#{$msv-font-path}/fa-duotone-900.woff') format('woff');
}

@font-face {
    font-family: 'Font Awesome 5 Pro Light';
    font-style: normal;
    font-weight: $msv-outline-icon-weight;
    font-display: auto;
    src: url('#{$msv-font-path}/fa-light-300.woff2') format('woff2'),
    url('#{$msv-font-path}/fa-light-300.woff') format('woff');
}

@font-face {
    font-family: 'Font Awesome 5 Free';
    font-style: normal;
    font-weight: $msv-solid-icon-weight;
    font-display: auto;
    src: url('#{$msv-font-path}/fa-solid-900.woff2') format('woff2'),
    url('#{$msv-font-path}/fa-solid-900.woff') format('woff');
}

@mixin msv-icon($icon-weight: $msv-solid-icon-weight, $display-type: inline-block, $font-size: $msv-font-size-m) {
    font-family: $msv-icon-font-family;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: $display-type;
    font-size: $font-size;
    font-style: normal;
    font-variant: normal;
    font-weight: $icon-weight;
    text-rendering: auto;
    line-height: 1;
}

@mixin add-icon($icon, $selector: before, $display-type: inline-block, $font-size: $msv-font-size-m, $icon-weight: $msv-solid-icon-weight) {
  &:#{$selector} {
    @include msv-icon($icon-weight, $display-type, $font-size);
    content: $icon;
  }
}