:root {
  --ecomm-page-navi-bg: #{$clear-day};
  --ecomm-page-navi-bg-active: var(--brandSecondaryColor);
  --ecomm-page-navi-font-colour: #{$msv-gray-900};
  --ecomm-page-navi-font-colour-active: var(--brandTextColour);
  --ecomm-page-navi-font-weight: bold;

  --ecomm-page-navi-padding: 10px;
}

.ecomm-page-navi {
  a { 
    background-color: var(--ecomm-page-navi-bg);
    color: var(--ecomm-page-navi-font-colour);
    display: block;
    font-weight: var(--ecomm-page-navi-font-weight);
    padding: var(--ecomm-page-navi-padding);
    text-decoration: none;

    &.active-link {
      background-color: var(--ecomm-page-navi-bg-active);
      color: var(--ecomm-page-navi-font-colour-active);
    }
  }
}