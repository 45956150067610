:root {
  --ecomm-idp-header-bg: #{$msv-white};
  --ecomm-idp-header-padding: .5rem 0;

  --ecomm-idp-header-logo-margin: 0 .5rem 0 0;
  --ecomm-idp-header-logo-width: 3rem;
}

.ecomm-idp-header {
  @include display-flex;
  @include justify-content(center);

  background-color: var(--ecomm-idp-header-bg);
  padding: var(--ecomm-idp-header-padding);

  .logo {
    &:first-child { margin: var(--ecomm-idp-header-logo-margin); }

    width: var(--ecomm-idp-header-logo-width);
  }
}