:root {
    --ecomm-history-side-bg: var(--brandPrimaryColor);
    --ecomm-history-side-height: 100vh;
  
    --ecomm-history-top-padding: 2rem 1rem;
  
    --ecomm-history-top-title-padding: 0;
    --ecomm-history-top-title-size: 1.5rem;
    --ecomm-history-top-title-weight: bold;
  
    --ecomm-history-top-count-padding: 0;
  
    --ecomm-history-filter-padding: 2rem;
  
    --ecomm-history-filter-button-active-colour: var(--brandTextColour);
  
    --ecomm-history-filter-button-bg: transparent;
    --ecomm-history-filter-button-border: none;
    --ecomm-history-filter-button-colour: #{$msv-white};
    --ecomm-history-filter-button-margin: 0 0 2rem 0;
    --ecomm-history-filter-button-padding: 0;
    --ecomm-history-filter-button-weight: bold;
    --ecomm-history-filter-button-width: 100%;
  }
  
  .ecomm-history-filter {
    background-color: var(--ecomm-history-side-bg);
    min-height: var(--ecomm-history-side-height);
  
    > .top { 
      border-left: transparent;
      border-right: transparent;
      border-style: dashed;
      border-top: transparent;
      padding: var(--ecomm-history-top-padding);
  
      > .title {
        font-size: var(--ecomm-history-top-title-size);
        font-weight: var(--ecomm-history-top-title-weight);
        padding: var(--ecomm-history-top-title-padding);
      }
      
      > .count { 
        padding: var(--ecomm-history-top-count-padding);
        text-align: end; 
      }
    }
  
    > .filter {
      padding: var(--ecomm-history-filter-padding);
  
      > button {
        background-color: var(--ecomm-history-filter-button-bg);
        border: var(--ecomm-history-filter-button-border);
        color: var(--ecomm-history-filter-button-colour);
        font-weight: var(--ecomm-history-filter-button-weight);
        padding: var(--ecomm-history-filter-button-padding);
        text-align: start;
        width: var(--ecomm-history-filter-button-width);
        
        &:not(:last-child) { margin: var(--ecomm-history-filter-button-margin); }
  
        &.active { color: var(--ecomm-history-filter-button-active-colour); }
      }
    }
  }